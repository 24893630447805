import * as React from "react";
import { useCallback, useEffect, useState } from "react";
import { ApiClient } from "../../api";
import Alert from "../../component/alert";
import Loading from "../../component/loading";
import IInformation from "../../models/information";
import { getSession } from "../../session";

type EntryProps = {
  information?: IInformation;
};

export const InformationModalUI = (props: EntryProps) => {
  const { information } = props;
  if (information === undefined) {
    return <Loading />;
  }

  return (
    <Alert
      title={information.title}
      body={information.body}
      okText="OK"
      onClickOk={() => {}}
    />
  );
};

interface IOuterProps {
  disableModal?: boolean;
}

type AllProps = IOuterProps;

// お知らせポップアップ表示判定
export const isNotDisplayed = () => {
  return (sessionStorage.getItem('isDisplayed') !== 'true');
};
export const clearDisplayed = () => {
  sessionStorage.removeItem('isDisplayed');
};
export const setDisplayed = () => {
  sessionStorage.setItem('isDisplayed', 'true');
};


const Information = (props: AllProps) => {
  const { disableModal } = props;
  const [showAlert, setShowAlert] = useState(false);
  const [information, setInformation] = useState<IInformation>();

  const fetchInformation = useCallback(async () => {
    try {
      if (disableModal) {
        return;
      }
      const session = getSession();
      if (!session || !session.login_key) {
        return;
      }
      const result = await new ApiClient().getInformation(session.login_key);
      if (result && result.has_information) {
        setInformation(result);
        setShowAlert(true);
      }
      setDisplayed();
    } catch (e) {
      //  console.log(e);
    }
  }, []);
  useEffect(() => {
    fetchInformation();
  }, [window.location.href]);

  return (
    <>
      {showAlert && information && (
        <Alert
          title={information.information.title}
          body={information.information.body}
          okText="OK"
          onClickOk={(e: any) => {
            setShowAlert(false);
          }}
        />
      )}
    </>
  );
};

export default Information;
