import * as React from "react";
import ToggleMenu from "../../ui/taste/list/toggle_menu";
import {ITag} from "../../ui/taste/list/index"

interface IOuterProps {
  left?: string;
  title?: string;
  right?: string;
  onLeftClick?: () => void;
  onRightClick: () => void;
  rightNode?: React.ReactNode;
  leftIcon?: string;
  isOpen: boolean;
  tag: ITag[];
  tasteNum: number;
  changeCheck: (name: string) => void;
  getKeywordList: () => void;
}

type AllProps = IOuterProps;

const NavigationTL = (props: AllProps) => {
  const { title, left, right, onLeftClick, onRightClick, rightNode, leftIcon, isOpen, tag, tasteNum, changeCheck, getKeywordList } = props;

  return (
    <React.Fragment>
      <div className="c-nav_bar--light navigation-header" style={(isOpen)?{overflow:"visible"}:{overflow:"hidden"}}>
        {/* left */}
        <div className="c-nav_bar-left">
          {left || leftIcon ? (
            <div className="c-button" onClick={onLeftClick}>
              {left ? (
                <span className="c-button-strings" style={{padding: "7px 8px"}}>{left}</span>
              ) :
                <i className="material-icons">{leftIcon}</i>
              }
            </div>
          ) : (
            <></>
          )}
        </div>
        {/* title */}
        <div className="c-nav_bar-center">
          {title ? (
            <span className="c-button-strings">{title}</span>
          ) : (
            <></>
          )}
        </div>
        {/* right */}
        <div className="c-nav_bar-right">
          {right ?
            isOpen ?
              (
                <div className="c-button" onClick={onRightClick} >
                  <span className="c-button-strings" style={{padding: "7px 20px", backgroundColor: "darkgrey", color: "white"}}>
                    {right}<span style={{fontSize: "0.75rem"}}>▲</span>
                  </span>
                </div>
              )
              :
              (
                <div className="c-button" onClick={onRightClick} >
                  <span className="c-button-strings" style={{padding: "7px 20px", backgroundColor: "darkgrey", color: "white"}}>
                    {right}<span style={{fontSize: "0.75rem"}}>▼</span>
                  </span>
                </div>
              )
            :
            (
              <></>
            )
          }
        </div>
        {rightNode}
        <div style={(isOpen)?{display:""}:{display:"none"}}>
          <div style={{position:"absolute", backgroundColor:"white", padding:"10px",borderBottomStyle:"inset"}}>
            <ToggleMenu
              tag={tag}
              tasteNum={tasteNum}
              isOpen={isOpen}
              changeCheck={changeCheck}
              changeToggleMenu={onRightClick}
              getKeywordList={getKeywordList}
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default NavigationTL;