import * as React from "react";

interface IOuterProps {
  id: number;
  title: string;
  imageUrl?: string;
  isRecommend?: boolean;
}

type AllProps = IOuterProps;

const TasteCell = (props: AllProps) => {
  const { title, imageUrl, isRecommend } = props;
  return (
    <React.Fragment>
      <div className="c-card-thumbnail">
        <div
          className="c-thumbnail--round_top"
          style={{ width: "100%" }}
          data-object_fit="contain"
        >
          <img
            src={
              imageUrl === ""
                ? "https://d1kj7933n1tx2w.cloudfront.net/images/rooms/icon_image_default.png"
                : imageUrl
            }
            alt="画像が設定されていません"
          />
        </div>
      </div>
      {isRecommend ? (
        <div className="c-card-ribbon">
          <div className="c-ribbon u-fc--tint u-tc--on_tint"> オススメ</div>
        </div>
      ) : null}
      <div className="c-card-body">
        {" "}
        <div className="c-card-body-title">{title}</div>
      </div>
    </React.Fragment>
  );
};

export default TasteCell;
