import * as React from "react";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import Scroll from "react-scroll";
import { Dispatch } from "redux";
import { ApiClient } from "../../../api";
import { IPlanParam, IUpdatePlanParam } from "../../../api/request/plans";
import Done from "../../../component/alert/done";
import Edit from "../../../component/alert/edit";
import Confirm from "../../../component/confirm";
import Header from "../../../component/header";
import Loading from "../../../component/loading";
import addToPhotosActiveImg from "../../../images/ic-add-to-photos-active.svg";
import addImg from "../../../images/ic-add.svg";
import deleteActiveImg from "../../../images/ic-delete-active.svg";
import deleteImg from "../../../images/ic-delete.svg";
import editActiveImg from "../../../images/ic-edit-active.svg";
import editImg from "../../../images/ic-edit.svg";
import shareActiveImg from "../../../images/ic-share-active.svg";
import { IPlan, IPlanInfo } from "../../../models/plan";
import { ActionName as ActionNameCase, ICaseAction } from "../../../redux/case";
import { ActionName as ActionNamePlan, IPlanAction } from "../../../redux/plan";
import { getSession, setActivePlan } from "../../../session";
import { handleRequestError } from "../../../util";
import PlanDeleteDialog from "../delete_dialog";

interface IPlanProps {
  selectPlan: (id: number, name: string) => void;
  selectedPlanIds: number[];
  selectedPlanNames: string[];
  setSelectedPlanIds: ([]) => void;
  setSelectedPlanNames: ([]) => void;
  setDuplicateSourcePlanId: (id: number) => void;
  setDuplicateSourcePlanName: (name: string) => void;
  setShowDuplicateComplete: (shown: boolean) => void;
}

type AllProps = RouteComponentProps & IPlanProps;

const PlanMenuHeader = (props: AllProps) => {
  const desc = `
  プランの新規作成 / 複製 / 削除 / 選択が行えます。<br />
  プランは最大で5つまで作成することができます。<br />
  編集が完了したら、設計者へプランを共有ください。打ち合わせ前に1つは共有が必要です。`;
  return (
    <React.Fragment>
      <Header title="プラン管理" description={desc} />
    </React.Fragment>
  );
};

const scroll = (
  domId: string,
  timeoutMilliSec: number,
  durationMilliSec: number
) => {
  const timeout = timeoutMilliSec || 300;
  const durate = durationMilliSec || 250;

  const scroller = Scroll.scroller;
  setTimeout(() => {
    scroller.scrollTo(domId, {
      duration: durate,
      delay: 0,
      offset: -100,
      smooth: true
    });
  }, timeout);
};

const actionDuplicatePlan = async (
  planId: number,
  dispatchPlan: Dispatch<IPlanAction>,
  setShowDuplicateComplete: (shown: boolean) => void
) => {
  // プラン複製
  const duplicatedPlan = await new ApiClient().duplicatePlan(planId);
  // 再取得
  const responsePlans = await new ApiClient().getPlans();
  dispatchPlan({
    type: ActionNamePlan.setPlans,
    fetchedPlans: responsePlans
  });
  dispatchPlan({
    type: ActionNamePlan.setDuplicatedPlan,
    duplicatedPlan: duplicatedPlan
  });
  // スクロール
  scroll(`${duplicatedPlan.publish_key}`, 0, 500);
  // 複製完了
  setShowDuplicateComplete(true);
};

const PlanMenuContent = (props: AllProps) => {
  const [loading, setLoading] = useState(false);
  const [showPlanEdit, setShowPlanEdit] = useState(false);
  const [showShareUrlConfirm, setShowShareUrlConfirm] = useState(false);
  const [showShareUrlComplete, setShowShareUrlComplete] = useState(false);
  const [showTasteUnselected, setShowTasteUnselected] = useState(false);
  const [showPlanIndex, setShowPlanIndex] = useState(0);

  // plan
  const plansSelector = (state: any) => state.planReducer.fetchedPlans;
  const plans: IPlan[] = useSelector(plansSelector);
  const dispatchPlan = useDispatch<Dispatch<IPlanAction>>();
  // case
  const dispatchCase = useDispatch<Dispatch<ICaseAction>>();

  // 画面遷移処理
  const showPlan = (plan: IPlan) => {
    switch (plan.status) {
      case "pending":
        dispatchPlan({
          type: ActionNamePlan.setPlan,
          fetchedPlan: plan
        });
        // active plan
        const session = getSession();
        const activePlanId = session ? session.plan_id : 0;
        if (plan.id === activePlanId) {
          props.history.goBack();
        } else {
          setActivePlan(plan);
          dispatchCase({
            type: ActionNameCase.addSelectedCaseId,
            selectedCaseIds: []
          });
          props.history.push(`/case/insert?publish_key=${plan.publish_key}`);
        }
        break;
      case "edit":
        setActivePlan(plan);
        props.history.push(`/part/home?publish_key=${plan.publish_key}`);
        break;
      case "locked":
        setActivePlan(plan);
        props.history.push(`/share/detail?publish_key=${plan.publish_key}`);
        break;
      default:
        setActivePlan(plan);
        props.history.push(`/part/home?publish_key=${plan.publish_key}`);
        break;
    }
  };

  // URLコピー処理
  const copy = async (plan: IPlan) => {
    const shareUrl = `${window.location.protocol}//${window.location.host}/share/detail?publish_key=${plan.publish_key}`;
    const input = document.createElement("input");
    input.setAttribute("id", "copyinput");
    document.body.appendChild(input);
    input.value = shareUrl;
    input.select();
    document.execCommand("copy");
    document.body.removeChild(input);
    // 共有状態送信
    // 既にロック済みの場合は、コピーのみ行いAPI送信しない
    if (plan.status !== "locked") {
      const param: IPlanParam = {
        id: plan.id
      };
      // 共有状態更新
      await new ApiClient().sharedUrl(param);
      // ロック処理
      await new ApiClient().lockPlan(param);
      // 再取得
      const responsePlans = await new ApiClient().getPlans();
      dispatchPlan({
        type: ActionNamePlan.setPlans,
        fetchedPlans: responsePlans
      });
    }
  };

  const fetchPlans = useCallback(async () => {
    setLoading(true);
    try {
      const result = await new ApiClient().getPlans();
      setLoading(false);
      dispatchPlan({
        type: ActionNamePlan.setPlans,
        fetchedPlans: result
      });
    } catch (e) {
      setLoading(false);
      handleRequestError(e, props.history);
    }
  }, [dispatchPlan]);

  const duplicatePlan = useCallback(
    async (id: number) => {
      setLoading(true);
      try {
        const result = await new ApiClient().limitPlan();
        if (result.is_over) {
          // 削除確認ダイアログを表示する
          dispatchPlan({
            type: ActionNamePlan.showDeletePlan,
            showDeletePlan: true
          });
        } else {
          // // プラン複製
          await actionDuplicatePlan(
            id,
            dispatchPlan,
            props.setShowDuplicateComplete
          );
        }
        // プランの選択状態をリセット
        props.setSelectedPlanIds([]);
        props.setSelectedPlanNames([]);
        setLoading(false);
      } catch (e) {
        setLoading(false);
        handleRequestError(e, props.history);
      }
    },
    [dispatchPlan]
  );

  const updatePlan = useCallback(
    async (
      planParts: IUpdatePlanParam,
      planId: number,
      onUpdated: (plan: IPlanInfo) => void
    ) => {
      setLoading(true);
      try {
        // プラン更新
        const result = await new ApiClient().updatePlan(planId, planParts);
        // 再取得
        const responsePlans = await new ApiClient().getPlans();
        dispatchPlan({
          type: ActionNamePlan.setPlans,
          fetchedPlans: responsePlans
        });
        // activeなプランは再取得
        const activePlan = responsePlans.find(plan => plan.id === activePlanId);
        if (activePlan) {
          dispatchPlan({
            type: ActionNamePlan.setPlan,
            fetchedPlan: activePlan
          });
        }
        // スクロール
        scroll(`${result.publish_key}`, 0, 250);
        onUpdated(result);
        // プランの選択状態をリセット
        props.setSelectedPlanIds([]);
        props.setSelectedPlanNames([]);
        setLoading(false);
      } catch (e) {
        setLoading(false);
        handleRequestError(e, props.history);
      }
    },
    [dispatchPlan]
  );

  useEffect(() => {
    dispatchPlan({
      type: ActionNamePlan.showDeletedPlan,
      showDeletedPlan: false
    });
    fetchPlans();
  }, [fetchPlans]);

  if (loading) {
    return <Loading />;
  }

  // active plan
  const session = getSession();
  const activePlanId = session ? session.plan_id : 0;

  return (
    <React.Fragment>
      <div className="plan-menu">
        {plans ? (
          plans.map((plan, index) => {
            const isActive = plan.id === activePlanId;
            return (
              <div
                key={plan.id}
                className="contents"
                id={`${plan.publish_key}`}
              >
                <div className={`${isActive ? "actived-boder" : ""}`}>
                  <div className="container">
                    <section className="left">
                      <div className="check">
                        <input
                          disabled={isActive}
                          id={`${plan.id}`}
                          type="checkbox"
                          defaultChecked={false}
                          onClick={() => props.selectPlan(plan.id, plan.name)}
                        />
                        <label htmlFor={`${plan.id}`} />
                      </div>
                    </section>
                    <section className="main" onClick={() => showPlan(plan)}>
                      {/* プラン名 */}
                      <div className="name">{plan.name}</div>
                      <div className="base-taste-preview">
                        <div className="base-taste-preview-thumb">
                          {/* ベーステイストサムネイル */}
                          {plan.thumbnail_url ? (
                            <div className="thumb">
                              <img src={encodeURI(plan.thumbnail_url)} />
                            </div>
                          ) : (
                            <></>
                          )}
                          {// ロック済みのプラン
                          plan.status === "locked" && (
                            <div className="plan-status locked">ロック中</div>
                          )}
                          {// 選択中のプラン
                          isActive && (
                            <div className="plan-status actived">選択中</div>
                          )}
                        </div>
                        {/* メモ */}
                        <div className="memo">{plan.memo}</div>
                      </div>
                      <div className="date">
                        {/* 作成日時 */}
                        <div className="created_at">
                          <span>作成日時&nbsp;</span>
                          {plan.created_at}
                        </div>
                        {/* 最終更新日時 */}
                        <div className="updated_at">
                          <span>最終更新日時&nbsp;</span>
                          {plan.updated_at}
                        </div>
                      </div>
                    </section>
                  </div>
                  {/* アクション */}
                  <div className="actions">
                    <div
                      className="c-button"
                      onClick={e => {
                        setShowPlanIndex(index);
                        props.setDuplicateSourcePlanId(plan.id);
                        props.setDuplicateSourcePlanName(plan.name);
                        duplicatePlan(plan.id);
                      }}
                    >
                      <div className="c-button-icons">
                        <div className="button-label">複製</div>
                        <img src={addToPhotosActiveImg} alt="copy" />
                      </div>
                    </div>
                    {plan.status === "locked" ? (
                      <div className="c-button modal-ignore disabled">
                        <div className="c-button-icons">
                        <div className="button-label">編集</div>
                          <img src={editImg} alt="edit" />
                        </div>
                      </div>
                    ) : (
                      <div
                        className="c-button modal-ignore"
                        onClick={e => {
                          setShowPlanEdit(true);
                          setShowPlanIndex(index);
                        }}
                      >
                        <div className="c-button-icons">
                          <div className="button-label">編集</div>
                          <img src={editActiveImg} alt="edit" />
                        </div>
                      </div>
                    )}
                    <div
                      className="c-button modal-ignore share"
                      onClick={e => {
                        if (plan.status === "pending") {
                          setShowTasteUnselected(true);
                        } else if (plan.status === "locked") {
                          setShowPlanIndex(index);
                          copy(plans[index]);
                          setShowShareUrlComplete(true);
                        } else {
                          setShowShareUrlConfirm(true);
                          setShowPlanIndex(index);
                        }
                      }}
                    >
                      <div className="c-button-icons">
                      <div className="button-label">共有</div>
                        <img src={shareActiveImg} alt="share" />
                      </div>
                    </div>
                  </div>
                </div>
                <hr className="c-separator" />
              </div>
            );
          })
        ) : (
          <></>
        )}
      </div>
      {showPlanEdit && (
        <Edit
          title="プラン情報編集"
          editItems={[
            {
              title: "プラン名",
              type: "text",
              value: plans[showPlanIndex].name
            },
            {
              title: "メモ",
              type: "textarea",
              value: plans[showPlanIndex].memo
            }
          ]}
          okText="保存"
          cancelText="キャンセル"
          onClickOk={(planName, planMemo) => {
            const updateParam: IUpdatePlanParam = {
              name: planName,
              memo: planMemo,
              plan_part_variations: []
            };

            updatePlan(
              updateParam,
              plans[showPlanIndex].id,
              (plan: IPlanInfo) => {}
            );

            // state制御:編集ダイアログを非表示
            setShowPlanEdit(false);
            // state制御:ダイアログ表示中のプラン(インデックス)をリセット
            setShowPlanIndex(0);
          }}
          onClickCancel={() => {
            setShowPlanEdit(false);
            setShowPlanIndex(0);
          }}
        />
      )}
      {showShareUrlConfirm && (
        <Confirm
          title="プランURLを発行してもよろしいですか？"
          text="URLを発行するとそのプランを変更できなくなりますが、メニューからプランを複製すれば別のプランとしてカスタマイズすることができます。"
          okText="プランURLを発行する"
          cancelText="キャンセル"
          onClickOk={(e: any) => {
            copy(plans[showPlanIndex]);
            // state制御:確認ダイアログを非表示
            setShowShareUrlConfirm(false);
            // state制御:完了ダイアログを表示
            setShowShareUrlComplete(true);
            // state制御:ダイアログ表示中のプラン(インデックス)をリセット
            setShowPlanIndex(0);
          }}
          onClickCancel={() => {
            setShowShareUrlConfirm(false);
            setShowPlanIndex(0);
          }}
        />
      )}
      {showShareUrlComplete && (
        <Done
          title=""
          text="URLをクリップボードにコピーしました。"
          okText="OK"
          onClickOk={(e: any) => {
            // state制御:確認ダイアログを非表示
            setShowShareUrlComplete(false);
          }}
        />
      )}
      {showTasteUnselected && (
        <Done
          title=""
          text="テイストが選択されていないプランはプランURLを発行することができません。"
          okText="OK"
          onClickOk={(e: any) => {
            // state制御:テイスト未選択通知ダイアログを非表示
            setShowTasteUnselected(false);
          }}
        />
      )}
    </React.Fragment>
  );
};

const PlanMenuFooter = (props: AllProps) => {
  const [loading, setLoading] = useState(false);
  const [showLastConfirm, setShowLastConfirm] = useState(false);
  const [showDeletedConfirm, setShowDeletedConfirm] = useState(false);

  const dispatchPlan = useDispatch<Dispatch<IPlanAction>>();

  const destroyPlans = useCallback(
    async (selectedPlanIds: number[]) => {
      setLoading(true);
      try {
        // プラン削除
        const result = await new ApiClient().destroyPlans(selectedPlanIds);
        // 再取得
        const responsePlans = await new ApiClient().getPlans();
        dispatchPlan({
          type: ActionNamePlan.setPlans,
          fetchedPlans: responsePlans
        });
        setLoading(false);
      } catch (e) {
        setLoading(false);
        handleRequestError(e, props.history);
      }
    },
    [dispatchPlan]
  );

  if (loading) {
    return <Loading />;
  }

  return (
    <div className="c-footer">
      <div className="c-footer-buttons">
        {props.selectedPlanIds.length === 0 ? (
          <div className="c-button delete">
            <div className="c-button-icons">
              <img src={deleteImg} alt="delete" />
            </div>
          </div>
        ) : (
          <div
            className="c-button delete active"
            onClick={e => {
              setShowLastConfirm(true);
            }}
          >
            <div className="c-button-icons">
              <img src={deleteActiveImg} alt="delete" />
            </div>
          </div>
        )}
        <div
          className="c-button create"
          onClick={() => {
            dispatchPlan({
              type: ActionNamePlan.createPlan,
              createPlan: true
            });
          }}
        >
          <div className="c-button-strings">
            新しいプランを作成&nbsp;
            <img src={addImg} alt="create" />
          </div>
        </div>
      </div>
      {showLastConfirm && (
        <Confirm
          title=""
          text={props.selectedPlanNames + "を削除してもよろしいですか？"}
          okText="OK"
          cancelText="キャンセル"
          onClickOk={(e: any) => {
            destroyPlans(props.selectedPlanIds);
            // state制御:確認ダイアログを非表示
            setShowLastConfirm(false);
            // state制御:完了ダイアログを表示
            setShowDeletedConfirm(true);
          }}
          onClickCancel={() => setShowLastConfirm(false)}
        />
      )}
      {showDeletedConfirm && (
        <Done
          title=""
          text={props.selectedPlanNames + "を削除しました。"}
          okText="OK"
          onClickOk={(e: any) => {
            // プランの選択状態をリセット
            props.setSelectedPlanIds([]);
            props.setSelectedPlanNames([]);
            // state制御:完了ダイアログを非表示
            setShowDeletedConfirm(false);
          }}
        />
      )}
    </div>
  );
};

const PlanMenu = (props: AllProps) => {
  const dispatchPlan = useDispatch<Dispatch<IPlanAction>>();
  // プランの選択状態
  const [selectedPlanIds, setSelectedPlanIds] = useState(Array());
  const [selectedPlanNames, setSelectedPlanNames] = useState(Array());
  // 複製元プランID
  const [duplicateSourcePlanId, setDuplicateSourcePlanId] = useState(0);
  // 複製元プラン名
  const [duplicateSourcePlanName, setDuplicateSourcePlanName] = useState("");
  // 最新の複製プラン
  const planSelector = (state: any) => state.planReducer.duplicatedPlan;
  const duplicatedPlan: IPlan = useSelector(planSelector);
  // 複製完了
  const [showDuplicateComplete, setShowDuplicateComplete] = useState(false);

  const selectPlan = (id: number, name: string) => {
    const ids = selectedPlanIds.slice();
    const names = selectedPlanNames.slice();
    if (ids.includes(id)) {
      const index = ids.indexOf(id, 0);
      if (index > -1) {
        ids.splice(index, 1);
        names.splice(index, 1);
      }
    } else {
      ids.push(id);
      names.push(name);
    }
    setSelectedPlanIds(ids);
    setSelectedPlanNames(names);
  };

  const duplicateCompletedMessage = `
  ${duplicateSourcePlanName}を複製して
  ${duplicatedPlan.name}を作成しました。`;

  return (
    <div className="t-plan">
      <div className="c-layout">
        <div className="c-layout-child">
          <PlanMenuHeader {...props} />
          <PlanMenuContent
            {...props}
            selectPlan={selectPlan}
            setSelectedPlanIds={setSelectedPlanIds}
            setSelectedPlanNames={setSelectedPlanNames}
            setDuplicateSourcePlanId={setDuplicateSourcePlanId}
            setDuplicateSourcePlanName={setDuplicateSourcePlanName}
            setShowDuplicateComplete={setShowDuplicateComplete}
          />
        </div>
        <div className="c-layout-child">
          <PlanMenuFooter
            {...props}
            selectedPlanIds={selectedPlanIds}
            selectedPlanNames={selectedPlanNames}
            setSelectedPlanIds={setSelectedPlanIds}
            setSelectedPlanNames={setSelectedPlanNames}
          />
        </div>
      </div>
      {showDuplicateComplete && (
        <Done
          title=""
          text={duplicateCompletedMessage}
          okText="OK"
          onClickOk={(e: any) => {
            setShowDuplicateComplete(false);
          }}
        />
      )}
      <PlanDeleteDialog
        onCancel={onCreatePlanCancel}
        onCreate={onCreatePlanComplete}
        onDelete={actionType => {
          // 新規
          if (actionType === "create") {
            dispatchPlan({
              type: ActionNamePlan.createPlan,
              createPlan: true
            });
          } else if (actionType === "duplicate") {
            actionDuplicatePlan(
              duplicateSourcePlanId,
              dispatchPlan,
              setShowDuplicateComplete
            );
          }
        }}
      />
    </div>
  );
};

const onCreatePlanCancel = () => {};
const onCreatePlanComplete = (plan: IPlan, router: RouteComponentProps) => {
  setActivePlan(plan);
  router.history.push(`/case/insert?publish_key=${plan.publish_key}`);
};

export default withRouter(PlanMenu);
