import * as React from "react";

interface Iprops{
  name: string;
  times: number;
  cancel: (name: string) => void
}

const KeywordItemCancel = (props: Iprops) => {
  const { name, times, cancel } = props;
  return (
    <span
      onClick={() => {cancel(name)}}
      style={{
        backgroundColor:"white",
        color:"black",
        cursor:"pointer",
        borderRadius:"30em",
        border:"2px solid grey",
        margin:"10px 10px 0px 10px",
        display:"inline-block",
        paddingLeft:"10px",
        paddingRight:"10px"
    }}><label style={{color:"darkgrey"}}><b>×</b></label> {name}({times})</span>
  );

};

export default KeywordItemCancel;