import * as React from "react";
import Carousel from "../../../component/carousel";

const TasteImageHeader = () => {
  return (
    <div className="c-nav_bar">
      <div className="c-nav_bar-left">
        <div className="c-button--circle u-fc--on_dark-quatiary c-modal_close">
          <i className="u-tc--on_light-primary material-icons">close</i>
        </div>
      </div>
      <div className="c-nav_bar-center" />
      <div className="c-nav_bar-right" />
    </div>
  );
};
export interface ITasteImageModal {
  urls: string[];
  taste_id: number;
  startIndex: number;
}
const TasteImages = (props: ITasteImageModal) => {
  return (
    <div
      className="c-modal"
      data-modal-color="dark_trans_nav"
      id={`taste_images_modal_${props.taste_id}`}
    >
      <div className="c-modal-inwrapper">
        <div className="c-modal-inwrapper-content">
          <TasteImageHeader />
          <Carousel
            centerPadding={"0%"}
            className={" c-carousel u-fc--dark-secondary"}
            dot={true}
            swipe={true}
            arrows={true}
            infinite={true}
            self={slider => {
              if (slider) {
                slider.slickGoTo(props.startIndex);
              }
            }}
          >
            {props.urls.map((url, index) => {
              return (
                <img
                  src={url}
                  key={index}
                />
              );
            })}
          </Carousel>
        </div>
      </div>
    </div>
  );
};

export default TasteImages;
