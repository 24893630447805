export enum Env {
  development = "development",
  develop = "develop",
  devtest = "devtest",
  staging = "staging",
  production = "production"
}

export const getCurrentEnv = (): Env => {

  switch (process.env.REACT_APP_ENV) {
    case Env.development:
      return Env.development;
    case Env.develop:
      return Env.develop;
    case Env.devtest:
      return Env.devtest;
    case Env.staging:
      return Env.staging;
    case Env.production:
      return Env.production;
    default:
      throw new Error(`予期せぬ環境です: ${process.env.REACT_APP_ENV}`);
  }
};
