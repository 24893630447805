import * as React from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import styled from "styled-components";

const timeout = 200;

export const AnimationStyle = styled.div`
  .push-appear,
  .push-enter {
    opacity: 1;
    transform: translateX(150px);
  }
  .push-appear-active,
  .push-enter-active {
    transition: opacity ${timeout}ms ease-out, transform ${timeout}ms ease-out;
    opacity: 1;
    transform: translateX(0px);
  }
`;

type AllProps = React.Props<{}>;

const TransitionPush = (props: AllProps) => {
  return (
    <AnimationStyle>
      <TransitionGroup appear={true}>
        <CSSTransition
          classNames="push"
          timeout={{ enter: timeout, exit: timeout }}
        >
          {props.children}
        </CSSTransition>
      </TransitionGroup>
    </AnimationStyle>
  );
};

export default TransitionPush;
