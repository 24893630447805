import { IPlan, IPlanInfo } from "../../models/plan";
import { host, IRequest } from "./core";

export const buildPlansRequest = (): IRequest<IPlan[]> => {
  return {
    url: `${host}/api/plans`,
    options: {
      method: "get",
      resolveWithFullResponse: true,
      json: true
    }
  };
};

export const buildPlanRequest = (planId: number): IRequest<IPlan> => {
  return {
    url: `${host}/api/plans/${planId}`,
    options: {
      method: "get",
      resolveWithFullResponse: true,
      json: true
    }
  };
};

export const buildFindPlanRequest = (publishKey: string): IRequest<IPlan> => {
  return {
    url: `${host}/api/plans/find_plan/?publish_key=${publishKey}`,
    options: {
      method: "get",
      resolveWithFullResponse: true,
      json: true
    }
  };
};

export const buildCreatePlanRequest = (): IRequest<IPlan> => {
  return {
    url: `${host}/api/plans`,
    options: {
      method: "post",
      resolveWithFullResponse: true,
      json: true
    }
  };
};

export const buildPlanFixedTasteRequest = (
  tasteId: number,
  publishKey: string,
  squareMeter: number
): IRequest<IPlanInfo> => {
  return {
    url: `${host}/api/plans/fixed_taste`,
    options: {
      method: "post",
      resolveWithFullResponse: true,
      json: {
        taste_id: tasteId,
        publish_key: publishKey,
        square_meter: squareMeter
      }
    }
  };
};

export interface IUpdatePlanPartParam {
  id: number;
  part_variation_id: number;
  sub_category_id: number;
  is_change: boolean;
  is_default: boolean;
  memo: string;
}

export interface IUpdatePlanParam {
  name: string;
  memo: string;
  plan_part_variations: IUpdatePlanPartParam[];
}

export interface IUpdateUserMadeImageParam {
  image_url_01: string; // 見える化イメージ(合成画像)：正面
  image_url_02: string; // 見える化イメージ(合成画像)：側面
}

export interface IUpdatePlanSquareMeterParam {
  id: number;
  square_meter: number;
}

export interface IPlanParam {
  id: number;
}

export const buildUpdatePlanRequest = (
  planId: number,
  param: IUpdatePlanParam
): IRequest<IPlanInfo> => {
  return {
    url: `${host}/api/plans/${planId}`,
    options: {
      method: "patch",
      resolveWithFullResponse: true,
      json: param
    }
  };
};

export const buildUpdateUserMadeImageRequest = (
  planId: number,
  param: IUpdateUserMadeImageParam
): IRequest<IPlan> => {
  return {
    url: `${host}/api/user_made_images/${planId}`,
    options: {
      method: "patch",
      resolveWithFullResponse: true,
      json: param
    }
  };
};

export const buildUpdatePlanSquareMeterRequest = (
  param: IUpdatePlanSquareMeterParam
): IRequest<IPlanInfo> => {
  return {
    url: `${host}/api/plans/update_square_meter`,
    options: {
      method: "post",
      resolveWithFullResponse: true,
      json: param
    }
  };
};

export const buildUpdateSharedUrlRequest = (
  param: IPlanParam
): IRequest<void> => {
  return {
    url: `${host}/api/plans/update_is_share`,
    options: {
      method: "post",
      resolveWithFullResponse: true,
      json: param
    }
  };
};

export const buildDuplicatePlanRequest = (planId: number): IRequest<IPlan> => {
  return {
    url: `${host}/api/plans/${planId}/duplicate`,
    options: {
      method: "post",
      resolveWithFullResponse: true,
      json: true
    }
  };
};

export const buildDestroyPlansRequest = (
  planIds: number[]
): IRequest<IPlan> => {
  return {
    url: `${host}/api/plans/destroies`,
    options: {
      method: "delete",
      resolveWithFullResponse: true,
      json: { ids: planIds }
    }
  };
};

export const buildLimitPlanRequest = (): IRequest<IPlan> => {
  return {
    url: `${host}/api/plans/check_limit`,
    options: {
      method: "post",
      resolveWithFullResponse: true,
      json: true
    }
  };
};

export const buildExistPlanRequest = (publishKey: string): IRequest<IPlan> => {
  return {
    url: `${host}/api/plans/check_exist/?publish_key=${publishKey}`,
    options: {
      method: "get",
      resolveWithFullResponse: true,
      json: true
    }
  };
};

export const buildLockRequest = (param: IPlanParam): IRequest<IPlan> => {
  return {
    url: `${host}/api/plans/${param.id}/lock`,
    options: {
      method: "put",
      resolveWithFullResponse: true,
      json: param
    }
  };
};

export const buildCheckHideTasteRequest = (publishKey: string): IRequest<IPlan> => {
  return {
    url: `${host}/api/plans/check_hide_taste/?publish_key=${publishKey}`,
    options: {
      method: "get",
      resolveWithFullResponse: true,
      json: true
    }
  };
};
