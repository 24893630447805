import * as React from "react";
import { useEffect, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import WalkThroughDialog from "../../../component/walk_through_dialog";
import insertImg from "../../../images/photo-album.svg";
import { buildPlanQueryParam } from "../../../util";
import Information, { isNotDisplayed } from "../../information";

type AllProps = RouteComponentProps;
const CaseInsert = (props: AllProps) => {
  const [showWalkThrough, setShowWalkThrough] = useState(false);
  const [showInformation, setShowInformation] = useState(false);
  const urlParams = new URLSearchParams(window.location.search);
  useEffect(() => {
    if (urlParams.get("first_login") === "true") {
      setShowWalkThrough(true);
    } else {
      // お知らせ未表示の場合は、取得処理を行う
      isNotDisplayed() && setShowInformation(true);  
    }
  }, [urlParams]);
  return (
    <>
      {showWalkThrough ? (
        <WalkThroughDialog
          onClickClose={(e: any) => {
            setShowWalkThrough(false);
            props.history.push(`/case/insert?publish_key=${urlParams.get("publish_key")}`);
          }}
        />
      ) : (
        <div className="t-insert">
          <div className="c-layout">
            <div className="c-layout-child">
              <div className="c-group--read">
                <div className="c-group--read-large_read">
                  まずはテイストを選んでみよう
                </div>
                <div className="c-group--read-image">
                  <img src={insertImg} alt="ピックアップする" />
                </div>
                <div className="c-group--read-small_read">
                  まずは自分の好みに近いイメージを
                  <br />
                  ピックアップしましょう。
                  <br />
                  ここで選んだイメージから、
                  <br />
                  あなたにオススメのテイストを提案します。
                  <br />
                </div>
                <div className="c-group--read-button">
                  <div
                    className="c-button"
                    onClick={() =>
                      props.history.push(`/case/list${buildPlanQueryParam()}`)
                    }
                  >
                    ピックアップする
                  </div>
                </div>
                <div className="u-tc--on_light-tertiary u-fs--small remark">
                  ※プランは最大で5つまで作成できます。
                  <br />
                  ※画面左上のメニューからプランの管理が可能です。
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {showInformation && <Information disableModal={false} />}
    </>
  );
};

export default withRouter(CaseInsert);
