import * as React from "react";
import Divider from "../../component/divider";
import "./style.css";
const Rule = () => {
  return (
    <>
      <div className="c-rule">
        <div className="c-layout">
          <div className="c-layout-child">
            <div className="c-group--pageheader">
              <div className="c-pageheader">
                <div className="c-pageheader-title">sugataについて</div>
                <Divider />
              </div>
            </div>
          </div>
          <div className="c-layout-child">
            <div className="c-detail">
              <ul>
                <li>
                  sugataは物件購入や設計前の準備としてデザインイメージと工事の金額をシミュレーションし、自分らしい暮らしへのお部屋づくりの方向性を決めるためのツールです。<br />
                  設計の打合せに入ってから間取りや仕様は自由に変更することが可能ですので、ご希望の内容と完全に同じでなくても、まずは一番、ご希望に近いものをテイストやオプション内から選択頂くことで、設計打合せがスムーズに進みます。<br />
                  最終的な内容や工事金額は後々、打合せをしながら決めていきますので、全てを正確に選択する必要はございません。
                </li>
                <li>
                  内容については、別紙「リノベる Design＆Construction Service Guide」も併せてご確認ください。
                </li>
                <li>
                  sugataはフルリノベーションを前提としているため、部分的な工事をご検討の場合には、イメージや選択内容に沿って工事ができなかったり、工事金額が大きく異なったりする可能性があります。<br />
                  特に小規模の工事になるほど、ずれは大きくなる可能性がありますので予めご了承ください。
                </li>
                <li>
                  その他設計担当へご要望やご質問等ございましたら、メモ機能をご活用ください。設計の打合せの際の参考にさせていただきます。
                </li>
              </ul>
            </div>
          </div>
          <div className="c-layout-child">
            <div className="c-group--pageheader">
              <div className="c-pageheader">
                <div className="c-pageheader-title">注意事項</div>
                <Divider />
              </div>
            </div>
          </div>
          <div style={{paddingLeft: 6, paddingRight: 12, fontSize: "0.9rem", fontWeight: "bold"}}>【表示される工事金額について】</div>
          <div className="c-layout-child">
            <div className="c-detail">
              <ul>
                <li>
                  実際の工事金額はデザイナーとの打合せで工事条件や内容の確定後にお見積もりにてご提示し、確定させていただきます。
                </li>
                <li>
                  表示される「テイストの概算金額」には設計コーディネート費・工事管理費・諸経費・消費税が含まれています。
                </li>
                <li>
                  表示される金額は25万円単位の参考価格となります。
                </li>
                <li>
                  表示金額に工事の諸条件(マンションの状態や規約、エリア、お部屋の形状、法規、工事時期等)による変動は含まれておりません。
                </li>
                <li>
                  表示される金額は選択された平米数から考えられる一般的な間取りをベースに、必要な材料の数量や工賃を含んだ価格です。
                </li>
                <li>
                  表示金額にはアプリに掲載のないもの（解体費用、下地の費用、配管や配線費用、設備の設置費用、養生やクリーニング費用等）も、標準的な費用が盛り込まれております。
                </li>
                <li>
                  写真やCGパースの家具、小物、ペンダント照明は表示金額に含まれておりませんが、一部の商品は購入いただく事が出来ますので、担当者にご相談ください。
                </li>
                <li>
                  表示価格や含まれる仕様等は、通知無く更新されることがございます。
                </li>
              </ul>
            </div>
          </div>
          <div style={{paddingLeft: 6, paddingRight: 12, fontSize: "0.9rem", fontWeight: "bold"}}>【表示されるイメージついて】</div>
          <div className="c-layout-child">
            <div className="c-detail">
              <ul>
                <li>
                  テイストのCGパースはあくまで素材や色の組み合わせのイメージをしやすくするためのものです。<br />
                  実際のお部屋の間取りや空間とは異なります。
                </li>
                <li>
                  テイストのCGパースと実際の素材や色や形状とは異なる可能性がございます。<br />
                  設計の打ち合わせにて実際にサンプル等を確認の上ご決定ください。
                </li>
                <li>
                  お選びいただくパーツが在庫切れや廃盤の場合や、マンション規約や法令、間取になどによって採用できない場合がございます。
                </li>
                <li>
                  キッチン、ユニットバス、トイレはCGパースと標準設定の内容が異なる場合がございます。
                </li>
                <li>
                  キッチン背面の収納はCGパースに記載が有る場合も標準設定では含まれておりません。
                </li>
              </ul>
            </div>
          </div>
          <div style={{paddingLeft: 6, paddingRight: 12, fontSize: "0.9rem", fontWeight: "bold"}}>【アレンジ機能について】</div>
          <div className="c-layout-child">
            <div className="c-detail">
              <ul>
                <li>
                  CGパースでデザインのアレンジが出来るパーツは、LDKの天井、キッチン側壁、リビング側壁になります。
                </li>
                <li>
                  アレンジした内容はイメージへの反映のみで、工事金額には反映されません。
                </li>
              </ul>
            </div>
          </div>
          <div style={{paddingLeft: 6, paddingRight: 12, fontSize: "0.9rem", fontWeight: "bold"}}>【アプリについて】</div>
          <div className="c-layout-child">
            <div className="c-detail">
              <ul>
                <li>
                  sugataではデータの送受信量が多いため、ご利用はWi-Fi環境を推奨致します。
                </li>
                <li>
                  リノベるとの工事請負契約が締結されない場合はアカウントの停止をさせていただく可能性が有りますので、予めご了承ください。
                </li>
                <li>
                  複数の端末での同時利用は、不具合が生じる可能性があるため想定しておりません。一つの端末からのご利用を推奨致します。
                </li>
                <li>
                  sugataのテイストデザイン、基本間取、CGパース(アレンジ後のCGも含む）、それに伴うシステムの版権はリノベる株式会社に帰属します。第三者への提供や公開はお控えください。
                </li>
              </ul>
            </div>
          </div>
          <div style={{paddingLeft: 6, paddingRight: 12, fontSize: "0.9rem", fontWeight: "bold"}}>【退会について】</div>
          <div className="c-layout-child">
            <div className="c-detail">
              <ul>
                <li>
                  退会に関するお問い合わせは、担当スタッフへご連絡ください。<br />
                  尚、退会した場合は作成したプランが削除されますので、予めご了承ください。
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Rule;
