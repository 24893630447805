import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import insertImg from "../../../images/knowledge_v2.jpg";
import { buildPlanQueryParam } from "../../../util";

type AllProps = RouteComponentProps;
const ShareInsert = (props: AllProps) => {
  return (
    <div className="t-insert">
      <div className="c-layout">
        <div className="c-layout-child">
          <div className="c-group--read">
            <div className="c-group--read-large_read">
              あなたのsugataを確認しよう
            </div>
            <div className="c-group--read-image">
              <img src={insertImg} style={{height: 140, width: 190, objectFit: "cover"}} alt="ピックアップする" />
            </div>
            <div className="c-group--read-small_read">
              理想に近づいた住まいを確認しましょう。
            </div>
            <div className="c-group--read-button">
              <div
                onClick={() =>
                  props.history.push(`/share/detail${buildPlanQueryParam()}`)
                }
                className="t-submit c-button u-fc--tint u-tc--on_tint"
              >
                進む
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(ShareInsert);
