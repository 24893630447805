import * as React from "react";
import { RouteComponentProps } from "react-router-dom";
import PriceDiff from "./price_diff";

export enum PartState {
  default = "DEFAULT",
  changed = "CHANGED"
}

interface IOuterProps {
  id: number;
  subCategory: string;
  imageUrl: string;
  materialName: string;
  description: string;
  priceDifference: number;
  isDefault?: boolean;
  state: PartState;
  hasComment?: boolean;
  onClick: () => void;
  showModal?: boolean;
}

type AllProps = RouteComponentProps & IOuterProps;

const PartCell = (props: AllProps) => {
  const {
    subCategory,
    imageUrl,
    materialName,
    description,
    priceDifference,
    isDefault,
    state,
    hasComment,
    onClick,
    showModal
  } = props;

  // modal
  const cardClassName = showModal ? "c-card  c-modal_toggle" : "c-card";
  const cardStyle = showModal ? { width: "100%" } : {};

  return (
    <div className="c-list-item" onClick={onClick} id={`${props.id}`} >
      <div
        className={cardClassName}
        style={cardStyle}
        data-modal-target={`details_modal_${props.id}`}
      >
        <div className="c-card-header">{subCategory}</div>
        <div className="c-card-body">
          <div className="c-card-body-thumbnail">
            <div
              className="c-thumbnail--round_all c-drop_shadow--custom"
              data-object_fit="contain"
            >
              {imageUrl && <img src={imageUrl} alt="画像が未設定です" />}

              {hasComment ? (
                <div className="c-button--circle u-fc--on_light-quatiary u-tc--on_dark-primary">
                  <i className="material-icons">comment</i>
                </div>
              ) : null}
            </div>
          </div>

          <div className="c-card-body-ribbon">
            {state === PartState.default ? (
              <div className="c-ribbon u-fc--tint u-tc--on_tint">
                標準パーツ
              </div>
            ) : (
              <div className="c-ribbon u-fc--success u-tc--on_success">
                変更済み
              </div>
            )}
          </div>
          <div className="c-card-body-title">{materialName}</div>
          <div className="c-card-body-description">{description}</div>
          <PriceDiff price={priceDifference} isDefault={isDefault} />
        </div>
      </div>
    </div>
  );
};

export default PartCell;
