import Scroll from "react-scroll";
import { ApiClient } from "../api";
import { HttpStatusCode } from "../api/request/core";
import { IPlan } from "../models/plan";
import ISession from "../models/session";
import { getSession, setActivePlan } from "../session";
import { Env, getCurrentEnv } from "../env";

export const parsePublishKey = (): string => {
  const session = getSession();
  const searchParams = new URLSearchParams(window.location.search.substring(1));
  const publishKey =
    searchParams.get("publish_key") || (session && session.publish_key);
  return publishKey || "";
};

export const isHouseholdItemsUrl = (): boolean => {
  // 家具EC連携か
  const houseHoldItemsKey = "si";
  const searchParams = new URLSearchParams(window.location.search.substring(1));
  const queryString = searchParams.get(houseHoldItemsKey) || "";
  if (queryString) {
    return true;
  }
  return false;
};

export const authRedirectUrl = (): string => {
  // ログインエラー時のURL
  // - 基本は トップページ("/")
  // - 家具EC連携時は トップページ("/?si=rhi")
  return isHouseholdItemsUrl() ? `/?si=rhi` : `/`;
};

export const buildPlanQueryParam = (): string => {
  const publishKey = parsePublishKey();
  return `?publish_key=${publishKey}`;
};

export const addPlanQueryParam = (): string => {
  const publishKey = parsePublishKey();
  return `&publish_key=${publishKey}`;
};

export const loadPlanPublishKey = async (): Promise<IPlan> => {
  const publishKey = parsePublishKey();
  if (!publishKey) {
    alert("URLをご確認下さい");
    throw new Error("URLをご確認下さい");
  }
  const responsePlan: IPlan = await new ApiClient().findPlan(publishKey);
  setActivePlan(responsePlan);
  return responsePlan;
};

export const parseMitsumori = (mitsumori: number) => {
  // 50万円単位で切り上げ
  const MitsumoriOffset = 500000;
  const newValue = Math.ceil(mitsumori / MitsumoriOffset) * MitsumoriOffset;
  return newValue;
};

export const withComma = (value: number) => {
  const valueWithComma = `${value}`.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
  return valueWithComma;
};

export const parsePriceFormat = (price: number) => {

  const session = getSession();
  var showFlg = false;
  if(session && session.accepted == true && session.matter_type == 2){
    showFlg = true;
  }

  const priceWithComma = withComma(Math.abs(price));
  if(showFlg == true){
    return `¥${priceWithComma}`;
  } else {
    return "";
  }
};

export const handleRequestError = (error: any, history: any) => {
  if (error.statusCode === HttpStatusCode.REQUEST_INVALID) {
    alert("お使いのアカウントは利用が停止されています");
    history.push("/");
  }
};

export const scrollFromQueryParam = (key: string, timeoutMilliSec?: number, durationMilliSec?: number) => {
  const searchParams = new URLSearchParams(window.location.search.substring(1));
  const timeout = timeoutMilliSec || 300;
  const durate = durationMilliSec || 250;
  const anchor = searchParams.get(key) || "";
  if (anchor) {
    const ScrollElement = Scroll.Element;
    const scroller = Scroll.scroller;
    setTimeout(() => {
      scroller.scrollTo(anchor, {
        duration: durate,
        delay: 0,
        offset: -100,
        smooth: true
      });
    }, timeout);
  }
};

/** 【リノべる様イベントON/OFF設定】    
 * true(ON)：devtest    
 *    ・概算価格：¥10,000,000で固定表示    
 *    ・部材価格：非表示    
 *    ・標準パーツからの増減額：非表示    
 *    
 * false(OFF)：development/develop/staging/production    
 *    ・概算価格：通常表示    
 *    ・部材価格：通常表示    
 *    ・標準パーツからの増減額：通常表示
*/
export const eventVersionPriceDisplay: Boolean = (getCurrentEnv() == Env.devtest) ? false : false;

/** 【見える化カスタマイズON/OFF設定】    
*/
export const visualizationFuncAvailable = (session: ISession | null) => {
  if (session && session.visualization_func_status) {
    return true;
  } else {
    return false;
  }
};

/** 【前輪化価格表示ON/OFF設定】    
*/
export const showThePrice = (session: ISession | null) => {
  if (session && session.accepted == true && session.matter_type == 2) {
    return true;
  } else {
    return false;
  }
};

/** 【合成イメージアップロード状況管理用】    
*/
let savingUserMadeImageStatus: boolean = false;
export const setSavingUserMadeImageStatus = async (bool: boolean) => {
  savingUserMadeImageStatus = bool;
  return;
};
export const getSavingUserMadeImageStatus = async () => {
  return savingUserMadeImageStatus;
};
export const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));