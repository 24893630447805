import { Action, Reducer } from "redux";

export enum RedirectState {
  waitShowConfirm,
  needShowConfirm,
  needRedirect,
  notRedirect
}

export interface ISessionRestoreState {
  redirectState: RedirectState;
}

const initialState: ISessionRestoreState = {
  redirectState: RedirectState.waitShowConfirm
};

export enum ActionName {
  setRedirectState = "sessionRestore/setRedirectState"
}

export interface ISetRedirectStateAction
  extends Action<ActionName.setRedirectState> {
  redirectState: RedirectState;
}

export const sessionRestoreReducer: Reducer<
  ISessionRestoreState | undefined,
  ISetRedirectStateAction
> = (state = initialState, action) => {
  switch (action.type) {
    case ActionName.setRedirectState: {
      return { ...state, redirectState: action.redirectState };
    }
  }
  return state;
};
