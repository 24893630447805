import * as React from "react";

interface IOuterProps {
  title: string;
  text: string;
  okText: string;
  onClickOk?: (e: any) => void;
}

type AllProps = IOuterProps;

const Done = (props: AllProps) => {
  const { title, text, okText, onClickOk } = props;

  return (
    <div
      className="c-modal--alert"
      data-modal="active"
      data-modal-color="light_trans_nav"
    >
      <div className="c-modal-inwrapper information">
        <div
          className="c-modal-inwrapper-content"
          style={{ overflowY: "hidden" }}
        >
          <div className="c-layout">
            <div className="c-layout-child">
              <div className="u-fs--base">{title}</div>
              <div className="u-fs--small">
                {text.split("\n").map((str, index) => (
                  <React.Fragment key={index}>
                    {str}
                    <br />
                  </React.Fragment>
                ))}
              </div>
            </div>
            <div className="c-layout-child">
              <div onClick={onClickOk} style={{ textAlign: "center" }}>
                <div className="c-button c-modal_close">
                  <div className="c-button-strings">
                    <div className="u-tc--success">{okText}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Done;
