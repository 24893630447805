import React, { useState, useEffect } from "react";
import { RouteComponentProps } from "react-router";
import queryString from "query-string";

import Loading from "../../../component/loading";
import CaseDetailUI from "../../case/detail/ui";
import "./style.css";

const PreviewCaseDetail = (props: RouteComponentProps) => {
  const [imageUrl, setImageUrl] = useState<string | null>(null);
  const { search } = props.location;

  useEffect(() => {
    const queryImageUrl = queryString.parse(search).image_url as string;

    if (queryImageUrl) {
      setImageUrl(queryImageUrl);
      return;
    }

    window.addEventListener('message', event => {
      setImageUrl(event.data || "https://d1kj7933n1tx2w.cloudfront.net/images/rooms/icon_image_default.png");
    });
  }, [search]);

  if (imageUrl === null) {
    return <Loading />;
  }

  return (
    <div style={{ height: "100%" }}>
      <CaseDetailUI
        imageUrl={imageUrl}
        isSelected={false}
        onSelect={() => { }}
        onClose={() => { }}
      />
    </div>
  );
};

export default PreviewCaseDetail;
