import * as React from "react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import { Dispatch } from "redux";
import sendPageView from "../../../analytics";
import { ApiClient } from "../../../api";
import {
  IUpdatePlanParam,
  IUpdatePlanPartParam
} from "../../../api/request/plans";
import Divider from "../../../component/divider";
import Loading from "../../../component/loading";
import Navigation from "../../../component/navigation";
import IPartVariation from "../../../models/part_variation";
import { IPlan, IPlanInfo } from "../../../models/plan";
import { ISelectedPart, ISubCategoryParts } from "../../../models/plan_part";
import { ActionName as ActionNamePlan, IPlanAction } from "../../../redux/plan";
import {
  ActionName as ActionNameTastePart,
  ITastePartAction
} from "../../../redux/taste_part";
import {
  buildPlanQueryParam,
  handleRequestError,
  loadPlanPublishKey
} from "../../../util";
import PlanDuplicateDialog from "../../plan/duplicate_dialog";
import { PartDetailImageModal, PartDetailModal } from "../detail/index";
import PartCell, { PartState } from "./part_cell";

import "./style.css";

interface IPartListProps {
  planId: number;
  subCategoryId: number;
  planPartVariation: ISubCategoryParts;
  tastePartVariation: IPartVariation[];
  initialSelectedAllPart: ISelectedPart | undefined;
}
type AllProps = RouteComponentProps & IPartListProps;

const PartListHeader = (props: AllProps) => {
  return (
    <React.Fragment>
      <Navigation
        left={"一覧へ戻る"}
        onLeftClick={() => backPage(props)}
        title={props.planPartVariation.name}
      />
    </React.Fragment>
  );
};

const backPage = (props: AllProps) => {
  const searchParams = new URLSearchParams(window.location.search.substring(1));
  const scrollId = searchParams.get("scroll_id") || "";
  searchParams.get("is_share")
    ? props.history.push(
        `/share/detail${buildPlanQueryParam()}&scroll_id=${scrollId}`
      )
    : props.history.push(
        `/part/home${buildPlanQueryParam()}&scroll_id=${scrollId}`
      );
};

const forwardAllListPage = (
  props: AllProps & IPlanUpdateProps,
  resultPlan: IPlan
) => {
  if (resultPlan.status !== `locked`) {
    const searchParams = new URLSearchParams(
      window.location.search.substring(1)
    );
    const scrollId = searchParams.get("scroll_id") || "";
    props.history.push(
      `/part/all_list${buildPlanQueryParam()}&sub_category_id=${
        props.subCategoryId
      }&scroll_id=${scrollId}`
    );
  } else {
    props.dispatcherPlan({
      type: ActionNamePlan.showDuplicateConfirm,
      showDuplicateConfirm: true
    });
  }
};

const PartListFooter = (props: AllProps) => {
  return (
    <React.Fragment>
      <div className="c-footer">
        <Divider />
        <div className="c-footer-buttons" onClick={() => backPage(props)}>
          <span className="c-footer-buttons-main c-button">これで決定する</span>
        </div>
      </div>
    </React.Fragment>
  );
};

const PartListContent = (props: AllProps & IPlanUpdateProps) => {
  // 最新パーツ
  const planSelector = (state: any) => state.planReducer.fetchedPlan;
  const resultPlan: IPlan = useSelector(planSelector);
  // 選択部材
  const [selectedPartVariationId, setSelectedPartVariationId] = useState(0);
  // 画面初回表示時の選択部材
  const initialSelectedAllPart = props.initialSelectedAllPart;
  // 現在のサブカテゴリに紐づくプラン側のパーツ
  const resultPlanPartVariation:
    | ISubCategoryParts
    | undefined = resultPlan.categories
    .map(c => c.sub_categories)
    .flat()
    .find(sc => sc.id === props.subCategoryId);

  const dataModalTarget =
    resultPlan.status !== "locked"
      ? { "data-modal-target": "comment_modal" }
      : {};

  const onDuplicate =
    resultPlan.status !== "locked"
      ? () => <></>
      : () => {
          props.dispatcherPlan({
            type: ActionNamePlan.showDuplicateConfirm,
            showDuplicateConfirm: true
          });
        };

  const memo =
    resultPlanPartVariation && resultPlanPartVariation.selected_part.memo;
  const formattedMemo = `${memo}`.substr(0, 50);

  return (
    <React.Fragment>
      <div className="c-layout">
        <div className="c-layout-child">
          {/* note */}
          <div className="c-group--comment">
            <div className="c-group--comment-textarea">
              <textarea
                className="on_light c-modal_toggle toggle-comment-modal"
                {...dataModalTarget}
                placeholder="ここにない部材を見たい、部屋毎に部材を変えたい等の希望をここにメモしましょう！(50文字まで)"
                readOnly={true}
                maxLength={50}
                value={formattedMemo}
                onClick={onDuplicate}
              />
            </div>
            <div className="c-group--button_area">
              <div
                onClick={onDuplicate}
                className="c-button c-modal_toggle"
                {...dataModalTarget}
              >
                <i className="material-icons">create</i>
              </div>
            </div>
          </div>

          {/* list header */}
          <div className="c-group--pageheader">
            <div className="c-pageheader">
              <hr className="c-separator" />
            </div>
          </div>

          {/* list */}
          <div className="c-group--part_list">
            <div className="c-list--equal" data-list_column="2">

              {props.tastePartVariation.map(pv => {

                // 選択状態
                const selected =
                  selectedPartVariationId > 0
                    ? selectedPartVariationId === pv.part_variation_id
                    : props.planPartVariation.selected_part
                        .part_variation_id === pv.part_variation_id || false;

                const selectedItem =
                    selectedPartVariationId > 0
                        ? props.tastePartVariation.find( pv => pv.part_variation_id === selectedPartVariationId )
                        : props.tastePartVariation.find( pv => pv.part_variation_id === props.planPartVariation.selected_part.part_variation_id );


                const hideForUser =
                    selectedItem === undefined ? false : selectedItem.hide_for_user;
                const beforeIsDefault =
                    selectedItem === undefined ? true : selectedItem.is_default;

                // 選択されていないデフォルト以外で、かつ非表示フラグがtrueの部材を非表示
                if(pv.hide_for_user && !selected && !pv.is_default){
                  return
                }

                return (
                  <PartCell
                    {...props}
                    key={pv.id}
                    id={pv.id}
                    name={pv.name}
                    coverImage={pv.images.length > 0 ? pv.images[0] : ""}
                    images={pv.images}
                    partNumber={pv.part_number}
                    materialName={pv.material_name}
                    description={pv.note}
                    priceDifference={pv.price_difference}
                    state={PartState.default}
                    isDefault={pv.is_default}
                    hideForUser={hideForUser}
                    beforeIsDefault={beforeIsDefault}
                    selected={selected}
                    onClick={(e: any) => {
                      setSelectedPartVariationId(0);
                      sendPageView(
                        "部材詳細画面",
                        `/part/detail/${pv.part_id}`
                      );
                    }}
                    onSelect={async (e: any) => {
                      await onPartSelect(
                        props,
                        {
                          id: props.planPartVariation.selected_part.id,
                          part_variation_id: pv.part_variation_id,
                          sub_category_id: props.subCategoryId,
                          is_change: !selected,
                          is_default: pv.is_default,
                          memo: props.planPartVariation.selected_part.memo || ""
                        },
                        resultPlan,
                        pv.part_variation_id,
                        e,
                        setSelectedPartVariationId
                      );
                    }}
                  />
                );
              })}
              {

                initialSelectedAllPart &&
                  !initialSelectedAllPart.exists_taste_part_variation && (
                <PartCell
                  {...props}
                  key={initialSelectedAllPart.id}
                  id={initialSelectedAllPart.id}
                  name={initialSelectedAllPart.name}
                  coverImage={initialSelectedAllPart.images.length > 0 ? initialSelectedAllPart.images[0] : ""}
                  images={initialSelectedAllPart.images}
                  partNumber={initialSelectedAllPart.partNumber}
                  materialName={initialSelectedAllPart.material_name}
                  description={initialSelectedAllPart.note}
                  priceDifference={initialSelectedAllPart.price_difference}
                  state={PartState.default}
                  isDefault={initialSelectedAllPart.is_default}
                  isSelectFromAll={true}
                  hideForUser={false}
                  beforeIsDefault={true}
                  selected={
                    selectedPartVariationId > 0
                      ? selectedPartVariationId === initialSelectedAllPart.part_variation_id
                      : props.planPartVariation.selected_part.part_variation_id === initialSelectedAllPart.part_variation_id || false
                  }
                  onClick={(e: any) => {
                    setSelectedPartVariationId(0);
                    sendPageView(
                      "部材詳細画面",
                      `/part/detail/${initialSelectedAllPart.part_id}`
                    );
                  }}
                  onSelect={async (e: any) => {
                    await onPartSelect(
                      props,
                      {
                        id: initialSelectedAllPart.id,
                        part_variation_id:
                          initialSelectedAllPart.part_variation_id,
                        sub_category_id: props.subCategoryId,
                        is_change: true,
                        is_default: false,
                        memo: initialSelectedAllPart.memo || ""
                      },
                      resultPlan,
                      initialSelectedAllPart.part_variation_id,
                      e,
                      setSelectedPartVariationId
                    );
                  }}
                />
              )}
            </div>
          </div>
        </div>
        {/* list footer */}
        <div className="c-group--notes">
          <div className="u-centered u-tc--on_light-tertiary u-fs--small">
            ※価格は間取や部材、現場の条件等により変化するため、詳細確定後にご案内します。アプリ上の金額には概算金額で変更が反映されます。
          </div>
        </div>
        <PlanDuplicateDialog
          plan={resultPlan}
          renderPlanDeleteDialog={true}
          redirectUrlWithoutPublishKey={`${window.location.pathname}?sub_category_id=${props.subCategoryId}&`}
        />
      </div>
    </React.Fragment>
  );
};

// 部材選択時の処理(部材選択、部材モーダル)
const onPartSelect = async (
  props: AllProps & IPlanUpdateProps,
  updatePlanPartVariation: IUpdatePlanPartParam,
  resultPlan: IPlan,
  selectedPartVariationId: number,
  e: any,
  setSelectedPartVariationId?: (index: number) => void
) => {
  if (resultPlan.status !== `locked`) {
    // 部材詳細-トラッキング停止
    e.stopPropagation();
    const updateParam: IUpdatePlanParam = {
      name: resultPlan.name,
      memo: resultPlan.memo,
      plan_part_variations: [updatePlanPartVariation]
    };
    setSelectedPartVariationId &&
      setSelectedPartVariationId(selectedPartVariationId);
    await updatePlan(
      props.dispatcherPlan,
      updateParam,
      props.planId,
      (plan: IPlanInfo) => {}
    );
  } else {
    props.dispatcherPlan({
      type: ActionNamePlan.showDuplicateConfirm,
      showDuplicateConfirm: true
    });
  }
};
const PartListModalMemo = (props: AllProps & IPlanUpdateProps) => {
  const [isReset, setReset] = useState(false);
  const formattedMemo = `${props.planPartVariation.selected_part.memo}`.substr(
    0,
    50
  );
  const [memo, setMemo] = useState(formattedMemo);

  // 最新パーツ
  const planSelector = (state: any) => state.planReducer.fetchedPlan;
  const resultPlan: IPlan = useSelector(planSelector);
  // reset
  useEffect(() => {
    if (isReset) {
      setMemo(formattedMemo);
      setReset(false);
    }
  }, [isReset]);

  return (
    <React.Fragment>
      <div
        id="comment_modal"
        className="c-modal--half"
        data-modal-color="light_trans_nav"
      >
        <div className="c-modal-inwrapper">
          <div className="c-modal-inwrapper-content">
            <div className="c-nav_bar">
              <div className="c-nav_bar-left">
                <div
                  className="c-button c-modal_close"
                  onClick={() => {
                    // キャンセル
                    setReset(true);
                  }}
                >
                  <div className="c-button-strings">キャンセル</div>
                </div>
              </div>
              <div className="c-nav_bar-center" />
              <div className="c-nav_bar-right">
                <div
                  className="c-button c-modal_close"
                  onClick={async () => {
                    if (resultPlan.status !== "locked") {
                      const updateParam: IUpdatePlanParam = {
                        name: resultPlan.name,
                        memo: resultPlan.memo,
                        plan_part_variations: [
                          {
                            id: props.planPartVariation.selected_part.id,
                            part_variation_id:
                              props.planPartVariation.selected_part
                                .part_variation_id,
                            sub_category_id: props.subCategoryId,
                            is_change:
                              props.planPartVariation.selected_part.is_change,
                            is_default:
                              props.planPartVariation.selected_part.is_default,
                            memo: memo ? memo : ""
                          }
                        ]
                      };
                      await updatePlan(
                        props.dispatcherPlan,
                        updateParam,
                        props.planId,
                        (plan: IPlanInfo) => {
                          // console.log('onUpdated');
                        }
                      );
                    } else {
                      props.dispatcherPlan({
                        type: ActionNamePlan.showDuplicateConfirm,
                        showDuplicateConfirm: true
                      });
                    }
                  }}
                >
                  <div className="c-button-strings u-tc--success">保存</div>
                </div>
              </div>
            </div>
            <div className="c-layout">
              <div className="c-layout-child">
                <textarea
                  className="on_light"
                  placeholder="ここにない部材を見たい、部屋毎に部材を変えたい等の希望をここにメモしましょう！(50文字まで)"
                  value={memo}
                  maxLength={50}
                  onChange={e => {
                    setMemo(e.target.value);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

interface IPlanUpdateProps {
  dispatcherPlan: Dispatch<IPlanAction>;
}
const PartListModal = (props: AllProps & IPlanUpdateProps) => {
  // 最新パーツ
  const planSelector = (state: any) => state.planReducer.fetchedPlan;
  const resultPlan: IPlan = useSelector(planSelector);
  const [index, setIndex] = useState(0);
  // 画面初回表示時の選択部材
  const initialSelectedAllPart = props.initialSelectedAllPart;

  return (
    <React.Fragment>
      {/* メモのModal */}
      <PartListModalMemo
        {...props}
        planPartVariation={props.planPartVariation}
      />

      {/* テイスト側のModal */}
      {props.tastePartVariation.map(pv => {
        // 選択状態
        const selected =
          props.planPartVariation.selected_part.part_variation_id ===
            pv.part_variation_id || false;

        return (
          <React.Fragment key={pv.id}>
            <PartDetailModal
              id={pv.id}
              name={pv.name}
              partNumber={pv.part_number}
              description={pv.note}
              images={pv.images}
              material_name={pv.material_name}
              isShared={false}
              isSelected={selected}
              isDefault={pv.is_default}
              memo={""}
              price_difference={pv.price_difference}
              onBack={() => {
                sendPageView(
                  "部材選択画面",
                  `/part/list?sub_category_id=${props.subCategoryId}`
                );
              }}
              onChange={async (e: any) => {
                onPartSelect(
                  props,
                  {
                    id: props.planPartVariation.selected_part.id,
                    part_variation_id: pv.part_variation_id,
                    sub_category_id: props.subCategoryId,
                    is_change: !selected,
                    is_default: pv.is_default,
                    memo: props.planPartVariation.selected_part.memo || ""
                  },
                  resultPlan,
                  0,
                  e
                );
              }}
              onChangeIndex={cahngedIndex => {
                setIndex(cahngedIndex);
              }}
            />
            <PartDetailImageModal
              id={pv.id}
              images={pv.images}
              startIndex={index}
            />
          </React.Fragment>
        );
      })}
      {initialSelectedAllPart && !initialSelectedAllPart.exists_taste_part_variation && (
        <React.Fragment key={initialSelectedAllPart.id}>
          <PartDetailModal
            id={initialSelectedAllPart.id}
            name={initialSelectedAllPart.name}
            partNumber={initialSelectedAllPart.partNumber}
            description={initialSelectedAllPart.note}
            images={initialSelectedAllPart.images}
            material_name={initialSelectedAllPart.material_name}
            isShared={false}
            isSelected={
              props.planPartVariation.selected_part.part_variation_id ===
                initialSelectedAllPart.part_variation_id || false
            }
            isDefault={false}
            memo={""}
            price_difference={initialSelectedAllPart.price_difference}
            onBack={() => {
              sendPageView(
                "部材選択画面",
                `/part/list?sub_category_id=${props.subCategoryId}`
              );
            }}
            onChange={async (e: any) => {
              onPartSelect(
                props,
                {
                  id: props.planPartVariation.selected_part.id,
                  part_variation_id: initialSelectedAllPart.part_variation_id,
                  sub_category_id: props.subCategoryId,
                  is_change: true,
                  is_default: false,
                  memo: props.planPartVariation.selected_part.memo || ""
                },
                resultPlan,
                0,
                e
              );
            }}
            onChangeIndex={cahngedIndex => {
              setIndex(cahngedIndex);
            }}
          />
          <PartDetailImageModal
            id={initialSelectedAllPart.id}
            images={initialSelectedAllPart.images}
            startIndex={index}
          />
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

const updatePlan = async (
  dispatcherPlan: Dispatch<IPlanAction>,
  planParts: IUpdatePlanParam,
  planId: number,
  onUpdated: (plan: IPlanInfo) => void
) => {
  // プラン更新
  const result = await new ApiClient().updatePlan(planId, planParts);
  // 再取得
  const responsePlan = await new ApiClient().getPlan(planId);
  dispatcherPlan({
    type: ActionNamePlan.setPlan,
    fetchedPlan: responsePlan
  });
  onUpdated(result);
};

const PartList = (props: AllProps) => {
  const [loading, setLoading] = useState(false);

  // planId,publishKey
  const searchParams = new URLSearchParams(window.location.search.substring(1));
  const subCategoryId = Number(searchParams.get("sub_category_id")) || 0;

  // plan
  const planSelector = (state: any) => state.planReducer.fetchedPlan;
  const resultPlan: IPlan = useSelector(planSelector);
  const dispatcherPlan = useDispatch<Dispatch<IPlanAction>>();
  // 現在のサブカテゴリに紐づくプラン側のパーツ
  const resultPlanPartVariation:
    | ISubCategoryParts
    | undefined = resultPlan.categories
    .map(c => c.sub_categories)
    .flat()
    .find(sc => sc.id === subCategoryId);
  const planId = resultPlan.id;

  // 画面初回表示時の選択部材
  const initialSelectedAllPart: ISelectedPart | undefined = useMemo(() => {
    return resultPlanPartVariation && resultPlanPartVariation.selected_part;
  }, [resultPlanPartVariation !== undefined]);

  // taste
  const tastePartSelector = (state: any) =>
    state.tastePartReducer.fetchedTasteParts;
  const resultTastePart: IPartVariation[] = useSelector(tastePartSelector);
  const dispatcherTastePart = useDispatch<Dispatch<ITastePartAction>>();

  const fetchContents = useCallback(async () => {
    setLoading(true);
    try {
      // publish_key -> plan
      const responsePlan = await loadPlanPublishKey();

      // plan
      dispatcherPlan({
        type: ActionNamePlan.setPlan,
        fetchedPlan: responsePlan
      });

      // tastePart
      const responseTastePart = await new ApiClient().getPartVariations(
        responsePlan.taste.id,
        Number(subCategoryId),
        responsePlan.publish_key
      );

      dispatcherTastePart({
        type: ActionNameTastePart.setTasteParts,
        fetchedTasteParts: responseTastePart
      });
      setLoading(false);
    } catch (e) {
      setLoading(false);
      handleRequestError(e, props.history);
    }
  }, [dispatcherPlan, dispatcherTastePart]);

  useEffect(() => {
    fetchContents();
  }, [fetchContents]);

  if (
    resultPlan === undefined ||
    loading ||
    resultPlanPartVariation === undefined ||
    resultTastePart === undefined
  ) {
    return <Loading />;
  }
  if (resultPlan.taste.id === 0) {
    return <p className="u-centered">存在しないプランです</p>;
  }

  return (
    <div className="t-part-select">
      <PartListHeader {...props} planPartVariation={resultPlanPartVariation} />
      <PartListContent
        {...props}
        dispatcherPlan={dispatcherPlan}
        planId={planId}
        subCategoryId={Number(subCategoryId)}
        planPartVariation={resultPlanPartVariation}
        tastePartVariation={resultTastePart}
        initialSelectedAllPart={initialSelectedAllPart}
      />
      <PartListModal
        {...props}
        dispatcherPlan={dispatcherPlan}
        planId={planId}
        subCategoryId={Number(subCategoryId)}
        planPartVariation={resultPlanPartVariation}
        tastePartVariation={resultTastePart}
        initialSelectedAllPart={initialSelectedAllPart}
      />
      <PartListFooter {...props} />
    </div>
  );
};

export default PartList;
