import ISession from "../models/session";
import { IPlan } from "../models/plan";

interface ILocalStorage {
  session_v2: ISession;
}

export const getLocalStorage = <T extends keyof ILocalStorage>(key: T) => {
  const ls: ILocalStorage[T] | null = JSON.parse(
    localStorage.getItem(key) || "null"
  );
  return ls;
};

export const setLocalStorage = <T extends keyof ILocalStorage>(
  key: T,
  value: ILocalStorage[T]
) => {
  localStorage.setItem(key, JSON.stringify(value));
  return;
};

export const getSession = (): ISession | null => {
  const res = getLocalStorage("session_v2");
  return res;
};

export const setSession = (s: ISession) => {
  setLocalStorage("session_v2", s);
};

export const disablePartResetConfirm = () => {
  const session = getSession();
  if (!session) {
    return;
  }
  session.show_reset_part_confirm = false;
  setSession(session);
};

export const enablePartResetConfirm = () => {
  const searchParams = new URLSearchParams(window.location.search.substring(1));
  if (searchParams.get("scroll_id")) {
    return false;
  }
  const session = getSession();
  if (!session) {
    return;
  }
  session.show_reset_part_confirm = true;
  setSession(session);
};

export const isShowPartResetConfirm = () => {
  const session = getSession();
  if (!session) {
    return false;
  }
  return session.show_reset_part_confirm || false;
};

export const permanentSelectSquareMeter = (squareMeter: number) => {
  const session = getSession();
  if (!session) {
    return;
  }
  session.select_square_meter = squareMeter;
  setSession(session);
};

export const setActivePlan = (plan: IPlan) => {
  const session = getSession();
  if (!session) {
    return;
  }
  session.plan_id = plan.id;
  session.publish_key = plan.publish_key;
  setSession(session);
};
