import * as React from "react";
import { useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import Carousel from "../../../component/carousel";
import Confirm from "../../../component/confirm";
import Divider from "../../../component/divider";
import {
  disablePartResetConfirm,
  isShowPartResetConfirm
} from "../../../session";
import { parsePriceFormat, eventVersionPriceDisplay, showThePrice } from "../../../util";
import { priceDiffState } from "../price_diff";
import { getSession } from "../../../session";
interface IOutProps {
  id: number;
  name: string;
  partNumber: string;
  images: string[];
  material_name: string;
  description: string;
  isSelected?: boolean;
  isShared?: boolean;
  isDefault?: boolean;
  memo?: string;
  price_difference: number;
  onBack?: () => void;
  onChange?: (e: any) => Promise<void>;
  onRedirect?: () => void;
  onChangeIndex?: (index: number) => void;
  guardConfirm?: boolean;
}

type AllProps = IOutProps;

export const PartDetailModal = (props: AllProps) => {
  const { id } = props;

  return (
    <div
      className="c-modal"
      id={`details_modal_${id}`}
      data-modal-color="light_trans_nav"
    >
      <PartDetailModalUI {...props} />
    </div>
  );
};

export const PartDetailModalUI = (props: AllProps) => {
  const [showResetPartConfirm, setShowResetPartConfirm] = useState(false);
  const selectPartClassName = isShowPartResetConfirm()
    ? "c-footer-buttons-main c-button u-fc--tint u-tc--on_tint"
    : "c-footer-buttons-main c-button u-fc--tint u-tc--on_tint c-modal_close";

  const {
    id,
    name,
    partNumber,
    images,
    material_name,
    description,
    isSelected,
    isShared,
    isDefault,
    memo,
    price_difference,
    onBack,
    onChange,
    onChangeIndex,
    guardConfirm
  } = props;

  return (
    <div className="c-modal-inwrapper">
      <div className="c-modal-inwrapper-content">
        <div className="c-nav_bar u-bc--transparent">
          <div className="c-nav_bar-left">
            <div
              className="c-button--circle u-fc--on_dark-quatiary c-modal_close"
              onClick={onBack}
            >
              <i className="u-tc--on_light-primary material-icons">close</i>
            </div>
          </div>
        </div>
        <div className="c-layout">
          <div className="c-layout-child">
            {images && images.length > 0 ? (
              <Carousel
                centerPadding={"0%"}
                className={"c-carousel"}
                dot={true}
                swipe={true}
                arrows={true}
                infinite={true}
                afterChange={onChangeIndex}
              >
                {images.map((url, index) => {
                  if (!url) {
                    return;
                  }
                  return (
                    <div
                      className="c-thumbnail c-modal_toggle c-modal-detail-image"
                      data-object_fit="contain"
                      style={{ width: "100%", height: "50vh" }}
                      key={index}
                      data-modal-target={`detail_images_modal_${id}`}
                    >
                      <img
                        src={url}
                        style={{ maxWidth: "768px", margin: "auto" }}
                        alt="thumbnail"
                      />
                    </div>
                  );
                })}
              </Carousel>
            ) : (
              <div
                className="c-thumbnail"
                data-object_fit="contain"
                style={{ width: "100%", height: "50vh" }}
              >
                <img
                  src={"https://placehold.jp/640x450.png?text=noimage"}
                  alt="thumbnail"
                />
              </div>
            )}
            <div className="c-group--part_details">
              <div className="c-group--part_details-material">
                <div className="c-group--part_details-material-material_name">
                  {material_name}
                </div>
                <div
                  className="c-group--part_details-material-description"
                  dangerouslySetInnerHTML={{
                    __html: description.replace(/[\n]/g, "<br />")
                  }}
                />
              </div>
              {((isDefault && isDefault) || !(showThePrice(getSession())) || eventVersionPriceDisplay) ? (
                <></>
              ) : (
                <div
                  className="c-group--part_details-price"
                  data-state={priceDiffState(price_difference)}
                >
                  <div className="c-group--part_details-price-guide">標準パーツから</div>
                  <div className="c-group--part_details-price-icon_up">Up</div>
                  <div className="c-group--part_details-price-icon_down">Down</div>
                  <div className="c-group--part_details-price-icon_flat">変更なし</div>
                  {parsePriceFormat(price_difference)}
                </div>
              )}
              <div className="c-group--part_details-name">
                <div className="c-group--part_details-name-product_name">
                  {name}
                </div>
                <div className="c-group--part_details-name-code">
                  {partNumber}
                </div>
              </div>
            </div>
          </div>
          {isSelected ? null : (
            <div className="c-layout-child">
              <div className="c-group--footer">
                <div className="c-footer">
                  <Divider />
                  {memo}
                  <div className="c-footer-buttons">
                    {isShared ? (
                      <React.Fragment></React.Fragment>
                      ) : (
                        <div
                          className={selectPartClassName}
                          onClick={e => {
                            if (isShowPartResetConfirm()) {
                              if (guardConfirm === undefined) {
                                // プレビューの場合(guardConfirm=true)ダイアログは表示させない
                                setShowResetPartConfirm(true);
                              }
                              return;
                            }
                            if (onChange) {
                            onChange(e);
                            }
                          }}
                        >
                          <div className="c-button-strings">これに変更する</div>
                        </div>
                      )
                    }
                  </div>
                </div>
              </div>
            </div>
          )}
          {!isSelected && showResetPartConfirm && (
            <Confirm
              title="部材を変更すると、以前の選択が上書きされます。変更しますか？"
              text="※他に編集する方や別の端末がある場合は、そちらへの上書きにご注意ください。"
              okText="はい"
              cancelText="キャンセル"
              onClickOk={(e: any) => {
                // state制御:確認ダイアログを非表示
                setShowResetPartConfirm(false);
                // session制御:確認ダイアログを無効
                disablePartResetConfirm();
                // 部材の選択処理
                if (props.onChange) {
                  props.onChange(e);
                }
              }}
              onClickCancel={() => setShowResetPartConfirm(false)}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export const PartDetailImageModal = (props: {
  id: number;
  images: string[];
  onClose?: () => void;
  startIndex?: number;
}) => {
  const { id, images, onClose, startIndex } = props;

  return (
    <div
      className="c-modal c-modal-image"
      id={`detail_images_modal_${id}`}
      data-modal-color="dark_trans_nav"
    >
      <div className="c-modal-inwrapper">
        <div className="c-modal-inwrapper-content">
          <div className="c-nav_bar u-bc--transparent">
            <div className="c-nav_bar-left">
              <div
                className="c-button--circle u-fc--on_dark-quatiary c-modal_close"
                onClick={onClose}
              >
                <i className="u-tc--on_light-primary material-icons">close</i>
              </div>
            </div>
          </div>
          <Carousel
            centerPadding={"0%"}
            className={"c-carousel"}
            dot={true}
            swipe={true}
            arrows={true}
            infinite={true}
            initialSlide={startIndex}
            self={slider => {
              if (slider) {
                slider.slickGoTo(startIndex);
              }
            }}
          >
            {images &&
              images.map((url, index) => {
                return <img src={url} alt="thumbnail" key={index} />;
              })}
          </Carousel>
        </div>
      </div>
    </div>
  );
};
const PartDetail = (props: RouteComponentProps) => {
  const dummy: string[] = [
    "https://placehold.jp/640x450.png",
    "https://placehold.jp/640x450.png",
    "https://placehold.jp/640x450.png"
  ];
  return (
    <div className="t-part-select">
      <div className="c-layout">
        <div className="c-layout-child">
          <div
            className="c-button u-fc--tint u-tc--on_tint c-modal_toggle"
            data-modal-target="details_modal_1"
          >
            modal
          </div>
        </div>
      </div>
      <PartDetailModal
        id={1}
        name={"name"}
        partNumber={"partNumber"}
        description={"description"}
        images={dummy}
        material_name={"material_name"}
        isShared={true}
        memo={"memo"}
        price_difference={0}
        onBack={() => props.history.goBack()}
      />
      <PartDetailImageModal id={1} images={dummy} />
    </div>
  );
};

export default PartDetail;
