import * as React from "react";
// @ts-ignore
import FadeIn from "react-lazyload-fadein";
import sendPageView from "../../../analytics";

interface IOuterProps {
  id: number;
  imageUrl?: string;
  selected: boolean;
  isReadable?: boolean;
  onSelect?: (e: any) => void;
  onLoaded?: (url: string | undefined) => void;
}

type AllProps = IOuterProps;

const CaseCell = (props: AllProps) => {
  const { id, imageUrl, selected, isReadable, onSelect, onLoaded } = props;

  return (
    <div
      className="c-list-item"
      style={{ transform: isReadable ? "initial" : "translate3d(0,0,0)" }}
    >
      <div
        onClick={(e: any) => {
          sendPageView("事例詳細画面", `/case/detail/${id}`);
        }}
        className="c-thumbnail--round_all c-modal_toggle"
        style={{ width: "100%" }}
        data-modal-target={`case_details_${id}`}
        data-state={selected ? "selected" : "unselect"}
      >
        <FadeIn height={200}>
          {(onload: () => void) => {
            if (onLoaded) {
              onLoaded(imageUrl);
            }
            return (
              <img
                src={
                  imageUrl === ""
                    ? "https://d1kj7933n1tx2w.cloudfront.net/images/rooms/icon_image_default.png"
                    : imageUrl
                }
                onLoad={onload}
                alt="画像が設定されていません"
              />
            );
          }}
        </FadeIn>
        {isReadable ? null : (
          <div className="c-button modal-ignore" onClick={onSelect}>
            <i className="material-icons modal-ignore">check_circle</i>
          </div>
        )}
      </div>
    </div>
  );
};

export default CaseCell;
