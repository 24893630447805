import { Action, Reducer } from "redux";
import { IUser } from "../models/user";

export interface IUserState {
  userState: IUser;
}

const initialState: IUserState = {
  userState: {
    id: 0,
    first_login: false,
    login_key: "",
    visualization_func_status: false,
    accepted: false,
    matter_type_id: 0,
    plan: {
      id: 0,
      publish_key: "",
      login_key: "",
      name: "",
      memo: "",
      taste: {
        id: 0,
        title: "",
        color_keywords: "",
        style_keywords: "",
        price_area: 0,
        note: "",
        sort_order: 0,
        images: [],
        colors: [],
        recommends: [],
        base_images: {
          id: 0,
          image_url_01: "",
          image_url_02: "",
          range_hood_url_01: "",
          range_hood_url_02: "",
          spotlight_url_01: "",
          spotlight_url_02: "",
          curtain_url: "",
          curtain_url_02: ""
        }
      },
      total_price: 0,
      total_price_difference: 0,
      square_meter: 0,
      categories: [],
      is_share: false,
      status: "",
      thumbnail_url: "",
      created_at: "",
      updated_at: "",
      is_over: false,
      exist: false,
      hide_taste: false,
      user_made_images: {}
    }
  }
};

export enum ActionName {
  setUser = "user/setUser"
}

interface ISetUser extends Action<ActionName.setUser> {
  userState: IUser;
}

export type IUserAction = ISetUser;

export const userReducer: Reducer<IUserState | undefined, ISetUser> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case ActionName.setUser: {
      return { ...state, setUserState: action.userState };
    }
  }
  return state;
};
