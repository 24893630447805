import * as React from "react";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Dispatch } from "redux";
import { ApiClient } from "../../api";
import Divider from "../../component/divider";
import Header from "../../component/header";
import Navigation from "../../component/navigation";
import Loading from "../../component/loading";
import { IPlan } from "../../models/plan";
import { IPlanParts } from "../../models/plan_part";
import {IParseImageForApi} from "../../models/part_variation";
import { ActionName as ActionNamePlan, IPlanAction } from "../../redux/plan";
import { ActionName, IPlanPartAction } from "../../redux/plan_part";
import { getSession } from "../../session";
import { handleRequestError, loadPlanPublishKey, parsePublishKey, setSavingUserMadeImageStatus, sleep } from "../../util";
import { IUpdatePlanPartParam } from "../../api/request/plans";
import BaseImage from "./base_image";
import VisualizationContent from "./visualization_content";
import { IUserMadeImageRequest, uploadUserMadeImage } from "../../lib/user_made_image_uploader";

export interface IPart {
  sub_category_id: number;
  part_id: number;
  part_variation_id: number;
  image_url_01: string;
  image_url_02: string;
  panel_url: string;
  is_default: boolean;
  recommend: boolean;
  is_checked: boolean;
  is_selected:boolean;
}

export interface ISubCategories{
  id: number;
  layerNo: string;
  name: string;
}

type AllProps = RouteComponentProps;

let resultPlan: IPlan;
let planPartsResult: IPlanParts[];
let newPart: IPart[][];

const addNowLoadingClass = () => {
  const headerLink: HTMLElement = document.getElementById('header-save-user-made-image-btn') as HTMLElement;
  if (headerLink !== null && headerLink !== undefined) {
    headerLink.classList.add("now-loading");
  }
  const partsArea: HTMLElement = document.getElementById('visualization-content-parts-area') as HTMLElement;
  if (partsArea !== null && partsArea !== undefined) {
    partsArea.classList.add("now-loading");
  }
  const footerBtn: HTMLElement = document.getElementById('footer-save-user-made-image-btn') as HTMLElement;
  if (footerBtn !== null && footerBtn !== undefined) {
    footerBtn.classList.add("now-loading");
  }
}

/* 見える化合成イメージ保存処理 */
async function saveUserMadeImage(props: AllProps) {
  // プログレスバー
  const progressBar = document.getElementById('saving-progress') as HTMLProgressElement;

  addNowLoadingClass();
  const session = getSession();
  const plan_id = session ? session.plan_id : 0;
  const publishKey = parsePublishKey();
  /** 見える化レイヤー数
   * 0. 戸境壁（壁(左)）
   * 1. アクセント壁（壁(右)）
   * 2. 天井
   * 3. ベースイメージ
   * 4. レンジフード
   * 5. スポットライト（※登録されている場合のみ）
   * 6. カーテン（※登録されている場合のみ・サイドビューのみ）
  */
  const numberOfLayers: number = 6;
  // フロントビュー
  const frontCanvas: HTMLCanvasElement = document.getElementById('frontCanvas') as HTMLCanvasElement;
        frontCanvas.width = 1920;
        frontCanvas.height = 1080;
  const frontContext: CanvasRenderingContext2D | null = frontCanvas.getContext('2d');
  // サイドビュー
  const sideCanvas: HTMLCanvasElement = document.getElementById('sideCanvas') as HTMLCanvasElement;
        sideCanvas.width = 1920;
        sideCanvas.height = 1080;
  const sideContext: CanvasRenderingContext2D | null = sideCanvas.getContext('2d');
  // 各レイヤーのイメージを最下層から順に描画
  let rejectStatus: boolean = false;
  for (let layerNo = 0; layerNo <= numberOfLayers; layerNo++) {
    // フロントビュー
    let frontElement: HTMLImageElement;
    let frontImage = new Image(1920, 1080);
    // ※フロントには「カーテン」レイヤーが無いため、最後1回分の処理は実行しない
    if (layerNo !== numberOfLayers) {
      await new Promise((resolve, reject) => {
        progressBar.value += 3;
        frontElement = document.getElementById(`img-layer${layerNo}`) as HTMLImageElement;
        if (frontElement === null || frontElement === undefined) {
          reject();
        }
        frontImage.addEventListener('load', function() {
          resolve(frontImage);
        }, false);
        frontImage.crossOrigin = "anonymous";
        frontImage.src = frontElement.src.includes("data:image/png") ? require("./bg.png") : frontElement.src + `?ver=front${layerNo}&param=${Math.random().toString(32).substring(2)}`;
      }).then((frontImage) => {
        frontContext!.drawImage((frontImage as HTMLImageElement), 0, 0);
        progressBar.value += 4;
      }).catch(() => {
        rejectStatus = true;
      });
    }
    // サイドビュー
    let sideElement: HTMLImageElement;
    let sideImage = new Image(1920, 1080);
    await new Promise((resolve, reject) => {
      progressBar.value += 3;
      sideElement = document.getElementById(`img2-layer${layerNo}`) as HTMLImageElement;
      if (sideElement === null || sideElement === undefined) {
        reject();
      }
      sideImage.addEventListener('load', function() {
        resolve(sideImage);
      }, false);
      sideImage.crossOrigin = "anonymous";
      sideImage.src = sideElement.src.includes("data:image/png") ? require("./bg.png") : sideElement.src + `?ver=side${layerNo}&param=${Math.random().toString(32).substring(2)}`;
    }).then((sideImage) => {
      sideContext!.drawImage((sideImage as HTMLImageElement), 0, 0);
      progressBar.value += 4;
    }).catch(() => {
      rejectStatus = true;
    });
  }

  if (rejectStatus) {
    return;
  }

  // プラン（選択パーツ）更新処理
  const plan_part_variations: IUpdatePlanPartParam[] = [];
  const subCategories: number[] = [40, 4, 1];
  for (const subCategoryId of subCategories) {
    for (const parts of newPart[subCategoryId]) {
      if (parts.is_checked === true) {
        let plan_part_id = 0;

        for (const planParts of planPartsResult) {
          for (const planPartsSubCategory of planParts.sub_categories) {
            if (subCategoryId === planPartsSubCategory.id) {
              plan_part_id = planPartsSubCategory.selected_part.id;
            }
          }
        }

        plan_part_variations.push(
          {
            id : plan_part_id,
            part_variation_id : parts.part_variation_id,
            is_change : true,
            is_default : parts.is_default,
            memo : '',
            sub_category_id:subCategoryId
          }
        );

        const updateParam = {
          name: resultPlan.name,
          memo: resultPlan.memo,
          plan_part_variations: plan_part_variations
        }

        progressBar.value += 1;
        await new ApiClient().updatePlan(plan_id, updateParam);
        progressBar.value += 2;
      }
    }
  }

  await sleep(150); // プログレスバーのステータスが100%になるのを視認できるよう、少しだけ待つ
  await setSavingUserMadeImageStatus(true); // アップロード+URL保存処理開始フラグを立てる

  // 住設選択へ遷移
  props.history.push(`/part/home?publish_key=${publishKey}`);

  // 合成イメージのアップロード＋アップロード先(S3)のURL取得
  const frontImageRequest: IUserMadeImageRequest = {
    id: plan_id,
    filetype: "image/png",
    extension: 'png',
    body: frontContext!.canvas.toDataURL(),
    filename: "frontImage"
  }
  const frontImageUrl: string = await uploadUserMadeImage(frontImageRequest);
  const sideImageRequest: IUserMadeImageRequest = {
    id: plan_id,
    filetype: "image/png",
    extension: 'png',
    body: sideContext!.canvas.toDataURL(),
    filename: "sideImage"
  }
  const sideImageUrl: string = await uploadUserMadeImage(sideImageRequest);

  // 合成イメージURLを保存
  await new ApiClient().updateUserMadeImages(plan_id, {image_url_01: frontImageUrl, image_url_02: sideImageUrl});
  await setSavingUserMadeImageStatus(false);
}

/* 「設備とオプションの選択へ」ボタン/リンクの状態変更 */
async function changeSaveBtnState(headerOrFooter: string) {
  const saveImageBtn = document.getElementById(`${headerOrFooter}-save-user-made-image-btn`) as HTMLImageElement;
  const saveImageBtnText = document.getElementById(`${headerOrFooter}-save-user-made-image-btn-text`) as HTMLImageElement;
  saveImageBtn.dataset.state = "disabled";
  saveImageBtnText.innerText = "イメージを保存中...";
}

const VisualizationHeader = (props: AllProps) => {
  const planSelector = (state: any) => state.planReducer.fetchedPlan;
  const resultPlan: IPlan = useSelector(planSelector);
  const session = getSession();
  if (resultPlan.status === "locked") {
    props.history.push(`/share/detail?publish_key=${resultPlan.publish_key}`);
  } else if (session && !(session.visualization_func_status)) {
    props.history.push(`/part/home?publish_key=${resultPlan.publish_key}`);
  }

  const [showProgressBar, setShowProgressBar] = useState(false);
  const [headerSaveImageBtnText, setSaveImageBtnText] = useState("設備とオプションの選択へ");
  const [headerSaveImageBtnStatus, setSaveImageBtnStatus] = useState("");

  async function whereToGoBack () {
    const publishKey = resultPlan.publish_key;
    const session = getSession();
    const select_case_ids = (session && session.select_case_ids !== "") ? session.select_case_ids : "0";
    const base_taste_id = (session && session.base_taste_id !== 0) ? session.base_taste_id : (resultPlan.taste.id !== null ? resultPlan.taste.id : 0);
    await new ApiClient().tasteReset(resultPlan.publish_key);
    let url: string = session ? session.from_where : `/taste/recommend?select_case_ids=${select_case_ids}&publish_key=${publishKey}`;
    if (url === "" || url === null || url === undefined) {
      if (base_taste_id !== 0) {
        url =`/taste/detail/${base_taste_id}/?select_case_ids=${select_case_ids}`;
      } else {
        url =`/taste/list/?recommends=0&select_case_ids=${select_case_ids}`;
      }
    }
    props.history.push(url);
  }

  return (
    <React.Fragment>
      <Navigation
        left={"戻る"}
        onLeftClick={() => whereToGoBack()}
        rightNode={
          <div className="c-nav_bar-right">
            <button
              id="header-save-user-made-image-btn"
              className="c-button now-loading"
              onClick={async () =>{
                // 保存ボタン非活性＋表示テキスト変更
                await setSaveImageBtnStatus("disabled");
                await setSaveImageBtnText("イメージを保存中...");
                // フッターの保存ボタンも同時に変更
                await changeSaveBtnState("footer");
                // 保存処理実行
                await setShowProgressBar(true);
                await saveUserMadeImage(props);
                await setShowProgressBar(false);
              }}
              data-state={headerSaveImageBtnStatus}
            >
              <span
                id="header-save-user-made-image-btn-text"
                className="c-button-strings"
              >
                {headerSaveImageBtnText}
              </span>
            </button>
          </div>
        }
      />
      <Header
        title="お部屋をアレンジしてみよう"
        description="※天井と壁を変えて理想のイメージに近づけてみましょう"
      />
      {showProgressBar ?
        <div
          className="c-modal--alert"
          data-modal="active"
          data-modal-color="light_trans_nav"
        >
          <div className="c-modal-inwrapper">
            <div
              className="c-modal-inwrapper-content"
              style={{ overflowY: "hidden" }}
            >
              <div className="c-layout">
                <div className="c-layout-child">
                  <div className="u-fs--base">
                    <label htmlFor="saving-progress">イメージを保存中...</label>
                    <progress id="saving-progress" max="100" value="0" className="saving-state">0%</progress>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        :
        <></>
      }
    </React.Fragment>
  );
};

const Visualization = (props: AllProps) => {
  /* 見える化で使用する中カテゴリ ※layerNo4~8は現在未使用（非表示） */
  const subCategories : ISubCategories[] = [
    {id:40, layerNo: "0", name:"壁(左)"},
    {id: 4, layerNo: "1", name:"壁(右)"},
    // {id: 2, layerNo: "2", name:"壁(正面)"},
    {id: 1, layerNo: "2", name:"天井"}
    // {id: 5, layerNo: "4", name:"カウンター壁"},
    // {id: 6, layerNo: "5", name:"キッチン床"},
    // {id: 3, layerNo: "6", name:"ベース床"},
    // {id:15, layerNo: "7", name:"室内窓"},
    // {id:16, layerNo: "8", name:"ドア"}
  ];
  /* 側面イメージが無い中カテゴリID */
  const noSideImageSubCategoryIds: number[] = [4];

  const [loading, setLoading] = useState(false);

  // planPart
  const planPartsSelector = (state: any) =>
    state.planPartReducer.fetchedPlanParts;
  const planParts: IPlanParts[] = useSelector(planPartsSelector);
  const dispatchPlanPart = useDispatch<Dispatch<IPlanPartAction>>();

  // plan
  const dispatcherPlan = useDispatch<Dispatch<IPlanAction>>();
  const planSelector = (state: any) => state.planReducer.fetchedPlan;
  resultPlan = useSelector(planSelector);
  const session = getSession();
  if (resultPlan.status === "locked") {
    props.history.push(`/share/detail?publish_key=${resultPlan.publish_key}`);
  } else if (session && !(session.visualization_func_status)) {
    props.history.push(`/part/home?publish_key=${resultPlan.publish_key}`);
  }

  // parts
  const [ parts, setParts ] = useState<IPart[][]>([]);

  const insertPartFromAllPartVariations = (newParts: IParseImageForApi[][]) => {
    // 見える化用パースデータ取得・加工
    subCategories.forEach(sc => {
      // 「標準パーツ→おすすめパーツ→その他のパーツ」の順に並べ替え
      if (newParts[sc.id] !== undefined) {
        newParts[sc.id].sort((a, b) => {
          if (a.is_default && !b.is_default) {
            return -1;
          } else if (!a.is_default && b.is_default) {
            return 1;
          } else if (a.recommend && !b.recommend) {
            return -1;
          } else if (!a.recommend && b.recommend) {
            return 1;
          } else {
            return 0;
          }
        })
        newParts[sc.id].forEach(p => {
          if (parts[sc.id] === undefined) {
            parts[sc.id] = [];
          }
          parts[sc.id].push({
            sub_category_id: sc.id ,
            part_id: p.part_id ,
            part_variation_id: p.part_variation_id,
            image_url_01: p.image_url_01,
            image_url_02: p.image_url_02,
            panel_url: p.panel_url,
            is_default: p.is_default,
            recommend: p.recommend,
            is_checked: false,
            is_selected: false
          });
        });
      }
    });
  };

  const fetchContents = useCallback(async () => {
    setLoading(true);
    try {
      const responsePlan = await loadPlanPublishKey();
      if (responsePlan.taste === null || responsePlan.taste === undefined) {
        const session = getSession();
        const select_case_ids = (session && session.select_case_ids !== "") ? session.select_case_ids : "0";
        const base_taste_id = (session && session.base_taste_id !== 0) ? session.base_taste_id : 0;
        await new ApiClient().tasteReset(resultPlan.publish_key);
        let url: string = session ? session.from_where : `/taste/recommend?select_case_ids=${select_case_ids}&publish_key=${responsePlan.publish_key}`;
        if (url === "" || url === null || url === undefined) {
          if (base_taste_id !== 0) {
            url =`/taste/detail/${base_taste_id}/?select_case_ids=${select_case_ids}`;
          } else {
            url =`/taste/list/?recommends=0&select_case_ids=${select_case_ids}`;
          }
        }
        props.history.push(url);
        return;
      }
      const visualizationFlg = await new ApiClient().getVisualizationFuncStatus(responsePlan.taste.id);
      if(visualizationFlg.status === false){
        // 見える化で使用できない（ベースイメージ登録不足）のテイストの場合は、次の部材選択画面へ遷移させる
        const publishKey = parsePublishKey();
        props.history.push(`/part/home?publish_key=${publishKey}`);
      }

      dispatcherPlan({
        type: ActionNamePlan.setPlan,
        fetchedPlan: responsePlan
      });

      planPartsResult = await new ApiClient().getPlanParts(responsePlan.id);

      dispatchPlanPart({
        type: ActionName.setPlanParts,
        fetchedPlanParts: planPartsResult
      });

      // 見える化用パースデータ取得・加工
      await insertPartFromAllPartVariations(await new ApiClient().getAllPartVariationsForVisualizationFunc(responsePlan.taste.id, responsePlan.publish_key));

      // 既に選択されているものを選択状態にする
      newPart = [];
      planPartsResult.forEach(pp => {
        pp.sub_categories.forEach(sc => {
          if (parts[sc.id] !== undefined) {
            for (let key in parts) {
              newPart[key] = parts[key];
            }
            newPart[sc.id].forEach(np => {
              if (np.sub_category_id === sc.id && np.part_variation_id === sc.selected_part.part_variation_id) {
                np.is_checked = true;
              }
            });
          }
        });
      });

      // 既に選択されているものが１つもない場合は、１番先頭（左）を選択状態にする
      newPart.forEach(p => {
        let is_Checked = false;
        p.forEach(pp => {
          if (pp.is_checked === true) {
            is_Checked = true;
          }
        });
        if (is_Checked === false && p[0] !== undefined) {
          p[0].is_checked = true;
        }
      });

      // 加工したパーツ（パース）データを設定
      setParts(newPart);
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
      handleRequestError(e, props.history);
    }
  }, [dispatchPlanPart, dispatcherPlan]);

  useEffect(() => {
    fetchContents();
  }, [fetchContents]);

  useEffect(() => {
    const userAgent: string = window.navigator.userAgent.toLowerCase();
    const isOniOS: RegExpMatchArray | null = userAgent.match(/ipad/i) || userAgent.match(/iphone/i);
    const eventName: string = isOniOS ? "pagehide" : "beforeunload";
    let browser: string = '';
    if (userAgent.indexOf('msie') !== -1 || userAgent.indexOf('trident') !== -1) {
      browser = 'IE';
    } else if (userAgent.indexOf('edge') !== -1) {
      browser = 'Edge';
    } else if (userAgent.indexOf('chrome') !== -1) {
      browser = 'Chrome';
    } else if (userAgent.indexOf('safari') !== -1) {
      browser = 'Safari';
    } else if (userAgent.indexOf('firefox') !== -1) {
      browser = 'FireFox';
    } else if (userAgent.indexOf('opera') !== -1) {
      browser = 'Opera';
    } else {
      browser = 'Others';
    }

    // リロード・タブ閉じ検知
    const showBeforeunloadAlert = (event: any) => {
      if (isOniOS) {
        event.preventDefault();
      } else {
        event.returnValue = 'このページを再読み込みしますか？\n行った変更が保存されない可能性があります。';
      }
    }
    window.addEventListener(eventName, showBeforeunloadAlert, false);

    // ブラウザバック検知
    window.history.pushState(null, document.title, window.location.href);
    const showPopstateAlert = () => {
      const result: boolean = window.confirm('このページから離れますか？\n行った変更が保存されない可能性があります。');
      if (result) {
        props.history.go(-2);
        window.removeEventListener('popstate', showPopstateAlert, false);
      } else {
        window.history.pushState(null, document.title, window.location.href);
        props.history.go(1);
      }
    }
    window.addEventListener('popstate', showPopstateAlert, false);

    // スワイプバック制御
    const touchStartEvent = (event: any) => {
      if ((event.touches[0].pageX > 16 && event.touches[0].pageX < window.innerWidth - 16) || (event.pageX > 16 && event.pageX < window.innerWidth - 16)) {
        return;
      }
      event.preventDefault();
      if (browser === 'Safari') {
        window.alert('前のページに戻る場合は、\n画面内の「戻る」ボタンを\nタップしてください。');
      }
    }
    document.addEventListener('touchstart', touchStartEvent, false);

    return () => {
      window.removeEventListener(eventName, showBeforeunloadAlert, false);
      window.removeEventListener('popstate', showPopstateAlert, false);
      document.removeEventListener('touchstart', touchStartEvent, false);
    }
  }, []);

  // サムネイル選択後の処理
  const changeCheck = async (partVariationId: number, subCategoryId: number) => {
    const headerLink: HTMLElement = document.getElementById('header-save-user-made-image-btn') as HTMLElement;
    const partsArea: HTMLElement = document.getElementById('visualization-content-parts-area') as HTMLElement;
    const footerBtn: HTMLElement = document.getElementById('footer-save-user-made-image-btn') as HTMLElement;
    if (noSideImageSubCategoryIds.includes(subCategoryId)) {
      if (headerLink !== null && headerLink !== undefined) {
        headerLink.classList.add("now-loading", "no-side-image");
      }
      if (partsArea !== null && partsArea !== undefined) {
        partsArea.classList.add("now-loading", "no-side-image");
      }
      if (footerBtn !== null && footerBtn !== undefined) {
        footerBtn.classList.add("now-loading", "no-side-image");
      }
    } else {
      if (headerLink !== null && headerLink !== undefined) {
        headerLink.classList.add("now-loading");
      }
      if (partsArea !== null && partsArea !== undefined) {
        partsArea.classList.add("now-loading");
      }
      if (footerBtn !== null && footerBtn !== undefined) {
        footerBtn.classList.add("now-loading");
      }
    }
    // 新しく選択したパーツ（パース）と、直前まで選択していたパーツ（パース）のis_checkedを更新
    newPart = [];
    for (let key in parts) {
      newPart[key] = parts[key];
    }
    newPart[subCategoryId] = parts[subCategoryId].map(p => {
      if (p.part_variation_id === partVariationId && p.sub_category_id === subCategoryId) {
        return Object.assign({}, p, {
          is_checked: !p.is_checked
        });
      } else if (p.sub_category_id === subCategoryId) {
        return Object.assign({}, p, {
          is_checked: false
        });
      }
      return p;
    });
    // 状態を更新したパーツ（パース）データを再度設定
    setParts(newPart);
  };

  /* 画面読み込み中の表示 */
  if (loading) {
    return <Loading />;
  }

  return (
    <div className="t-visualization">
      <div className="c-layout">
        <div className="c-layout-child">
          <VisualizationHeader {...props} />
          <BaseImage
            parts={parts}
            plan={resultPlan}
            subCategories={subCategories}
          />
          <VisualizationContent
            parts={parts}
            subCategories={subCategories}
            changeCheck={changeCheck}
          />
        </div>
        <div className="c-layout-child">
          <VisualizationFooter {...props} />
        </div>
      </div>
    </div>
  );
};

const VisualizationFooter = (props: AllProps) => {
  const planSelector = (state: any) => state.planReducer.fetchedPlan;
  const resultPlan: IPlan = useSelector(planSelector);
  const session = getSession();
  if (resultPlan.status === "locked") {
    props.history.push(`/share/detail?publish_key=${resultPlan.publish_key}`);
  } else if (session && !(session.visualization_func_status)) {
    props.history.push(`/part/home?publish_key=${resultPlan.publish_key}`);
  }

  const [showProgressBar, setShowProgressBar] = useState(false);
  const [footersaveImageBtnText, setSaveImageBtnText] = useState("設備とオプションの選択へ");
  const [footersaveImageBtnStatus, setSaveImageBtnStatus] = useState("");

  async function whereToGoBack () {
    const publishKey = resultPlan.publish_key;
    const session = getSession();
    const select_case_ids = (session && session.select_case_ids !== "") ? session.select_case_ids : "0";
    const base_taste_id = (session && session.base_taste_id !== 0) ? session.base_taste_id : (resultPlan.taste.id !== null ? resultPlan.taste.id : 0);
    await new ApiClient().tasteReset(resultPlan.publish_key);
    let url: string = session ? session.from_where : `/taste/recommend?select_case_ids=${select_case_ids}&publish_key=${publishKey}`;
    if (url === "" || url === null || url === undefined) {
      if (base_taste_id !== 0) {
        url =`/taste/detail/${base_taste_id}/?select_case_ids=${select_case_ids}`;
      } else {
        url =`/taste/list/?recommends=0&select_case_ids=${select_case_ids}`;
      }
    }
    props.history.push(url);
  }

  return (
    <div className="c-footer">
      <Divider />
      <div className="c-footer-buttons">
        <div
          className="c-footer-buttons-sub c-button c-button-back"
          onClick={() => whereToGoBack()}
        >
          <span className="c-button-strings">戻る</span>
        </div>
        <div
          id="footer-save-user-made-image-btn"
          onClick={async () =>{
            // 保存ボタン非活性＋表示テキスト変更
            await setSaveImageBtnStatus("disabled");
            await setSaveImageBtnText("イメージを保存中...");
            // ヘッダーの保存ボタンも同時に変更
            await changeSaveBtnState("header");
            // 保存処理実行
            await setShowProgressBar(true);
            await saveUserMadeImage(props);
            await setShowProgressBar(false);
          }}
          data-state={footersaveImageBtnStatus}
          className="c-footer-buttons-main c-button c-footer-buttons-visualization now-loading"
        >
          <div
            id="footer-save-user-made-image-btn-text"
            className="c-button-strings c-footer-buttons-visualization-strings"
          >
            {footersaveImageBtnText}
          </div>
        </div>
      </div>
      {showProgressBar ?
        <div
          className="c-modal--alert"
          data-modal="active"
          data-modal-color="light_trans_nav"
        >
          <div className="c-modal-inwrapper">
            <div
              className="c-modal-inwrapper-content"
              style={{ overflowY: "hidden" }}
            >
              <div className="c-layout">
                <div className="c-layout-child">
                  <div className="u-fs--base">
                    <label htmlFor="saving-progress">イメージを保存中...</label>
                    <progress id="saving-progress" max="100" value="0" className="saving-state">0%</progress>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        :
        <></>
      }
    </div>
  );
};
export default withRouter(Visualization);