import ICase from "../../models/case";
import { host, IRequest } from "./core";

export const buildCasesRequest = (): IRequest<ICase[]> => {
  return {
    url: `${host}/api/cases`,
    options: {
      method: "get",
      resolveWithFullResponse: true,
      json: true
    }
  };
};
