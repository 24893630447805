import React from "react";
import { parsePriceFormat, eventVersionPriceDisplay, showThePrice } from "../../../util";
import { getSession } from "../../../session";
interface IPartListProps {
  price: number;
  isDefault?: boolean;
}
type AllProps = IPartListProps;

export const priceDiffState = (price: number) => {
  if (price < 0) {
    return "down";
  } else if (price > 0) {
    return "up";
  }
  return "flat";
};

const PriceDiff = (props: AllProps) => {
  const { price, isDefault } = props;
  if (isDefault) {
    return <div className="c-card-body-price" />;
  }

  if((showThePrice(getSession()) && !eventVersionPriceDisplay)){
    return (
      <>
        <div
          className="c-card-body-price"
          data-state={priceDiffState(price)}
          style={{ paddingTop: "4px" }}
        >
          <div className="c-card-body-price-guide">標準パーツから</div>
          <div className="c-card-body-price-icon_up">Up</div>
          <div className="c-card-body-price-icon_down">Down</div>
          <div className="c-card-body-price-icon_flat">変更なし</div>
          {parsePriceFormat(price)}
        </div>
      </>
    );
  } else {
    return (
      <React.Fragment></React.Fragment>
    );
  }
};

export default PriceDiff;

export const PriceDiff2Row = (props: AllProps) => {
  const { price, isDefault } = props;
  if (isDefault) {
    return <div className="c-card-body-price" />;
  }

  if((showThePrice(getSession()) && !eventVersionPriceDisplay)){
    return (
      <>
        <div
          className="all-card-body-price"
          data-state={priceDiffState(price)}
          style={{ paddingTop: "4px" }}
        >
          <div className="c-card-body-price-guide">標準パーツから</div>
          <div className="price-diff">
            <div className="c-card-body-price-icon_up">Up</div>
            <div className="c-card-body-price-icon_down">Down</div>
            <div className="c-card-body-price-icon_flat">変更なし</div>
            {parsePriceFormat(price)}
          </div>
        </div>
      </>
    );
  } else {
    return (
      <React.Fragment></React.Fragment>
    );
  }
};

