import React, { useEffect, useState } from "react";
import { RouteComponentProps } from "react-router";
import IInformation from "../../../models/information";
import { InformationModalUI } from "../../information";
interface IReceive {
  information?: IInformation;
}

const initialInfo: IReceive = {};

const PreviewInformationDetail = (props: RouteComponentProps) => {
  const [info, setInfo] = useState(initialInfo);
  const { information } = info;

  useEffect(() => {
    window.addEventListener('message', event => {
      if (event.data.information) {
        setInfo(event.data || {});
      }
    });
  }, []);

  return (
    <div style={{ height: "100%" }} className="preview">
      <InformationModalUI information={information} />
    </div>
  );
};

export default PreviewInformationDetail;
