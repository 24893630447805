import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";

import TransitionModal from "./modal";
import TransitionPush from "./push";

type AllProps = React.Props<{}> & RouteComponentProps;

export enum Transition {
  push = "PUSH",
  modal = "MODAL"
}

const TransionWrapper = (props: AllProps) => {
  const { children, history, location } = props;
  const transition: Transition | null = location.state
    ? location.state.transition
    : null;

  if (!transition) {
    return <>{children}</>;
  }

  switch (transition) {
    case Transition.push: {
      if (history.action === "PUSH") {
        return <TransitionPush>{children}</TransitionPush>;
      }
    }
    case Transition.modal: {
      if (history.action === "PUSH") {
        return <TransitionModal>{children}</TransitionModal>;
      }
    }
  }

  return <>{children}</>;
};

export default withRouter(TransionWrapper);
