import * as React from "react";

interface IOuterProps {
  title: string;
  body: string;
  okText: string;
  onClickOk?: (e: any) => void;
}

type AllProps = IOuterProps;

const Alert = (props: AllProps) => {
  const { title, body, okText, onClickOk } = props;

  return (
    <div
      className="c-modal--alert information"
      data-modal="active"
      data-modal-color="light_trans_nav"
    >
      <div className="c-modal-inwrapper">
        <div className="c-modal-inwrapper-content">
          <div className="c-layout">
            <div
              className="c-layout-child"
              style={{ padding: "26px 32px 26px 32px" }}
            >
              {title && (
                <div
                  className="c-pageheader-title"
                  style={{ margin: "10px 0 18px" }}
                >
                  {title}
                </div>
              )}
              <div
                className="c-pageheader-descriptions"
                style={{
                  textAlign: "left",
                  maxHeight: "50vh",
                  overflowY: "auto",
                  paddingRight: "10px",
                  paddingBottom: title ? "16px" : "0px"
                }}
                dangerouslySetInnerHTML={{
                  __html: body.replace(/\n/g, "<br />")
                }}
              />
            </div>
            <div className="c-layout-child">
              <div className="c-nav_bar-right" onClick={onClickOk}>
                <div
                  className="c-button c-modal_close"
                  style={{ width: "100%" }}
                >
                  <div className="c-button-strings">
                    <div className="u-tc--success">{okText}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Alert;
