import { Action, Reducer } from "redux";
import IPartVariation from "../models/part_variation";

export interface IAllPartListState {
  fetchedAllPartsList: IPartVariation[][];
}

const initialState: IAllPartListState = {
  fetchedAllPartsList: []
};

export enum ActionName {
  setAllPartsList = "allPartList/setAllPartsList"
}

interface ISetAllPartsList extends Action<ActionName.setAllPartsList> {
  fetchedAllPartsList: IPartVariation[][];
}

export type IAllPartListAction = ISetAllPartsList;



export const allPartListReducer: Reducer<
  IAllPartListState | undefined,
  IAllPartListAction
> = (state = initialState, action) => {
  switch (action.type) {
    case ActionName.setAllPartsList: {
      return { ...state, fetchedAllPartsList: action.fetchedAllPartsList };
    }
  }
  return state;
};
