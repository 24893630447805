import * as React from "react";
import KeywordItem from "./keyword_item";
import {ITag} from "./index";

interface Iprops{
  tag: ITag[];
  tasteNum: number;
  isOpen: boolean;
  changeCheck: (name: string) => void;
  changeToggleMenu: (open:boolean) => void;
  getKeywordList: () => void;
}

const ToggleMenu = (props: Iprops) => {
  const { tag, tasteNum, isOpen, changeCheck, changeToggleMenu, getKeywordList } = props;
  const changeTasteList = () => {
    changeToggleMenu(isOpen);
    getKeywordList();
  }

  return (
    <div style={{lineHeight:"30px"}}>
      <div style={{padding:"0px 5px 5px 5px", color:"dimgrey"}}>スタイル</div>
      {
        tag.map((t, index) => {
          if (t.type === "style") {
            return(
              <KeywordItem
                key={t.name + index}
                name={t.name}
                times={t.times}
                type={t.type}
                ischecked={t.ischecked}
                changeCheck={changeCheck}
              />
            )
          }
        })
      }
      <div style={{padding:"25px 5px 5px 5px", color:"dimgrey"}}>色合い</div>
      {
        tag.map((t, index) => {
          if (t.type === "color") {
            return(
              <KeywordItem
                key={t.name + index}
                name={t.name}
                times={t.times}
                type={t.type}
                ischecked={t.ischecked}
                changeCheck={changeCheck}
              />
            )
          }
        })
      }
      <div style={{textAlign:"center", fontSize:"12px", paddingTop:"20px"}}>絞り込まれた結果：{(tag.filter(t => t.ischecked).length >= 1) ? `${tasteNum}　件` : "未選択"}</div>
      <div style={{padding:"0px 20px 20px 20px", textAlign:"center"}} >
        <span
          onClick={() => {changeTasteList()}}
          style={{backgroundColor:"darkgrey",
            cursor:"pointer",
            color:"white",
            display:"inline-block",
            height:"40px",
            width:"200px",
            paddingTop:"5px"}}>検索する</span>
      </div>
    </div>
  );
};
export default ToggleMenu;