import * as React from "react";
import { useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import { IHouseholdItem } from "../../../../models/household_item";
import { IPlan } from "../../../../models/plan";
import { scrollFromQueryParam } from "../../../../util";
import HouseholdItemCell from "./household_cell";

interface IPlanProps {
  householdItems: IHouseholdItem[];
  plan: IPlan;
}
type AllProps = RouteComponentProps & IPlanProps;
const HouseholdItemRecommendList = (props: AllProps) => {
  const [initialized, setInitialized] = useState(false);
  // プラン共有前の場合は未処理
  if (!props.plan.is_share) {
    return <></>;
  }
  // 初回表示時のみスクロール
  if (!initialized) {
    scrollFromQueryParam("si", 1000, 1000);
    setInitialized(true);
  }

  return (
    <React.Fragment>
      <div className="c-pageheader">
        <hr
          className="c-separator"
          style={{
            marginTop: "10px",
            marginBottom: "20px"
          }}
        />
        <div
          id="rhi"
          className="c-group--taste_details-keywords-title"
          style={{ paddingBottom: "0px" }}
        >
          このテイストにオススメの家具
        </div>
        <div className="c-pageheader-descriptions">
          ※リノベるが提供する家具販売サイトへ移動します
        </div>
        <div className="c-group--part_category-list">
          {props.householdItems.length < 1 ? (
            <div
              className="c-pageheader-descriptions"
              style={{ textAlign: "center", padding: "10px" }}
            >
              オススメの家具は現在登録されていません
            </div>
          ) : (
            <div className="c-list--equal" data-list_column="2">
              {props.householdItems.map(item => {
                return (
                  <HouseholdItemCell
                    {...props}
                    key={item.id}
                    householdItem={item}
                  />
                );
              })}
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default HouseholdItemRecommendList;
