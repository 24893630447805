import * as React from "react";

interface IParseImageProps {
  id: number;
  subCategoryId: number;
  panel_url: string;
  checked: boolean;
  changeCheck: (id: number, subCategoryId: number) => void;
}
type AllProps = IParseImageProps;

const ColorThumbnail = (props: AllProps) => {
  const { id, subCategoryId, panel_url, checked, changeCheck } = props;

  return(
    <label
      className="thumbnail"
      onClick={(!checked) ? () => {changeCheck(id, subCategoryId)} : () => {}}
      style={{border: `${checked ? "4px black" : "1px lightgray"} solid`, height: "auto", width: "70px", margin: "4px", padding: "2px", cursor: "pointer", display: "inline-block"}}
    >
      <figure className="thumb-fig">
        <img className="thumbnail-parts" src={panel_url} alt={`thumbnail-${subCategoryId}-${id}`} style={{height: "100%", width: "100%"}}/>
      </figure>
    </label>
  );
};
export default ColorThumbnail;