import * as React from "react";

interface IOuterProps {
  margin?: boolean;
}
type AllProps = IOuterProps;

const marginSize = "1.25rem";

const Divider = (props: AllProps) => {
  const { margin } = props;

  return (
    <hr
      className="c-separator"
      style={{
        marginTop: margin ? marginSize : "0",
        marginBottom: margin ? marginSize : "0"
      }}
    />
  );
};

export default Divider;
