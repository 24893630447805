import IInformation from "../../models/information";
import { host, IRequest } from "./core";

export const buildInformationRequest = (
  loginKey: string
): IRequest<IInformation> => {
  return {
    url: `${host}/api/users/${loginKey}/information`,
    options: {
      method: "get",
      resolveWithFullResponse: true,
      json: true
    }
  };
};
