import * as React from "react";
import { RouteComponentProps } from "react-router-dom";
import Logo from "../../component/logo";
import renoveruLogo from "../../images/renoveru.svg";
import { IMaintenace } from "../../models/maintenance";

interface IProps {
  maintenance: IMaintenace;
}

const Maintenance = (props: RouteComponentProps & IProps) => {
  return (
    <div className="t-insert">
      <div className="c-layout">
        <div className="c-layout-child">
          <div className="c-group--read">
            <div className="c-group--read-image">
              <Logo />
            </div>
            <div
              className="c-group--read-small_read"
              dangerouslySetInnerHTML={{
                __html: props.maintenance.message.replace(/\n/g, "<br />")
              }}
            />
          </div>
        </div>
        <div className="c-layout-child">
          <div className="c-group--copy">
            <img src={renoveruLogo} alt="renoveru" />
            <div>Copyright © Renoveru,Inc.</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Maintenance;
