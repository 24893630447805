import * as React from "react";
import { useCallback, useEffect, useReducer, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Dispatch } from "redux";
import sendPageView from "../../../analytics";
import { ApiClient } from "../../../api";
import Divider from "../../../component/divider";
import Loading from "../../../component/loading";
import ICase from "../../../models/case";
import { ActionName, ICaseAction } from "../../../redux/case";
import CaseDetail, { ICaseImageModal } from "../detail";
import CaseImageList from "./case_image_list";
import { addPlanQueryParam } from "../../../util";

const confirmSearchPath = (ids: number[]): string => {
  const query = ids.join(",");
  return `?ids=${query}${addPlanQueryParam()}`;
};

const CaseListNav = (props: { ids: number[] }) => {
  const { ids } = props;
  return (
    <React.Fragment>
      <div className="c-nav_bar--light navigation-header">
        <div className="c-nav_bar-left" />
        <div className="c-nav_bar-center" />
        <div className="c-nav_bar-right">
          <Link
            to={{
              pathname: "/case/confirm",
              search: confirmSearchPath(ids)
            }}
            className="c-button"
            data-state={ids.length === 0 && "disabled"}
          >
            <span className="c-button-strings">選択したイメージを確認</span>
          </Link>
        </div>
      </div>
    </React.Fragment>
  );
};

const CaseListHeader = () => {
  return (
    <React.Fragment>
      <div className="c-group--pageheader">
        <div className="c-pageheader">
          <div className="c-pageheader-title">あなたの好みは？</div>
          <div className="c-pageheader-descriptions">
            以下のイメージから、お好みに近いイメージを1枚以上選択してください。
          </div>
          <Divider />
        </div>
      </div>
    </React.Fragment>
  );
};

export const CaseImageModal = (props: ICaseImageModal) => {
  return (
    <React.Fragment>
      <CaseDetail
        onClose={props.onClose}
        currentCaseId={props.currentCaseId}
        imageUrl={props.imageUrl}
        isConfirm={props.isConfirm}
        onSelect={props.onSelect}
        loaded={props.loaded}
      />
    </React.Fragment>
  );
};

const CaseListContents = (props: { forceUpdate: any }) => {
  const { forceUpdate } = props;
  const [loading, setLoading] = useState(false);

  const casesSelector = (state: any) => state.caseReducer.fetchCases;
  const cases: ICase[] = useSelector(casesSelector);
  const dispatch = useDispatch<Dispatch<ICaseAction>>();

  // 事例の選択状態
  const idsSelector = (state: any) => state.caseReducer.selectedCaseIds;
  const ids: number[] = useSelector(idsSelector);

  const fetchCases = useCallback(async () => {
    setLoading(true);
    try {
      const result = await new ApiClient().getCases();
      setLoading(false);
      dispatch({
        type: ActionName.setCases,
        fetchCases: result
      });
    } catch (e) {
      setLoading(false);
    }
  }, [dispatch]);

  useEffect(() => {
    fetchCases();
  }, [fetchCases]);

  if (loading) {
    return <Loading />;
  }

  return (
    <CaseImageList
      ids={ids}
      isReadable={false}
      forceUpdate={forceUpdate}
      cases={cases}
      dispatch={dispatch}
      onSelect={(e, c) => {
        // 事例詳細-トラッキング停止
        e.stopPropagation();
        if (ids.includes(c.id)) {
          const index = ids.indexOf(c.id, 0);
          if (index > -1) {
            ids.splice(index, 1);
          }
        } else {
          ids.push(c.id);
        }
        dispatch({
          type: ActionName.addSelectedCaseId,
          selectedCaseIds: ids
        });
        e.preventDefault();
        forceUpdate(e);
      }}
      onModalSelect={sendPageViewCaseInsert}
      onModalClose={sendPageViewCaseInsert}
      isModalConfirm={false}
    />
  );
};

const sendPageViewCaseInsert = () => {
  sendPageView("事例一覧画面", "/case/list");
};

const CaseListFooter = (props: { ids: number[] }) => {
  const { ids } = props;
  return (
    <React.Fragment>
      <div className="c-footer">
        <Divider />
        <div className="c-footer-buttons">
          <Link
            to={{
              pathname: "/case/confirm",
              search: confirmSearchPath(ids)
            }}
            className="c-footer-buttons-main c-button"
            data-state={ids.length === 0 && "disabled"}
          >
            <span className="c-button-strings">選択したイメージを確認</span>
          </Link>
        </div>
      </div>
    </React.Fragment>
  );
};

const CaseListContainer = (props: { forceUpdate: any }) => {
  const idsSelector = (state: any) => state.caseReducer.selectedCaseIds;
  const ids: number[] = useSelector(idsSelector);

  return (
    <React.Fragment>
      <CaseListNav ids={ids} />
      <div className="c-layout">
        <div className="c-layout-child">
          <CaseListHeader />
          <CaseListContents forceUpdate={props.forceUpdate} />
        </div>
        <div className="c-layout-child">
          <CaseListFooter ids={ids} />
        </div>
      </div>
    </React.Fragment>
  );
};

const CaseList = () => {
  const [, forceUpdate] = useReducer(x => x + 1, 0);

  return (
    <div className="t-case">
      <CaseListContainer forceUpdate={forceUpdate} />
    </div>
  );
};

export default CaseList;
