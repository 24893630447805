import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import TransitionWrapper from "./component/transition";
import "./sass/style.scss";

import Auth from "./component/auth";
import TrackRoute from "./component/track";
import CaseConfirm from "./ui/case/confirm";
import CaseDetail from "./ui/case/detail";
import CaseInsert from "./ui/case/insert";
import CaseList from "./ui/case/list";
import Intoroduction from "./ui/introduction";
import NotFound from "./ui/not_found";
import PartDetail from "./ui/part/detail";
import PartHome from "./ui/part/home";
import PartInsert from "./ui/part/insert";
import PartList from "./ui/part/list";
import AllPartList from "./ui/part/all_list";
import PlanMenu from "./ui/plan/menu";
import PreviewCaseDetail from "./ui/preview/case/detail";
import PreviewInformationDetail from "./ui/preview/information/detail";
import PreviewPartDetail from "./ui/preview/part/detail";
import PreviewTasteDetail from "./ui/preview/taste/detail";
import Rule from "./ui/rule";
import ShareDetail from "./ui/share/detail";
import ShareInsert from "./ui/share/insert";
import ShareUrl from "./ui/share/url";
import TasteDetail from "./ui/taste/detail";
import TasteImages from "./ui/taste/detail/taste_images";
import TasteList from "./ui/taste/list";
import TasteRecommend from "./ui/taste/recommend";
import Top from "./ui/top";
import Visualization from "./ui/visualization";

const Router = () => {
  return (
    <BrowserRouter>
      <Switch>
        <TrackRoute
          title={"ランディング画面"}
          url={"/"}
          exact={true}
          path="/"
          component={Top}
        />
        {/* NOTE: プレビュー画面はTracking不要 */}
        <Route
          exact={true}
          path="/preview/case/detail"
          component={PreviewCaseDetail}
        />
        <Route
          exact={true}
          path="/preview/taste/detail"
          component={PreviewTasteDetail}
        />
        <Route
          exact={true}
          path="/preview/part/detail"
          component={PreviewPartDetail}
        />
        <Route
          exact={true}
          path="/preview/information/detail"
          component={PreviewInformationDetail}
        />

        <Auth>
          <Switch>
            <TrackRoute
              title={"プランメニュー画面"}
              exact={true}
              globalMenuOpt={{
                show: true,
                hideTitle: true,
                showCloseButton: true
              }}
              path="/plan/menu/"
              component={PlanMenu}
            />
            <TrackRoute
              title={"サービス紹介画面"}
              exact={true}
              globalMenuOpt={{
                show: true,
                hideTitle: true,
                showCloseButton: true
              }}
              path="/introduction/"
              component={() => (
                <TransitionWrapper>
                  <Intoroduction />
                </TransitionWrapper>
              )}
            />
            <TrackRoute
              title={"注意事項画面"}
              exact={true}
              globalMenuOpt={{
                show: true,
                hideTitle: true,
                showCloseButton: true
              }}
              path="/rule/"
              component={() => (
                <TransitionWrapper>
                  <Rule />
                </TransitionWrapper>
              )}
            />
            <TrackRoute
              title={"事例インサート画面"}
              exact={true}
              path="/case/insert/"
              component={() => (
                <TransitionWrapper>
                  <CaseInsert />
                </TransitionWrapper>
              )}
            />
            <TrackRoute
              title={"事例一覧画面"}
              exact={true}
              globalMenuOpt={{ show: true }}
              path="/case/list/"
              component={() => (
                <TransitionWrapper>
                  <CaseList />
                </TransitionWrapper>
              )}
            />
            <TrackRoute
              title={"事例確認画面"}
              exact={true}
              globalMenuOpt={{ show: true }}
              path="/case/confirm/"
              component={() => (
                <TransitionWrapper>
                  <CaseConfirm />
                </TransitionWrapper>
              )}
            />
            <TrackRoute
              title={"事例詳細画面"}
              exact={true}
              globalMenuOpt={{ show: true, hideTitle: true }}
              path="/case/detail/:case_id"
              component={CaseDetail}
            />
            <TrackRoute
              title={"テイスト提案画面"}
              exact={true}
              globalMenuOpt={{ show: true }}
              path="/taste/recommend/"
              component={TasteRecommend}
            />
            <TrackRoute
              title={"テイスト画像画面"}
              exact={true}
              globalMenuOpt={{ show: true, hideTitle: true }}
              path="/taste/images/"
              component={TasteImages}
            />
            <TrackRoute
              title={"テイスト一覧画面"}
              exact={true}
              globalMenuOpt={{ show: true }}
              path="/taste/list/"
              component={TasteList}
            />
            <TrackRoute
              title={"テイスト詳細画面"}
              exact={true}
              globalMenuOpt={{ show: true }}
              path="/taste/detail/:taste_id"
              component={TasteDetail}
            />
            <TrackRoute
              title={"カスタマイズ画面"}
              exact={true}
              globalMenuOpt={{ show: true }}
              path="/visualization/"
              component={Visualization}
            />
            <TrackRoute
              title={"部材インサート画面"}
              exact={true}
              path="/part/insert/"
              component={() => (
                <TransitionWrapper>
                  <PartInsert />
                </TransitionWrapper>
              )}
            />
            <TrackRoute
              title={"部材ホーム画面"}
              exact={true}
              globalMenuOpt={{ show: true }}
              path="/part/home/"
              component={PartHome}
            />
            <TrackRoute
              title={"部材選択画面"}
              param={"sub_category_id"}
              exact={true}
              globalMenuOpt={{ show: true }}
              path="/part/list/"
              component={PartList}
            />
            <TrackRoute
              title={"パーツリスト"}
              param={"sub_category_id"}
              exact={true}
              globalMenuOpt={{ show: true }}
              path="/part/all_list/"
              component={AllPartList}
            />
            <TrackRoute
              title={"部材詳細画面"}
              exact={true}
              globalMenuOpt={{ show: true }}
              path="/part/detail/:part_id"
              component={PartDetail}
            />
            <TrackRoute
              title={"共有インサート画面"}
              exact={true}
              path="/share/insert/"
              component={() => (
                <TransitionWrapper>
                  <ShareInsert />
                </TransitionWrapper>
              )}
            />
            <TrackRoute
              title={"共有内容確認画面"}
              exact={true}
              globalMenuOpt={{ show: true }}
              path="/share/detail/"
              component={ShareDetail}
            />
            <TrackRoute
              title={"共有URL画面"}
              url={"/share/url"}
              exact={true}
              globalMenuOpt={{ show: true }}
              path="/share/url/:publish_key"
              component={ShareUrl}
            />
          </Switch>
        </Auth>
        {/* NOTE: NotFoundは常に一番下に書いておく必要あり */}
        <Route path="/" component={NotFound} />
      </Switch>
    </BrowserRouter>
  );
};

export default Router;
