import { Action, Reducer } from "redux";
import IPartVariation from "../models/part_variation";

export interface ITastePartState {
  fetchedTasteParts: IPartVariation[];
}

const initialState: ITastePartState = {
  fetchedTasteParts: []
};

export enum ActionName {
  setTasteParts = "tastePart/setTasteParts"
}

interface ISetTasteParts extends Action<ActionName.setTasteParts> {
  fetchedTasteParts: IPartVariation[];
}

export type ITastePartAction = ISetTasteParts;

export const tastePartReducer: Reducer<
  ITastePartState | undefined,
  ITastePartAction
> = (state = initialState, action) => {
  switch (action.type) {
    case ActionName.setTasteParts: {
      return { ...state, fetchedTasteParts: action.fetchedTasteParts };
    }
  }
  return state;
};
