import { Action, Reducer } from "redux";
import ICase from "../models/case";

export interface ICaseState {
  fetchCases: ICase[];
  selectedCaseIds: number[];
}

const initialState: ICaseState = {
  fetchCases: [],
  selectedCaseIds: []
};

export enum ActionName {
  setCases = "case/setCases",
  addSelectedCaseId = "case/addSelectedCaseId"
}

interface IAddSelectedCaseId extends Action<ActionName.addSelectedCaseId> {
  selectedCaseIds: number[];
}

interface ISetCases extends Action<ActionName.setCases> {
  fetchCases: ICase[];
}

export type ICaseAction = IAddSelectedCaseId | ISetCases;

export const caseReducer: Reducer<ICaseState | undefined, ICaseAction> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case ActionName.addSelectedCaseId: {
      return { ...state, selectedCaseIds: action.selectedCaseIds };
    }
    case ActionName.setCases: {
      return { ...state, fetchCases: action.fetchCases };
    }
  }
  return state;
};
