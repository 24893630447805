import IPartVariation from "../../models/part_variation";
import { host, IRequest } from "./core";

export const buildPartVariationRequest = (
  tasteId: number,
  subCategoryId: number,
  publishKey: string
): IRequest<IPartVariation[]> => {
  return {
    url: `${host}/api/part_variations?taste_id=${tasteId}&sub_category_id=${subCategoryId}&publish_key=${publishKey}`,
    options: {
      method: "get",
      resolveWithFullResponse: true,
      json: true
    }
  };
};
