import * as React from "react";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Dispatch } from "redux";
import { ApiClient } from "../../api";
import Done from "../../component/alert/done";
import Confirm from "../../component/confirm";
import deleteActiveImg from "../../images/ic-delete-active.svg";
import deleteImg from "../../images/ic-delete.svg";
import { IPlan } from "../../models/plan";
import { ActionName as ActionNameCase, ICaseAction } from "../../redux/case";
import { ActionName, IPlanAction } from "../../redux/plan";
import { getSession } from "../../session";

interface IPlanCreateProps {
  onCreate: (plan: IPlan, router: RouteComponentProps) => void;
  onCancel: () => void;
  onDelete: (actionType: string) => void;
}

interface IPlanProps {
  selectPlan: (id: number, name: string) => void;
  selectedPlanIds: number[];
  selectedPlanNames: string[];
  setSelectedPlanIds: ([]) => void;
  setSelectedPlanNames: ([]) => void;
  dispatch: Dispatch<IPlanAction>;
}

const limitPlan = async (): Promise<boolean> => {
  const responseLimitPlan = await new ApiClient().limitPlan();
  return responseLimitPlan.is_over;
};

const createPlan = async (dispatch: Dispatch<IPlanAction>): Promise<IPlan> => {
  const plan = await new ApiClient().createPlan();
  dispatch({
    type: ActionName.setPlan,
    fetchedPlan: plan
  });
  return plan;
};

const List = (props: { plans: IPlan[] } & IPlanProps) => {
  // active plan
  const session = getSession();
  const activePlanId = session ? session.plan_id : 0;
  return (
    <React.Fragment>
      <div className="plan-menu">
        {
          <div className="plan-menu">
            {props.plans.map((plan, index) => {
              const isActive = plan.id === activePlanId;
              return (
                <div key={`delete-plan-${plan.id}`} className="contents">
                  <div className={`${isActive ? "actived-boder" : ""}`}>
                    <div className="container">
                      <section className="left">
                        <div className="check">
                          <input
                            disabled={isActive}
                            id={`delete-plan-${plan.id}`}
                            type="checkbox"
                            defaultChecked={false}
                            onClick={() => props.selectPlan(plan.id, plan.name)}
                          />
                          <label htmlFor={`delete-plan-${plan.id}`} />
                        </div>
                      </section>
                      <section className="main">
                        <label htmlFor={`delete-plan-${plan.id}`}>
                          <div className="name">{plan.name}</div>
                          <div className="base-taste-preview">
                            <div className="base-taste-preview-thumb">
                              {plan.thumbnail_url ? (
                                <div className="thumb">
                                  <img src={encodeURI(plan.thumbnail_url)} />
                                </div>
                              ) : (
                                <></>
                              )}
                              {// ロック済みのプラン
                              plan.status === "locked" && (
                                <div className="plan-status locked">
                                  ロック中
                                </div>
                              )}
                              {// 選択中のプラン
                              isActive && (
                                <div className="plan-status actived">
                                  選択中
                                </div>
                              )}
                            </div>
                            <div className="memo">{plan.memo}</div>
                          </div>
                          <div className="date">
                            <div className="created_at">
                              <span>作成日時&nbsp;</span>
                              {plan.created_at}
                            </div>
                            <div className="updated_at">
                              <span>最終更新日時&nbsp;</span>
                              {plan.updated_at}
                            </div>
                          </div>
                        </label>
                      </section>
                    </div>
                  </div>
                  <hr className="c-separator" />
                </div>
              );
            })}
          </div>
        }
      </div>
    </React.Fragment>
  );
};

const Footer = (
  props: { onCancel: () => void; onDelete: () => void } & IPlanProps
) => {
  const [showLastConfirm, setShowLastConfirm] = useState(false);
  const [showDeletedConfirm, setShowDeletedConfirm] = useState(false);
  const destroyPlans = useCallback(
    async (selectedPlanIds: number[]) => {
      // プラン削除
      await new ApiClient().destroyPlans(selectedPlanIds);
      // 再取得
      const responsePlans = await new ApiClient().getPlans();
      props.dispatch({
        type: ActionName.setPlans,
        fetchedPlans: responsePlans
      });
    },
    [props.dispatch]
  );

  return (
    <>
      <div className="c-footer">
        <div className="c-footer-buttons">
          {props.selectedPlanIds.length === 0 ? (
            <div className="c-button delete">
              <div className="c-button-icons">
                <img src={deleteImg} alt="delete" />
              </div>
            </div>
          ) : (
            <div
              className="c-button delete active"
              onClick={e => {
                setShowLastConfirm(true);
              }}
            >
              <div className="c-button-icons">
                <img src={deleteActiveImg} alt="delete" />
              </div>
            </div>
          )}
          <div className="c-button cancel" onClick={props.onCancel}>
            <div className="c-button-strings">キャンセル</div>
          </div>
        </div>
      </div>
      {showLastConfirm && (
        <Confirm
          title=""
          text={props.selectedPlanNames + "を削除してもよろしいですか？"}
          okText="OK"
          cancelText="キャンセル"
          onClickOk={(e: any) => {
            destroyPlans(props.selectedPlanIds);
            setShowLastConfirm(false);
            setShowDeletedConfirm(true);
          }}
          onClickCancel={() => setShowLastConfirm(false)}
        />
      )}
      {showDeletedConfirm && (
        <Done
          title=""
          text={props.selectedPlanNames + "を削除しました。"}
          okText="OK"
          onClickOk={(e: any) => {
            props.setSelectedPlanIds([]);
            props.setSelectedPlanNames([]);
            setShowDeletedConfirm(false);
            props.onDelete();
          }}
        />
      )}
    </>
  );
};

const dismiss = (dispatch: Dispatch<IPlanAction>) => {
  // 閉じる
  dispatch({
    type: ActionName.createPlan,
    createPlan: false
  });
  dispatch({
    type: ActionName.showDeletePlan,
    showDeletePlan: false
  });
};

const reload = async (dispatch: Dispatch<IPlanAction>) => {
  // 再取得
  const responsePlans = await new ApiClient().getPlans();
  dispatch({
    type: ActionName.setPlans,
    fetchedPlans: responsePlans
  });
};

const PlanDeleteDialog = (props: IPlanCreateProps & RouteComponentProps) => {
  const [open, setOpen] = useState(false);
  const [create, setCreate] = useState(false);

  const dispatch = useDispatch<Dispatch<IPlanAction>>();
  // プラン作成
  const plansSelector = (state: any) => state.planReducer.createPlan;
  const doActionCreatePlan: boolean = useSelector(plansSelector);
  // 削除プランダイアログ表示
  const deletePlanSelector = (state: any) => state.planReducer.showDeletePlan;
  const doShowDeletePlan: boolean = useSelector(deletePlanSelector);
  const [actionType, setActionType] = useState("");

  const plansFetchedSelector = (state: any) => state.planReducer.fetchedPlans;
  const plans: IPlan[] = useSelector(plansFetchedSelector);

  const dispatchCase = useDispatch<Dispatch<ICaseAction>>();

  // プランの選択状態
  const [selectedPlanIds, setSelectedPlanIds] = useState(Array());
  const [selectedPlanNames, setSelectedPlanNames] = useState(Array());

  const selectPlan = (id: number, name: string) => {
    const ids = selectedPlanIds.slice();
    const names = selectedPlanNames.slice();
    if (ids.includes(id)) {
      const index = ids.indexOf(id, 0);
      if (index > -1) {
        ids.splice(index, 1);
        names.splice(index, 1);
      }
    } else {
      ids.push(id);
      names.push(name);
    }
    setSelectedPlanIds(ids);
    setSelectedPlanNames(names);
  };

  const validate = useCallback(async () => {
    // プラン作成
    if (doActionCreatePlan) {
      setActionType("create");
      // プラン上限数確認
      const isLimitOver = await limitPlan();

      // 上限超えている場合、削除ダイアログを表示
      if (isLimitOver) {
        // リロード
        await reload(dispatch);
        setOpen(true);
      }
      if (!isLimitOver) {
        setCreate(true);
      }
    } else if (doShowDeletePlan) {
      setActionType("duplicate");
      // リロード
      await reload(dispatch);
      setOpen(true);
    }
    dismiss(dispatch);
  }, [doActionCreatePlan, doShowDeletePlan]);

  const onCreate = useCallback(async () => {
    if (create) {
      // 事例の選択状態をリセット
      dispatchCase({
        type: ActionNameCase.addSelectedCaseId,
        selectedCaseIds: []
      });

      // プラン作成処理
      const plan = await createPlan(dispatch);

      // リロード
      await reload(dispatch);

      // 完了
      props.onCreate(plan, props);
      onClose();
    }
  }, [create]);

  const onClose = () => {
    setSelectedPlanIds([]);
    setSelectedPlanNames([]);
    setOpen(false);
    setCreate(false);
    dismiss(dispatch);
  };
  useEffect(() => {
    validate();
  }, [doActionCreatePlan, doShowDeletePlan]);
  useEffect(() => {
    onCreate();
  }, [onCreate]);

  // プラン作成
  return (
    <React.Fragment>
      {open && (
        <div className="t-plan">
          <div
            id="plan-delete-dialog"
            className="c-modal--alert plan-delete-dialog"
            data-modal="active"
            data-modal-color="light_trans_nav"
          >
            <div className="c-modal-inwrapper">
              <div className="c-modal-inwrapper-content">
                <div className="c-layout">
                  <div className="c-layout-child" style={{ paddingBottom: 0 }}>
                    <div className="c-page-title">
                      プランの最大数に達しました。
                      <br />
                      新しいプランを作成するには、作成済みのプランを1つ削除してください。
                    </div>
                    <hr className="c-separator" style={{ marginTop: "20px" }} />
                    <List
                      plans={plans}
                      selectPlan={selectPlan}
                      dispatch={dispatch}
                      selectedPlanIds={selectedPlanIds}
                      selectedPlanNames={selectedPlanNames}
                      setSelectedPlanIds={setSelectedPlanIds}
                      setSelectedPlanNames={setSelectedPlanNames}
                    />
                    <Footer
                      onDelete={() => {
                        onClose();
                        props.onDelete(actionType);
                      }}
                      onCancel={() => {
                        onClose();
                        props.onCancel();
                      }}
                      dispatch={dispatch}
                      selectPlan={selectPlan}
                      selectedPlanIds={selectedPlanIds}
                      selectedPlanNames={selectedPlanNames}
                      setSelectedPlanIds={setSelectedPlanIds}
                      setSelectedPlanNames={setSelectedPlanNames}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default withRouter(PlanDeleteDialog);
