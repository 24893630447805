import * as React from "react";
import Divider from "../../component/divider";

interface IOuterProps {
  title: string;
  description?: string;
}

type AllProps = IOuterProps;
const Header = (props: AllProps) => {
  const { title, description } = props;
  return (
    <React.Fragment>
      <div className="c-pageheader">
        <div className="c-pageheader-title">{title}</div>
        {description ? (
          <div
            className="c-pageheader-descriptions"
            dangerouslySetInnerHTML={{ __html: description }}
          />
        ) : (
          <></>
        )}
        <Divider />
      </div>
    </React.Fragment>
  );
};

export default Header;
