import { Action, Reducer } from "redux";
import { IHouseholdItem } from "../models/household_item";

export interface IHouseholdItemState {
  fetchedHouseholdItem: IHouseholdItem[];
}

const initialState: IHouseholdItemState = {
  fetchedHouseholdItem: []
};

export enum ActionName {
  setHouseholdItem = "householdItem/setHouseholdItem"
}

interface ISetHouseholdItem extends Action<ActionName.setHouseholdItem> {
  fetchedHouseholdItem: IHouseholdItem[];
}

export type IHouseholdItemAction = ISetHouseholdItem;

export const householdItemReducer: Reducer<
  IHouseholdItemState | undefined,
  IHouseholdItemAction
> = (state = initialState, action) => {
  switch (action.type) {
    case ActionName.setHouseholdItem: {
      return { ...state, fetchedHouseholdItem: action.fetchedHouseholdItem };
    }
  }
  return state;
};
