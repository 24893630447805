import React from 'react';
import { Provider } from "react-redux";
import { rootStore } from './store';
import ErrorBoundary from './ui/error_boundary';
import Router from './router';

const App: React.FC = () => {
  return (
    <Provider store={rootStore}>
      <ErrorBoundary>
        <Router />
      </ErrorBoundary>
    </Provider>
  );
}

export default App;
