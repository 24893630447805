import React from "react";
import { parsePriceFormat, eventVersionPriceDisplay, showThePrice } from "../../../util";
import { getSession } from "../../../session";

interface IPartListProps {
  price: number;
  isDefault?: boolean;
}
type AllProps = IPartListProps;

export const priceDiffState = (price: number) => {
  if (price < 0) {
    return "down";
  } else if (price > 0) {
    return "up";
  }
  return "flat";
};

const PriceDiff = (props: AllProps) => {
  const { price } = props;

  if((showThePrice(getSession()) && !eventVersionPriceDisplay)){
    return (
      <>
        <div className="c-card-body-price" data-state={priceDiffState(price)}>
          <div className="c-card-body-price-guide">標準パーツから</div>
          {priceDiffState(price) === "flat" ? (
            <div className="c-card-body-price-icon_flat">変更なし</div>
          ) : (
            <div className="price-diff-wrapper">
              <div className="c-card-body-price-icon_up">Up</div>
              <div className="c-card-body-price-icon_down">Down</div>
              <div className="price-diff">{parsePriceFormat(price)}</div>
            </div>
          )}
        </div>
      </>
    );
  } else {
    return (
      <React.Fragment></React.Fragment>
    );
  }
};

export default PriceDiff;
