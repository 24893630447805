import * as React from "react";
import { useCallback, useEffect, useReducer, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import { Dispatch } from "redux";
import sendPageView from "../../../analytics";
import { ApiClient } from "../../../api";
import Divider from "../../../component/divider";
import Loading from "../../../component/loading";
import ICase from "../../../models/case";
import { ActionName, ICaseAction } from "../../../redux/case";
import { handleRequestError, buildPlanQueryParam, addPlanQueryParam } from "../../../util";
import CaseImageList from "../list/case_image_list";
import { getSession, setSession } from "../../../session"

const CaseConfirmNav = (props: { onBack: () => void; ids: number[] }) => {
  const { onBack, ids } = props;

  let session = getSession();
  if (session != null) {
    session.select_case_ids = ids.join(",");
    setSession(session);
  }

  return (
    <React.Fragment>
      <div className="c-nav_bar--light navigation-header">
        <div className="c-nav_bar-left">
          <div className="c-button" onClick={onBack}>
            <span className="c-button-strings">戻る</span>
          </div>
        </div>
        <div className="c-nav_bar-center" />
        <div className="c-nav_bar-right">
          {ids.length === 0 ? (
            <div className="c-button">
              <span className="c-button-strings">オススメのテイストへ</span>
            </div>
          ) : (
            <Link
              to={{
                pathname: "/taste/recommend",
                search: `?select_case_ids=${ids.join(
                  ","
                )}${addPlanQueryParam()}`
              }}
              className="c-button"
            >
              <span className="c-button-strings">オススメのテイストへ</span>
            </Link>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

const CaseConfirmHeader = () => {
  return (
    <React.Fragment>
      <div className="c-pageheader">
        <div className="c-pageheader-title">このイメージでOK？</div>
        <div className="c-pageheader-descriptions">
          ここで選ばれたイメージをもとに、あなたにぴったりのテイストが提案されます。
        </div>
        <Divider />
      </div>
    </React.Fragment>
  );
};

const CaseConfirmContents = (props: {
  loading: boolean;
  ids: number[];
  selectCases: ICase[];
  dispatch: Dispatch<ICaseAction>;
  forceUpdate: any;
}) => {
  const { loading, ids, selectCases, dispatch, forceUpdate } = props;
  const [loadedImages, setLoadedImages] = useState<string[]>([]);
  if (loading) {
    return <Loading />;
  }

  if (selectCases.length === 0) {
    return <p className="u-tc--danger">事例が選択されていません</p>;
  }

  return (
    <CaseImageList
      ids={ids}
      isReadable={true}
      forceUpdate={forceUpdate}
      cases={selectCases}
      dispatch={dispatch}
      onModalSelect={sendPageViewCaseConfirm}
      onModalClose={sendPageViewCaseConfirm}
      isModalConfirm={true}
    />
  );
};

const sendPageViewCaseConfirm = () => {
  sendPageView("事例確認画面", "/case/confirm");
};

const CaseConfirmFooter = (props: { onBack: () => void; ids: number[] }) => {
  const { onBack, ids } = props;

  let session = getSession();
  if (session != null) {
    session.select_case_ids = ids.join(",");
    setSession(session);
  }

  return (
    <div className="c-footer">
      <Divider />
      <div className="c-footer-buttons">
        <div className="c-footer-buttons-sub c-button" onClick={onBack}>
          <span className="c-button-strings">戻る</span>
        </div>

        {ids.length === 0 ? (
          <div className="c-footer-buttons-main c-button" data-state="disabled">
            <span className="c-button-strings">オススメのテイストへ</span>
          </div>
        ) : (
          <Link
            className="c-footer-buttons-main c-button"
            to={{
              pathname: "/taste/recommend",
              search: `?select_case_ids=${ids.join(",")}${addPlanQueryParam()}`
            }}
          >
            <span className="c-button-strings">オススメのテイストへ</span>
          </Link>
        )}
      </div>
    </div>
  );
};

type AllProps = RouteComponentProps;

const CaseConfirmContainer = (props: AllProps & { forceUpdate: any }) => {
  const [loading, setLoading] = useState(false);

  const casesSelector = (state: any) => state.caseReducer.fetchCases;
  const cases: ICase[] = useSelector(casesSelector);
  const dispatch = useDispatch<Dispatch<ICaseAction>>();

  // 事例の選択状態
  const searchParams = new URLSearchParams(window.location.search.substring(1));
  const param = searchParams.get("ids");
  const idsSelector = (state: any) => state.caseReducer.selectedCaseIds;

  let ids: number[] = useSelector(idsSelector);
  if (loading && param) {
    // リロード対応
    ids = param.split(",").map(p => Number(p));
  }

  // 一度storeに選択状態を保存しておく
  useEffect(() => {
    dispatch({
      type: ActionName.addSelectedCaseId,
      selectedCaseIds: ids
    });
  }, [dispatch]);

  const selectCases = cases.filter(c => ids.includes(c.id));

  const fetchCases = useCallback(async () => {
    setLoading(true);
    try {
      const result = await new ApiClient().getCases();
      setLoading(false);
      dispatch({
        type: ActionName.setCases,
        fetchCases: result
      });
    } catch (e) {
      setLoading(false);
      handleRequestError(e, props.history);
    }
  }, [dispatch]);

  useEffect(() => {
    fetchCases();
  }, [fetchCases]);

  return (
    <React.Fragment>
      <CaseConfirmNav
        onBack={() => props.history.push(`/case/list${buildPlanQueryParam()}`)}
        ids={ids}
      />
      <div className="c-layout">
        <div className="c-layout-child">
          <CaseConfirmHeader />
          <CaseConfirmContents
            loading={loading}
            ids={ids}
            selectCases={selectCases}
            dispatch={dispatch}
            forceUpdate={props.forceUpdate}
          />
        </div>
        <div className="c-layout-child">
          <CaseConfirmFooter
            onBack={() =>
              props.history.push(`/case/list${buildPlanQueryParam()}`)
            }
            ids={ids}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

const CaseConfirm = (props: AllProps) => {
  const [, forceUpdate] = useReducer(x => x + 1, 0);
  return (
    <div className="t-case t-case-confirm">
      <CaseConfirmContainer {...props} forceUpdate={forceUpdate} />
    </div>
  );
};

export default withRouter(CaseConfirm);
