import * as React from "react";

export interface IMultipleItem {
  index: number;
  title: string;
  onClick: () => void;
}

interface IOuterProps {
  title: string;
  text: string;
  items: IMultipleItem[];
}

type AllProps = IOuterProps;

const VerticalItem = (props: { items: IMultipleItem[] }) => {
  return (
    <React.Fragment>
      {props.items
        .sort((lhs, rhs) => lhs.index - rhs.index)
        .map(item => {
          return (
            <div
              key={item.index}
              className="c-nav_bar-vertical-item"
              onClick={item.onClick}
            >
              <div className="c-button c-modal_close">
                <div className="c-button-strings">
                  <div className="u-tc--success">{item.title}</div>
                </div>
              </div>
            </div>
          );
        })}
    </React.Fragment>
  );
};

const MultipleableConfirm = (props: AllProps) => {
  const { title, text, items } = props;

  if (items.length === 0) {
    return <></>;
  }

  return (
    <React.Fragment>
      <div
        className="c-modal--alert"
        data-modal="active"
        data-modal-color="light_trans_nav"
      >
        <div className="c-modal-inwrapper">
          <div
            className="c-modal-inwrapper-content"
            style={{ overflowY: "hidden" }}
          >
            <div className="c-layout">
              <div className="c-layout-child">
                <div className="u-fs--base">{title}</div>
                <div className="u-fs--small">
                  {text.split("\n").map((str, index) => (
                    <React.Fragment key={index}>
                      {str}
                      <br />
                    </React.Fragment>
                  ))}
                </div>
              </div>
              <div className="c-layout-child">
                <div className="c-nav_bar-vertical">
                  <VerticalItem items={items} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default MultipleableConfirm;
