import * as React from "react";
import { useCallback, useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import { Dispatch } from "redux";
import { ApiClient } from "../../../api";
import Header from "../../../component/header";
import NavigationTL from "../../../component/navigation/index_tasteList";
import TransitionWrapper from "../../../component/transition";
import { ITaste } from "../../../models/taste";
import { ActionName, ITasteAction } from "../../../redux/taste";
import TasteCell from "./taste_cell";
import Loading from "../../../component/loading";
import { handleRequestError } from "../../../util";
import KeywordItemCancel from "./keyword_item_cancel";
import { getSession, setSession } from "../../../session";
import { parsePublishKey } from "../../../util";

export interface ITag {
  name: string;
  times: number;
  ischecked: boolean;
  type: string;
}

export interface IKeyword {
  name: string;
  times: number;
  type: string;
}

interface ITasteListPropsForTagFilter {
  recommends: number[];
  keywords: IKeyword[];
}

interface ITasteListProps {
  recommends: number[];
}

type TasteListPropsForTagFilter = RouteComponentProps & ITasteListPropsForTagFilter;
const TasteListContentTL = (props: TasteListPropsForTagFilter) => {
  const { recommends } = props;
  //選択中のタグの配列
  const { keywords } = props;
  const [loading, setLoading] = useState(false);

  const tasteSelector = (state: any) => state.tasteReducer.fetchTasteList;
  const tastes: ITaste[] = useSelector(tasteSelector);
  const dispatcher = useDispatch<Dispatch<ITasteAction>>();

  const fetchTasteList = useCallback(async () => {
    setLoading(true);
    try {
      const result = await new ApiClient().getTastes();
      dispatcher({
        type: ActionName.setTasteList,
        fetchTasteList: result
      });
      setLoading(false);
    } catch (e) {
      setLoading(false);
      handleRequestError(e, props.history);
    }
  }, [dispatcher]);

  useEffect(() => {
    fetchTasteList();
  }, [fetchTasteList]);

  if (loading) {
    return <Loading />;
  }

  // URLから選択事例IDを取得
  const searchParams = new URLSearchParams(window.location.search.substring(1));
  const queryString = searchParams.get("select_case_ids") || "";
  const selectCaseIds = queryString.split(",").map(id => Number(id));

  return (
    <div className="c-group--taste_variation_list">
      {
        //taste一覧画像の表示
        tastes.map(t => {
          //tasteのキーワードが選択中のタグに該当するかどうか
          let th = false;
          //color系keywords
          let splitted_color = t.color_keywords.split(",");
          splitted_color.map(i => {
            keywords.map(j => {
              if (i === j.name) {
                th = true;
              }
            });
          });
          //style系keywords
          let splitted_style = t.style_keywords.split(",");
          splitted_style.map(i => {
            keywords.map(j => {
              if (i === j.name) {
                th = true;
              }
            });
          });
          //タグ未選択の場合全てのテイスト表示(keywords.length===0)
          //tasteのキーワードが選択中のタグに該当する場合そのtasteを表示(th === true)
          if (keywords.length === 0 || th) {
            return (
              <div
                key={t.id}
                className="c-card"
                onClick={() => {
                  let session = getSession();
                  if (session != null) {
                    session.from_where = `${window.location.pathname + window.location.search}`
                    setSession(session);
                  }
                  props.history.push(
                    `/taste/detail/${t.id}/?select_case_ids=${selectCaseIds}`
                  );
                }}
              >
                <TasteCell
                  id={t.id}
                  title={t.title}
                  imageUrl={t.images[0]}
                  isRecommend={recommends.includes(t.id)}
                />
              </div>
            );
          }
        })
      }
    </div>
  );
};

type AllProps = RouteComponentProps;

const TasteList = (props: AllProps) => {
  //●トグルメニュー開閉の状態
  const [on, setOn] = useState<boolean>(false);
  //トグルメニュー開閉
  const changeToggleMenu = (open: boolean) => {
    setOn(!open);
  }

  //●tasteのローディング状態
  const [loading, setLoading] = useState(false);
  const tasteSelector = (state: any) => state.tasteReducer.fetchTasteList;
  const tastes: ITaste[] = useSelector(tasteSelector);
  const dispatcher = useDispatch<Dispatch<ITasteAction>>();
  const fetchTasteList = useCallback(async () => {
    setLoading(true);
    try {
      const result = await new ApiClient().getTastes();
      dispatcher({
        type: ActionName.setTasteList,
        fetchTasteList: result
      });
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  }, [dispatcher]);

  useEffect(() => {
    fetchTasteList();
  }, [fetchTasteList]);

  //●名前、回数、選択状態の情報が含まれているタグの状態
  const [tag, setTag] = useState<ITag[]>([]);
  function initTag() {
    //(keyword名、回数)
    let keywordsContentMap = new Map();
    //(keyword名、種類)
    let keywordsTypeMap = new Map();
    tastes.map((t) => {
      //color系keywords
      let splitted_color = t.color_keywords.split(",");
      for (let i = 0; i < splitted_color.length; i++) {
        if (splitted_color[i] != "") {
          if (keywordsContentMap.has(splitted_color[i])) {
            keywordsContentMap.set(splitted_color[i], keywordsContentMap.get(splitted_color[i]) + 1);
          } else {
            keywordsContentMap.set(splitted_color[i], 1);
            keywordsTypeMap.set(splitted_color[i], "color");
          }
        }
      }
      //style系keywords
      let splitted_style = t.style_keywords.split(",");
      for (let i = 0; i < splitted_style.length; i++) {
        if (splitted_style[i] != "") {
          if (keywordsContentMap.has(splitted_style[i])) {
            keywordsContentMap.set(splitted_style[i], keywordsContentMap.get(splitted_style[i]) + 1);
          } else {
            keywordsContentMap.set(splitted_style[i], 1);
            keywordsTypeMap.set(splitted_style[i], "style");
          }
        }
      }
    });
    let i = 0;
    keywordsContentMap.forEach((value: any, key: any) => {
      tag[i] = {
        name: key,
        times: value,
        ischecked: false,
        type: keywordsTypeMap.get(key)
      };
      i++;
    });
  }
  //tastesの内容が変わる限り、タグの配列を更新
  const actionInitTag = useMemo(() => { initTag() }, [tastes]);

  //タグ選択後の処理
  const changeCheck = (name: string) => {
    const newTag = tag.map(t => {
      if (t.name === name) {
        return Object.assign({}, t, {
          ischecked: !t.ischecked
        });
      }
      return t;
    });
    setTag(newTag);
  }

  //●選択中のタグ名の配列の状態
  const [keywordList, setKeywordList] = useState<IKeyword[]>([]);
  //検索ボタン押下後更新
  const getKeywordList = () => {
    let keywordL: IKeyword[] = [];
    tag.map((t, Number) => {
      if (t.ischecked) {
        keywordL[Number] = {
          name: t.name,
          times: t.times,
          type: t.type
        };
      }
    });
    setKeywordList(keywordL);
  }
  //キーワードの「ｘ」ボタン押下後の処理
  const keywordCancel = (name: string) => {
    const newKeywordList = keywordList.filter(k => k.name !== name);
    setKeywordList(newKeywordList);
  }
  //●検索結果件数
  const [tasteNum, setTasteNum] = useState<number>(tastes.length);
  //tastesが変わる限り、件数を更新
  useMemo(() => {
    setTasteNum(tastes.length);
  }, [tastes]);
  //選択中のタグ名の配列が変わる限り、件数を更新
  useEffect(() => {
    let num = 0;
    tastes.map(t => {
      let th = false;
      //color系keywords
      let splitted_color = t.color_keywords.split(",");
      splitted_color.map(i => {
        tag.map(j => {
          if (j.ischecked) {
            if (i === j.name) {
              th = true;
            }
          }
        });
      });
      //style系keywords
      let splitted_style = t.style_keywords.split(",");
      splitted_style.map(i => {
        tag.map(j => {
          if (j.ischecked) {
            if (i === j.name) {
              th = true;
            }
          }
        });
      });
      if (th) {
        num++;
      }
    });
    if (num === 0) {
      setTasteNum(tastes.length);
    } else {
      setTasteNum(num);
    }
  }, [tag]);

  //選択中のタグ名の配列、トグルメニューの開閉状態が変わる限り、タグの選択状態を更新
  useEffect(() => {
    const newTag = tag.map(t => {
      if (keywordList.filter(k => k.name === t.name).length > 0) {
        return Object.assign({}, t, {
          ischecked: true
        });
      } else {
        return Object.assign({}, t, {
          ischecked: false
        });
      }
    });
    setTag(newTag);
  }, [on, keywordList]);

  const getSelectedCaseIds = (): string => {
    const session = getSession();
    const loadSelectCaseIds = session ? session.select_case_ids : "";
    const searchParams = new URLSearchParams(window.location.search.substring(1));
    const selectCaseIdsParam = searchParams.get("select_case_ids") || loadSelectCaseIds;
    return selectCaseIdsParam;
  };

  // URLからオススメのテイストIDを取得
  const searchParams = new URLSearchParams(window.location.search.substring(1));
  const queryString = searchParams.get("recommends") || "";
  const ids = queryString.split(",").map(id => Number(id));
  let viewClass = 't-search-result-area';
  if (keywordList.length === 0) {
    viewClass = 't-search-result-area hidden';
  }

  return (
    <TransitionWrapper>
      <div className="t-taste">
        <NavigationTL
          left={"戻る"}
          onLeftClick={() => props.history.push(`/taste/recommend?select_case_ids=${getSelectedCaseIds()}&publish_key=${parsePublishKey()}`)}
          right={"絞り込み "}
          onRightClick={() => { changeToggleMenu(on) }}
          isOpen={on ? true : false}
          tag={tag}
          tasteNum={tasteNum}
          changeCheck={changeCheck}
          getKeywordList={getKeywordList}
        />
        <div className="c-layout">
          <div className="c-layout-child">
            <Header
              title="全てのテイストから自分の好みを探してみよう！"
              description="1つお好みのテイストを選んでください。<br />選択したテイストをベースに、お部屋をアレンジすることが出来ます！"
            />
            <div className={viewClass} >
              <div className="t-search-result-text"><b>絞り込まれた結果：{tasteNum}件 </b></div>
              <div className="t-search-result-box">{
                keywordList.map((k, number) => {
                  return (
                    <KeywordItemCancel
                      key={number + k.name + "_cancel"}
                      name={k.name}
                      times={k.times}
                      cancel={keywordCancel}
                    />
                  );
                })
              }</div>
            </div>
            <TasteListContentTL {...props} recommends={ids} keywords={keywordList} />
          </div>
        </div>
      </div>
    </TransitionWrapper>
  );
};
export default TasteList;