import { Action, Reducer } from "redux";
import { IPlan, IPlanInfo } from "../models/plan";

export interface IPlanState {
  planState: IPlanInfo;
  fetchedPlan: IPlan;
  fetchedPlans: IPlan[];
  duplicatedPlan: IPlan;
}

const initialState: IPlanState = {
  planState: {
    id: 0,
    publish_key: "",
    total_price: 0,
    total_price_difference: 0,
    square_meter: 0,
    is_share: false
  },
  fetchedPlan: {
    id: 0,
    thumbnail_url: "",
    name: "",
    publish_key: "",
    login_key: "",
    square_meter: 0,
    status: "",
    memo: "",
    created_at: "",
    updated_at: "",
    is_share: false,
    total_price_difference: 0,
    total_price: 0,
    taste: {
      id: 0,
      title: "",
      color_keywords:"",
      style_keywords: "",
      price_area: 0,
      note: "",
      sort_order: 0,
      images: [],
      colors: [],
      recommends: [],
      base_images: {
        id: 0,
        image_url_01: "",
        image_url_02: "",
        range_hood_url_01: "",
        range_hood_url_02: "",
        spotlight_url_01: "",
        spotlight_url_02: "",
        curtain_url: "",
        curtain_url_02: ""
      }
    },
    categories: [],
    is_over: false,
    exist: false,
    hide_taste: false,
    user_made_images: {}
  },
  fetchedPlans: [],
  duplicatedPlan: {
    id: 0,
    thumbnail_url: "",
    name: "",
    publish_key: "",
    login_key: "",
    square_meter: 0,
    status: "",
    memo: "",
    created_at: "",
    updated_at: "",
    is_share: false,
    total_price_difference: 0,
    total_price: 0,
    taste: {
      id: 0,
      title: "",
      color_keywords:"",
      style_keywords: "",
      price_area: 0,
      note: "",
      sort_order: 0,
      images: [],
      colors: [],
      recommends: [],
      base_images: {
        id: 0,
        image_url_01: "",
        image_url_02: "",
        range_hood_url_01: "",
        range_hood_url_02: "",
        spotlight_url_01: "",
        spotlight_url_02: "",
        curtain_url: "",
        curtain_url_02: ""
      }
    },
    categories: [],
    is_over: false,
    exist: false,
    hide_taste: false,
    user_made_images: {}
  }
};

export enum ActionName {
  setPlan = "plan/setPlan",
  setPlanState = "plan/setPlanState",
  setPlans = "plan/setPlans",
  setDuplicatedPlan = "plan/setDuplicatedPlan",
  createPlan = "plan/createPlan",
  showDeletePlan = "plan/showDeletePlan",
  showDeletedPlan = "plan/showDeletedPlan",
  showDrawer = "plan/showDrawer",
  showDuplicateConfirm = "plan/showDuplicateConfirm"
}

interface ISetPlan extends Action<ActionName.setPlan> {
  fetchedPlan: IPlan;
}

interface ISetPlanState extends Action<ActionName.setPlanState> {
  planState: IPlanInfo;
}

interface ISetPlans extends Action<ActionName.setPlans> {
  fetchedPlans: IPlan[];
}

interface ISetDuplicatedPlan extends Action<ActionName.setDuplicatedPlan> {
  duplicatedPlan: IPlan;
}

interface ICreatePlan extends Action<ActionName.createPlan> {
  createPlan: boolean;
}

interface IShowDeletePlan extends Action<ActionName.showDeletePlan> {
  showDeletePlan: boolean;
}

interface IShowDeletedPlan extends Action<ActionName.showDeletedPlan> {
  showDeletedPlan: boolean;
}

interface IShowDrawer extends Action<ActionName.showDrawer> {
  showDrawer: boolean;
}

interface IShowDuplicateConfirm
  extends Action<ActionName.showDuplicateConfirm> {
  showDuplicateConfirm: boolean;
}

export type IPlanAction =
  | ISetPlan
  | ISetPlanState
  | ISetPlans
  | ISetDuplicatedPlan
  | ICreatePlan
  | IShowDeletePlan
  | IShowDeletedPlan
  | IShowDrawer
  | IShowDuplicateConfirm;

export const planReducer: Reducer<IPlanState | undefined, IPlanAction> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case ActionName.setPlan: {
      return { ...state, fetchedPlan: action.fetchedPlan };
    }
    case ActionName.setPlanState: {
      return { ...state, planState: action.planState };
    }
    case ActionName.setPlans: {
      return { ...state, fetchedPlans: action.fetchedPlans };
    }
    case ActionName.setDuplicatedPlan: {
      return { ...state, duplicatedPlan: action.duplicatedPlan };
    }
    case ActionName.createPlan: {
      return { ...state, createPlan: action.createPlan };
    }
    case ActionName.showDeletePlan: {
      return { ...state, showDeletePlan: action.showDeletePlan };
    }
    case ActionName.showDeletedPlan: {
      return { ...state, showDeletedPlan: action.showDeletedPlan };
    }
    case ActionName.showDrawer: {
      return { ...state, showDrawer: action.showDrawer };
    }
    case ActionName.showDuplicateConfirm: {
      return {
        ...state,
        showDuplicateConfirm: action.showDuplicateConfirm
      };
    }
  }
  return state;
};
