import * as React from "react";

interface Iprops{
  name: string;
  times: number;
  type: string;
  ischecked: boolean;
  changeCheck: (name: string) => void
}

const KeywordItem = (props: Iprops) => {
  const { name, times, ischecked, changeCheck} = props;
  return (
    <span
      onClick={() => {changeCheck(name)}}
      style={(ischecked)
        ?
        {
          backgroundColor:"darkgrey",
          color:"white",
          cursor:"pointer",
          borderRadius:"30em",
          border:"2px solid grey",
          margin:"5px 10px 5px 10px",
          display:"inline-block",
          paddingLeft:"10px",
          paddingRight:"10px"
        }
        :
        {
          backgroundColor:"white",
          color:"black",
          cursor:"pointer",
          borderRadius:"30em",
          border:"2px solid grey",
          margin:"5px 10px 5px 10px",
          display:"inline-block",
          paddingLeft:"10px",
          paddingRight:"10px"
        }
      }
    >
      {name}({times})
    </span>
  );

};

export default KeywordItem;