import { combineReducers, createStore, Store } from "redux";
import { caseReducer, ICaseState } from "../src/redux/case";
import { categoryReducer, ICategoryState } from "../src/redux/category";
import { IPlanState, planReducer } from "../src/redux/plan";
import { IPlanPartState, planPartReducer } from "../src/redux/plan_part";
import { ISessionRestoreState, sessionRestoreReducer } from "../src/redux/session_restore";
import { ISettingState, settingReducer } from "../src/redux/setting";
import { ITasteState, tasteReducer } from "../src/redux/taste";
import { ITastePartState, tastePartReducer } from "../src/redux/taste_part";
import { ITastePartListState, tastePartListReducer } from "../src/redux/taste_part_list";
import { IAllPartState, allPartReducer } from "../src/redux/all_part";
import { IAllPartListState, allPartListReducer } from "../src/redux/all_part_list";
import { householdItemReducer, IHouseholdItemState } from "./redux/household_item";

export interface IRootState {
  caseState: ICaseState;
  tasteState: ITasteState;
  planState: IPlanState;
  settingState: ISettingState;
  categoryState: ICategoryState;
  planPartState: IPlanPartState;
  sessionRestoreReducer: ISessionRestoreState;
  tastePartState: ITastePartState;
  tastePartListState: ITastePartListState;
  allPartState: IAllPartState;
  allPartListState: IAllPartListState;
  tasteTotalPriceState: ITasteState;
  fetchedHouseholdItem: IHouseholdItemState;
}

export const rootStore: Store<IRootState> = createStore(
  combineReducers({
    caseReducer,
    categoryReducer,
    tasteReducer,
    planReducer,
    settingReducer,
    planPartReducer,
    sessionRestoreReducer,
    tastePartReducer,
    tastePartListReducer,
    allPartReducer,
    allPartListReducer,
    householdItemReducer,
  })
);
