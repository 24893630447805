import { Action, Reducer } from "redux";
import ICategory from "../models/category";

export interface ICategoryState {
  fetchedCategories: ICategory[];
}

const initialState: ICategoryState = {
  fetchedCategories: []
};

export enum ActionName {
  setCategories = "category/setCategories"
}

interface ISetCategories extends Action<ActionName.setCategories> {
  fetchedCategories: ICategory[];
}

export type ICategoryAction = ISetCategories;

export const categoryReducer: Reducer<
  ICategoryState | undefined,
  ICategoryAction
> = (state = initialState, action) => {
  switch (action.type) {
    case ActionName.setCategories: {
      return { ...state, fetchedCategories: action.fetchedCategories };
    }
  }
  return state;
};
