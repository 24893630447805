import * as React from "react";
import WalkThroughContent from "./content";

interface IOuterProps {
  onClickClose?: (e: any) => void;
  disableModal?: boolean;
}

type AllProps = IOuterProps;

const WalkThroughDialog = (props: AllProps) => {
  const { onClickClose, disableModal } = props;

  return (
    <div
      className="c-modal--alert walk-through-dialog"
      data-modal="active"
      data-modal-color="light_trans_nav"
    >
      <div className="c-modal-inwrapper">
        <div
          className="c-modal-inwrapper-content"
          style={{
            borderRadius: 0,
            width: "330px",
            maxHeight: "90vh",
            overflowY: "hidden"
          }}
        >
          <div className="c-layout">
            <div className="c-layout-child" style={{ padding: "0 0 12px 0" }}>
              <WalkThroughContent
                onClickClose={onClickClose}
                disableModal={disableModal}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WalkThroughDialog;
