import * as React from "react";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Dispatch } from "redux";
import { ApiClient } from "../../../api";
import { IUpdatePlanSquareMeterParam } from "../../../api/request/plans";
import Confirm from "../../../component/confirm";
import MultipleableConfirm, { IMultipleItem } from "../../../component/confirm/multipleable";
import Divider from "../../../component/divider";
import Header from "../../../component/header";
import Loading from "../../../component/loading";
import Navigation from "../../../component/navigation";
import SelectSquareMeter from "../../../component/select_square_meter";
import { IPlan } from "../../../models/plan";
import { IPlanParts } from "../../../models/plan_part";
import { ActionName as ActionNamePlan, IPlanAction } from "../../../redux/plan";
import { ActionName, IPlanPartAction } from "../../../redux/plan_part";
import { getSession, setSession, setActivePlan } from "../../../session";
import {
  buildPlanQueryParam,
  handleRequestError,
  loadPlanPublishKey,
  parsePriceFormat,
  parsePublishKey,
  scrollFromQueryParam,
  eventVersionPriceDisplay,
  visualizationFuncAvailable,
  showThePrice
} from "../../../util";
import PlanDeleteDialog from "../../plan/delete_dialog";
import { priceDiffState } from "../price_diff";
import PartCell, { PartState } from "./part_cell";

const resetPlan = async (props: RouteComponentProps) => {
  resetBaseTaste();
  await new ApiClient().tasteReset(parsePublishKey());
  props.history.push("/taste/recommend");
};

const actionDuplicateToTasteRecommend = async (
  planId: number,
  dispatchPlan: Dispatch<IPlanAction>,
  props: RouteComponentProps
) => {
  // プラン複製
  const duplicatedPlan = await new ApiClient().duplicatePlan(planId);
  // リセット
  await new ApiClient().tasteReset(duplicatedPlan.publish_key);
  // 再取得
  const responsePlan = await new ApiClient().getPlan(duplicatedPlan.id);
  dispatchPlan({
    type: ActionNamePlan.setPlan,
    fetchedPlan: responsePlan
  });
  setActivePlan(responsePlan);
  // 事例一覧へ
  props.history.push("/taste/recommend");
};

const newPlan = async (
  planId: number,
  props: RouteComponentProps,
  dispatcher: Dispatch<IPlanAction>
) => {
  const result = await new ApiClient().limitPlan();
  if (result.is_over) {
    // 削除確認ダイアログを表示する
    dispatcher({
      type: ActionNamePlan.showDeletePlan,
      showDeletePlan: true
    });
  } else {
    // // プラン複製
    actionDuplicateToTasteRecommend(planId, dispatcher, props);
  }
};

const PartHomeHeader = (props: AllProps) => {
  const [showBackConfirm, setShowBackConfirm] = useState(false);
  const [showResetConfirm, setShowResetConfirm] = useState(false);

  const dispatcherPlan = useDispatch<Dispatch<IPlanAction>>();
  const planSelector = (state: any) => state.planReducer.fetchedPlan;
  const resultPlan: IPlan = useSelector(planSelector);
  if (resultPlan.status == "locked") {
    props.history.push(`/share/detail?publish_key=${resultPlan.publish_key}`);
  }

  async function whereToGoBack () {
    const session = getSession();
    const visualizationFlg = await new ApiClient().getVisualizationFuncStatus(resultPlan.taste.id);
    if (visualizationFlg.status && session && session.visualization_func_status) {
      props.history.push(`/visualization${buildPlanQueryParam()}`);
    } else {
      const publishKey = resultPlan.publish_key;
      const select_case_ids = (session && session.select_case_ids !== "") ? session.select_case_ids : "0";
      const base_taste_id = (session && session.base_taste_id !== 0) ? session.base_taste_id : (resultPlan.taste.id !== null ? resultPlan.taste.id : 0);
      await new ApiClient().tasteReset(resultPlan.publish_key);
      let url: string = session ? session.from_where : `/taste/recommend?select_case_ids=${select_case_ids}&publish_key=${publishKey}`;
      if (url === "" || url === null || url === undefined) {
        if (base_taste_id !== 0) {
          url =`/taste/detail/${base_taste_id}/?select_case_ids=${select_case_ids}`;
        } else {
          url =`/taste/list/?recommends=0&select_case_ids=${select_case_ids}`;
        }
      }
      props.history.push(url);
    }
  }

  return (
    <React.Fragment>
      <Navigation
        left={"戻る"}
        onLeftClick={() => whereToGoBack()}
        onRightClick={async () =>
          submitSelectPart(props, dispatcherPlan, resultPlan)
        }
        right={"作成したプランを保存"}
      />
      <Header
        title="設備やオプションを選ぼう"
        description="部材をクリックすることで好みのものに変更・追加することが出来ます。デザイナーに伝えたいことは、メモ欄に記入しましょう。"
      />
      {showBackConfirm &&
        (resultPlan.status === "locked" ? (
          <ConfirmLockedDialog
            onOk={async () => {
              await newPlan(resultPlan.id, props, dispatcherPlan);
              setShowBackConfirm(false);
            }}
            onCancel={() => setShowBackConfirm(false)}
          />
        ) : (
          <ConfirmNonLockedDialog
            onNewPlan={async () => {
              await newPlan(resultPlan.id, props, dispatcherPlan);
              setShowBackConfirm(false);
            }}
            onReset={async () => {
              if (visualizationFuncAvailable(getSession())) {
                if ((await new ApiClient().checkHideTaste(parsePublishKey())).hide_taste) {
                  const visualizationFlg = await new ApiClient().getVisualizationFuncStatus(resultPlan.taste.id);
                  if(visualizationFlg.status == false){
                    props.history.push(`/taste/recommend`);
                  } else {
                    setShowResetConfirm(true);
                    props.history.push(`/visualization${buildPlanQueryParam()}`);
                  }
                } else {
                  await resetPlan(props);
                  props.history.push("/taste/recommend");
                }
                setShowBackConfirm(false);
              } else {
                if ((await new ApiClient().checkHideTaste(parsePublishKey())).hide_taste) {
                  setShowResetConfirm(true);
                } else {
                  await resetPlan(props);
                }
                setShowBackConfirm(false);
              }
            }}
            onCancel={() => setShowBackConfirm(false)}
          />
        ))
      }
      {showResetConfirm &&
        <ConfirmResetDialog
          onOk={async () => {
            await resetPlan(props);
          }}
          onCancel={() => setShowResetConfirm(false)}
        />
      }
    </React.Fragment>
  );
};

const ConfirmResetDialog = (props: {
  onOk: () => void;
  onCancel: () => void;
}) => {
  const message = `以前のテイストは選択できません。変更して宜しいですか？`;
  return (
    <React.Fragment>
      <Confirm
        title=""
        text={message}
        okText="はい"
        cancelText="キャンセル"
        onClickOk={props.onOk}
        onClickCancel={props.onCancel}
      />
    </React.Fragment>
  );
};

const ConfirmLockedDialog = (props: {
  onOk: () => void;
  onCancel: () => void;
}) => {
  const message = `
  このプランは既にプランURLを発行しているため、テイストを変更できません。
  新しいプランを作成してテイストを変更しますか？`;
  return (
    <React.Fragment>
      <Confirm
        title=""
        text={message}
        okText="はい"
        cancelText="キャンセル"
        onClickOk={props.onOk}
        onClickCancel={props.onCancel}
      />
    </React.Fragment>
  );
};

/*
  非ロック用の確認ダイアログ
 */
const ConfirmNonLockedDialog = (props: {
  onNewPlan: () => void;
  onReset: () => void;
  onCancel: () => void;
}) => {
  const items: IMultipleItem[] = [
    {
      index: 0,
      title: "新しいプランを作成する",
      onClick: () => props.onNewPlan()
    },
    {
      index: 1,
      title: "今のプランで部材選択をリセットする",
      onClick: () => props.onReset()
    },
    {
      index: 2,
      title: "キャンセル",
      onClick: () => props.onCancel()
    }
  ];

  const message = `
  テイスト選択へ戻るには、以下から方法をお選びください。
  部材選択を保存したい場合、「新しくプランを作成する」を選択してください。
  現在のプランが保存され、新たにプランを作成できます。`;

  return (
    <React.Fragment>
      <MultipleableConfirm
        title="部材の選択をリセットして、テイスト画面に戻りますか?"
        text={message}
        items={items}
      />
    </React.Fragment>
  );
};

const saveBaseTasteId = (plan: IPlan) => {
  const session = getSession();
  setSession({
    plan_id: session ? session.plan_id : 0,
    publish_key: session ? session.publish_key : "",
    select_case_ids: session ? session.select_case_ids : "",
    login_key: session ? session.login_key : "",
    base_taste_id: plan.taste.id,
    visualization_func_status: session ? session.visualization_func_status : false,
    accepted: session ? session.accepted : false,
    matter_type: session ? session.matter_type : 0,
    email: session ? session.email : "",
    show_reset_part_confirm: session ? session.show_reset_part_confirm : false,
    select_square_meter: plan ? plan.square_meter || 40 : 40,
    from_where: session ? session.from_where : ""
  });
};

const resetBaseTaste = () => {
  const session = getSession();
  setSession({
    plan_id: session ? session.plan_id : 0,
    publish_key: session ? session.publish_key : "",
    select_case_ids: session ? session.select_case_ids : "",
    login_key: session ? session.login_key : "",
    base_taste_id: 0,
    visualization_func_status: session ? session.visualization_func_status : false,
    accepted: session ? session.accepted : false,
    matter_type: session ? session.matter_type : 0,
    email: session ? session.email : "",
    show_reset_part_confirm: session ? session.show_reset_part_confirm : false,
    select_square_meter: session ? session.select_square_meter || 40 : 40,
    from_where: session ? session.from_where : ""
  });
};

type AllProps = RouteComponentProps;
const PartHomeContent = (props: AllProps) => {
  const [loading, setLoading] = useState(false);

  // planPart
  const planPartsSelector = (state: any) =>
    state.planPartReducer.fetchedPlanParts;
  const planParts: IPlanParts[] = useSelector(planPartsSelector);
  const dispatchPlanPart = useDispatch<Dispatch<IPlanPartAction>>();

  // plan
  const dispatcherPlan = useDispatch<Dispatch<IPlanAction>>();
  const planSelector = (state: any) => state.planReducer.fetchedPlan;
  const resultPlan: IPlan = useSelector(planSelector);

  const fetchContents = useCallback(async () => {
    setLoading(true);
    try {
      // publish_key -> plan
      const responsePlan = await loadPlanPublishKey();
      saveBaseTasteId(responsePlan);
      // plan
      dispatcherPlan({
        type: ActionNamePlan.setPlan,
        fetchedPlan: responsePlan
      });

      // planPart
      const planPartsResult = await new ApiClient().getPlanParts(
        responsePlan.id
      );
      dispatchPlanPart({
        type: ActionName.setPlanParts,
        fetchedPlanParts: planPartsResult
      });

      // スクロール
      scrollFromQueryParam("scroll_id");

      setLoading(false);
    } catch (e) {
      setLoading(false);
      handleRequestError(e, props.history);
    }
  }, [dispatchPlanPart, dispatcherPlan]);

  const existPlan = useCallback(async () => {
    const result: IPlan = await new ApiClient().existPlan(parsePublishKey());
    if (!result.exist) {
      dispatcherPlan({
        type: ActionNamePlan.showDeletedPlan,
        showDeletedPlan: true
      });
    }
  }, []);

  useEffect(() => {
    fetchContents();
    existPlan();
  }, [fetchContents]);

  if (loading) {
    return <Loading />;
  }

  return (
    <React.Fragment>
      {(showThePrice(getSession()) && !eventVersionPriceDisplay )
        ? <PartHomeFloatingPriceDiff
          dispatcherPlan={dispatcherPlan}
          plan_id={resultPlan.id}
          square_meter={resultPlan.square_meter}
          total_price_difference={resultPlan.total_price_difference}
        />
        : <React.Fragment></React.Fragment>
      }
      <div className="c-group--part_category">
        {planParts.map(c => {


          const ignore_categoriy_ids = [1, 2, 5];
          if(!(ignore_categoriy_ids.includes(c.id))) {

          return (
            <div className="c-accordion" key={c.id}>
              <input
                className="c-accordion-toggler"
                type="checkbox"
                defaultChecked={true}
                id={`c-accordion-${c.id}`}
              />
              <label
                className="c-accordion-label"
                htmlFor={`c-accordion-${c.id}`}
              >
                <div className="c-accordion-label-strings">{c.name}</div>
              </label>
              <div className="c-group--part_category-list c-accordion-content">
                <div className="c-list--equal" data-list_column="2">
                  {c.sub_categories.map(s => {
                    const ignore_sub_categoriy_ids = [1, 2, 23, 25, 26, 27, 28, 29, 30, 40, 3, 4, 5, 6, 15, 16];
                    if (!(ignore_sub_categoriy_ids.includes(s.id))) {
                      return (
                        <PartCell
                          {...props}
                          key={s.id}
                          id={s.id}
                          subCategory={s.name}
                          imageUrl={
                            s.selected_part.images
                              ? s.selected_part.images.length > 0
                                ? s.selected_part.images[0]
                                : ""
                              : ""
                          }
                          materialName={s.selected_part.material_name}
                          description={s.selected_part.note}
                          priceDifference={s.selected_part.price_difference}
                          isDefault={s.selected_part.is_default}
                          state={
                            s.selected_part.is_change &&
                            !s.selected_part.is_default
                              ? PartState.changed
                              : PartState.default
                          }
                          hasComment={s.selected_part.memo ? true : false}
                          onClick={() =>
                            props.history.push(
                              `/part/list${buildPlanQueryParam()}&sub_category_id=${
                                s.id
                              }&scroll_id=${s.id}`
                            )
                          }
                        />
                      );
                    };
                  })}
                </div>
              </div>
            </div>
          );
          }
        })}
      </div>
      <div className="c-layout-child">
        <div className="c-group--calc">
          <div
            className="c-calc--static"
            data-state={priceDiffState(resultPlan.total_price_difference)}
          >
            <hr className="c-separator" style={{ marginBottom: "5px" }} />
            {(showThePrice(getSession()) && !eventVersionPriceDisplay)
              ? <PartHomeFooterPriceDiff
                  is_float={false}
                  dispatcherPlan={dispatcherPlan}
                  plan_id={resultPlan.id}
                  square_meter={resultPlan.square_meter}
                  total_price_difference={resultPlan.total_price_difference}
                />
              : <React.Fragment></React.Fragment>
            }
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

const PartHomeFooterPriceDiff = (props: {
  is_float: boolean;
  dispatcherPlan: Dispatch<IPlanAction>;
  plan_id: number;
  square_meter: number;
  total_price_difference: number;
}) => {
  const updateSquareMeter = async (value: number) => {
    // プラン平米更新
    const param: IUpdatePlanSquareMeterParam = {
      id: props.plan_id,
      square_meter: value
    };
    // 平米、部材差分表示
    await new ApiClient().updatePlanSquareMeter(param);
    // 再取得
    const responsePlan = await new ApiClient().getPlan(param.id);
    props.dispatcherPlan({
      type: ActionNamePlan.setPlan,
      fetchedPlan: responsePlan
    });
  };

  return (
    <div>
      <div
        className={
          props.is_float ? `c-calc--float-title` : `c-calc--static-title`
        }
      >
        標準パーツからの増減額
      </div>
      <div
        className={
          props.is_float ? `c-calc--float-form` : `c-calc--static-form`
        }
      >
        <div
          className={
            props.is_float
              ? `c-calc--float-form-area_select`
              : `c-calc--static-form-area_select`
          }
          style={{ marginTop: props.is_float ? "8px" : "0px" }}
        >
          <SelectSquareMeter
            renderPlanDeleteDialog={false}
            onChange={e => {
              updateSquareMeter(Number(e.target.value));
            }}
          />
        </div>
        <div
          className={
            props.is_float
              ? `c-calc--float-form-result`
              : `c-calc--static-form-result`
          }
          style={{ marginTop: props.is_float ? "0px" : "16px" }}
        >
          <div
            className="c-calc--float-form-result-guide"
            style={{ flexBasis: "100vw" }}
          />
          <div className="c-calc--float-form-result--up">Up</div>
          <div className="c-calc--float-form-result--down">Down</div>
          <div
            className={
              props.is_float
                ? `c-calc--float-form-result--flat`
                : `c-calc--static-form-result--flat`
            }
          >
            変更なし
          </div>
          <b>{parsePriceFormat(props.total_price_difference)}</b>
        </div>
      </div>
    </div>
  );
};

const PartHomeFooter = (props: AllProps) => {
  const [showBackConfirm, setShowBackConfirm] = useState(false);
  const dispatcherPlan = useDispatch<Dispatch<IPlanAction>>();
  const planSelector = (state: any) => state.planReducer.fetchedPlan;
  const resultPlan: IPlan = useSelector(planSelector);
  if (resultPlan.status == "locked") {
    props.history.push(`/share/detail?publish_key=${resultPlan.publish_key}`);
  }

  async function whereToGoBack () {
    const session = getSession();
    const visualizationFlg = await new ApiClient().getVisualizationFuncStatus(resultPlan.taste.id);
    if (visualizationFlg.status && session && session.visualization_func_status) {
      props.history.push(`/visualization${buildPlanQueryParam()}`);
    } else {
      const publishKey = resultPlan.publish_key;
      const select_case_ids = (session && session.select_case_ids !== "") ? session.select_case_ids : "0";
      const base_taste_id = (session && session.base_taste_id !== 0) ? session.base_taste_id : (resultPlan.taste.id !== null ? resultPlan.taste.id : 0);
      await new ApiClient().tasteReset(resultPlan.publish_key);
      let url: string = session ? session.from_where : `/taste/recommend?select_case_ids=${select_case_ids}&publish_key=${publishKey}`;
      if (url === "" || url === null || url === undefined) {
        if (base_taste_id !== 0) {
          url =`/taste/detail/${base_taste_id}/?select_case_ids=${select_case_ids}`;
        } else {
          url =`/taste/list/?recommends=0&select_case_ids=${select_case_ids}`;
        }
      }
      props.history.push(url);
    }
  }

  return (
    <div className="c-footer">
      <Divider />
      {showBackConfirm &&
        (resultPlan.status === "locked" ? (
          <ConfirmLockedDialog
            onOk={async () => {
              await newPlan(resultPlan.id, props, dispatcherPlan);
              setShowBackConfirm(false);
            }}
            onCancel={() => setShowBackConfirm(false)}
          />
        ) : (
          <ConfirmNonLockedDialog
            onNewPlan={async () => {
              await newPlan(resultPlan.id, props, dispatcherPlan);
              setShowBackConfirm(false);
            }}
            onReset={async () => {
              await resetPlan(props);
              setShowBackConfirm(false);
            }}
            onCancel={() => setShowBackConfirm(false)}
          />
        ))}
      <div className="c-footer-buttons">
        <div
          className="c-footer-buttons-sub c-button c-button-back"
          onClick={() => whereToGoBack()}
        >
          <span className="c-button-strings">戻る</span>
        </div>
        <div
          onClick={async () =>
            submitSelectPart(props, dispatcherPlan, resultPlan)
          }
          className="c-footer-buttons-main c-button"
        >
          <div className="c-button-strings">作成したプランを保存</div>
        </div>
      </div>
    </div>
  );
};

const submitSelectPart = async (
  props: AllProps,
  dispatch: Dispatch<IPlanAction>,
  plan: IPlan
) => {
  if (plan.status !== "locked") {
    await new ApiClient().selectPart(parsePublishKey());
    props.history.push(`/share/insert${buildPlanQueryParam()}`);
    return;
  }
  // ロック時
  props.history.push(`/share/insert${buildPlanQueryParam()}`);
};

const PartHomeFloatingPriceDiff = (props: {
  dispatcherPlan: Dispatch<IPlanAction>;
  plan_id: number;
  square_meter: number;
  total_price_difference: number;
}) => {
  const [showFloatingPriceDiff, hideFloatingPriceDiff] = useState(true);

  const handleScroll = () => {
    // 最下部に到達したら非表示
    const scrollBottom =
      window.document.body.scrollHeight - window.document.body.clientHeight;
    const hide = scrollBottom - 100 < window.pageYOffset ? false : true;
    hideFloatingPriceDiff(hide);
  };

  // スクロール位置判定
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    // cleanUp
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div
      className="c-calc--float"
      style={{
        zIndex: 1,
        opacity: showFloatingPriceDiff ? 1 : 0,
        display: showFloatingPriceDiff ? "block" : "none",
        maxWidth: "375px",
        marginLeft: "auto",
        marginRight: "auto"
      }}
      data-state={priceDiffState(props.total_price_difference)}
    >
    {(showThePrice(getSession()) && !eventVersionPriceDisplay)
      ? <PartHomeFooterPriceDiff
          is_float={true}
          dispatcherPlan={props.dispatcherPlan}
          plan_id={props.plan_id}
          square_meter={props.square_meter}
          total_price_difference={props.total_price_difference}
        />
      : <React.Fragment></React.Fragment>
    }
    </div>
  );
};
const onCreatePlanCancel = () => {};
const onCreatePlanComplete = (plan: IPlan, router: RouteComponentProps) => {
   // テイスト提案画面へ
  const session = getSession();
  setSession({
    plan_id: plan.id || 0,
    publish_key: plan.publish_key || "",
    login_key: session ? session.login_key : "",
    email: session ? session.email : "",
    select_case_ids: "",
    base_taste_id: 0,
    visualization_func_status: session ? session.visualization_func_status : false,
    accepted: session ? session.accepted : false,
    matter_type: session ? session.matter_type : 0,
    show_reset_part_confirm: false,
    select_square_meter: 40,
    from_where: session ? session.from_where : ""
  });
  router.history.push(`/case/insert?publish_key=${plan.publish_key}`);
};

const PartHome = (props: AllProps) => {
  const dispatcher = useDispatch<Dispatch<IPlanAction>>();
  const planSelector = (state: any) => state.planReducer.fetchedPlan;
  const resultPlan: IPlan = useSelector(planSelector);
  if (resultPlan.status == "locked") {
    props.history.push(`/share/detail?publish_key=${resultPlan.publish_key}`);
  }

  // ドロワー開閉状態
  const showDrawerSelector = (state: any) => state.planReducer.showDrawer;
  const showDrawer: boolean = useSelector(showDrawerSelector);

  const [loading, setLoading] = useState(false);
  if (loading) {
    return <Loading />;
  }
  return (
    <div className="t-part-home">
      <div className="c-layout">
        <div className="c-layout-child">
          <PartHomeHeader {...props} />
          <PartHomeContent {...props} />
        </div>
        <div className="c-layout-child">
          <PartHomeFooter {...props} />
        </div>
      </div>
      {!showDrawer && (
        <PlanDeleteDialog
          onCancel={onCreatePlanCancel}
          onCreate={onCreatePlanComplete}
          onDelete={() => {
            setLoading(true);
            actionDuplicateToTasteRecommend(resultPlan.id, dispatcher, props);
          }}
        />
      )}
    </div>
  );
};

export default withRouter(PartHome);
