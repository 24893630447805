import { IMaintenace } from "../../models/maintenance";
import { host, IRequest } from "./core";

export const buildMaintenancesRequest = (): IRequest<IMaintenace> => {
  return {
    url: `${host}/api/maintenances`,
    options: {
      method: "get",
      resolveWithFullResponse: true,
      json: true
    }
  };
};
