import { ISetting } from "../../models/setting";
import { host, IRequest } from "./core";

export const buildSettingsRequest = (): IRequest<ISetting> => {
  return {
    url: `${host}/api/settings`,
    options: {
      method: "get",
      resolveWithFullResponse: true,
      json: true
    }
  };
};
