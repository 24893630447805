import { getCurrentEnv } from "../env";

export interface AwsConfiguration {
    region: string;
    identityPoolId: string;
    bucket: string;
    cloudfront: string;
  }

export const S3Config = (): AwsConfiguration => {
  switch (getCurrentEnv()) {
    case "production":
      return {
        region: "ap-northeast-1",
        identityPoolId: "ap-northeast-1:bc531cb8-6dfc-4d7b-922e-a55551883184",
        bucket: "sugata-v2-production",
        cloudfront: "https://d1xdvtz9yp8g6d.cloudfront.net/"
      };
    default:
      return {
        region: "ap-northeast-1",
        identityPoolId: "ap-northeast-1:0980272a-c14c-44e3-bfb4-a02f05491bb1",
        bucket: "sugata-v2-staging",
        cloudfront: "https://d2xcywsffwvuc0.cloudfront.net/"
      };
  }
};

// S3ファイル名に付与するハッシュ値の最大LENGTH
export const S3_FILENAME_HASH_LENGTH = 32;