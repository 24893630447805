import * as React from "react";
import { useState } from "react";
import { IPart, ISubCategories } from "./index";
import { IPlan } from "../../models/plan";
import "./style.css";

interface IBaseImage {
  parts: IPart[][];
  plan: IPlan;
  subCategories : ISubCategories[];
}

const removeNowLoadingClass = () => {
  const headerLink: HTMLElement = document.getElementById('header-save-user-made-image-btn') as HTMLElement;
  if (headerLink !== null && headerLink !== undefined) {
    headerLink.classList.remove("now-loading", "no-side-image");
  }
  const partsArea: HTMLElement = document.getElementById('visualization-content-parts-area') as HTMLElement;
  if (partsArea !== null && partsArea !== undefined) {
    partsArea.classList.remove("now-loading", "no-side-image");
  }
  const footerBtn: HTMLElement = document.getElementById('footer-save-user-made-image-btn') as HTMLElement;
  if (footerBtn !== null && footerBtn !== undefined) {
    footerBtn.classList.remove("now-loading", "no-side-image");
  }
}

const checkNoSideImageClassContain = () => {
  let result = false;
  const headerLink: HTMLElement = document.getElementById('header-save-user-made-image-btn') as HTMLElement;
  if ((headerLink !== null && headerLink !== undefined) && headerLink.classList.contains("no-side-image")) {
    result = true;
  }
  const partsArea: HTMLElement = document.getElementById('visualization-content-parts-area') as HTMLElement;
  if ((partsArea !== null && partsArea !== undefined) && partsArea.classList.contains("no-side-image")) {
    result = true;
  }
  const footerBtn: HTMLElement = document.getElementById('footer-save-user-made-image-btn') as HTMLElement;
  if ((footerBtn !== null && footerBtn !== undefined) && footerBtn.classList.contains("no-side-image")) {
    result = true;
  }
  return result;
}

let loadedImageCount: number = 0;
let loadedAllImagesFlg: boolean = false;
const loadedImageCheck = async () => {
  loadedImageCount += 1;
  if (loadedAllImagesFlg) {
    if (await checkNoSideImageClassContain()) {
      if (loadedImageCount === 1) {
        await removeNowLoadingClass();
        loadedImageCount = 0;
      }
    } else {
      if (loadedImageCount === 2) {
        await removeNowLoadingClass();
        loadedImageCount = 0;
      }
    }
  } else {
    if (loadedImageCount === 13) {
      await removeNowLoadingClass();
      loadedImageCount = 0;
      loadedAllImagesFlg = true;
    }
  }
}

let loadedBaseImageComponentCount: number = 0;
const BaseImage = (props: IBaseImage) => {
  loadedImageCount = 0;
  loadedBaseImageComponentCount += 1;

  const {parts, plan, subCategories} = props;
  const [toggle, setToggle] = useState<boolean>(false);
  const changeToggle = (open:boolean, id:number) => {
    if ((id === 1 && toggle) || (id === 2 && !toggle)) {
      setToggle(!open);
      removeNowLoadingClass();
      loadedImageCount = 0;
    }
  }
  const view1Class: string = toggle ? 'my-image-box toggle' : 'my-image-box';
  const view2Class: string = !toggle ? 'my-image-box toggle' : 'my-image-box';

  return (
    <div className="c-group--visualization_all c-nav_bar--light navigation-header" >
      <div className="col-md-8 mb-2" id="my-box">

        {/** レイヤー並び順（1:最背面～8:最前面）
         * 1. 戸境壁（壁(左)）
         * 2. アクセント壁（壁(右)）
         * 3. ベース壁（壁(正面)）
         * 4. 天井
         * 5. ベースイメージ
         * 6. レンジフード
         * 7. スポットライト（※登録されている場合のみ）
         * 8. カーテン（※登録されている場合のみ・サイドビューのみ）
         */}

        <canvas id="frontCanvas" className="canvas-layer">
          {/* フロントビュー描写 */}
        </canvas>
        <canvas id="sideCanvas" className="canvas-layer">
          {/* サイドビュー描写 */}
        </canvas>

        {/* フロントビュー生成 */}
        <div className={view1Class} id='view-c01' onClick={() => {changeToggle(toggle, 1)}} >
          {/* 各種パースイメージ：正面 */}
          {subCategories.map(sc => {
            return (
              <div className={`layer front-view perse-image-${sc.id}`} key={sc.id}>
                {parts[Number(sc.id)] && <img src={(parts[Number(sc.id)].filter(p => p.is_checked && p.image_url_01 != null && p.image_url_01 != '').length === 1) ? parts[Number(sc.id)].filter(p => p.is_checked)[0].image_url_01 : require("./bg.png")} alt={`img-layer${sc.layerNo}`} id={`img-layer${sc.layerNo}`} className="img-fluid" onLoad={loadedImageCheck}/>}
              </div>
              )
            })
          }
          {/* ベースイメージ：正面 */}
          <div className='layer front-view base-image'>
            <img src={(plan.taste != null && plan.taste.base_images != null && plan.taste.base_images.image_url_01 != null && plan.taste.base_images.image_url_01 != '') ? plan.taste.base_images.image_url_01 : require("./bg.png")} alt={`img-layer${subCategories.length}`} id={`img-layer${subCategories.length}`} className="img-fluid" onLoad={loadedImageCheck}/>
          </div>
          {/* レンジフード：正面 */}
          <div className='layer front-view range_hood'>
            <img src={(plan.taste != null && plan.taste.base_images != null && plan.taste.base_images.range_hood_url_01 != null && plan.taste.base_images.range_hood_url_01 != '') ? plan.taste.base_images.range_hood_url_01 : require("./bg.png")} alt={`img-layer${subCategories.length + 1}`} id={`img-layer${subCategories.length + 1}`} className="img-fluid" onLoad={loadedImageCheck}/>
          </div>
          {/* スポットライト：正面 */}
          <div className='layer front-view spotlight'>
            <img src={(plan.taste != null && plan.taste.base_images != null && plan.taste.base_images.spotlight_url_01 != null && plan.taste.base_images.spotlight_url_01 != '') ? plan.taste.base_images.spotlight_url_01 : require("./bg.png")} alt={`img-layer${subCategories.length + 2}`} id={`img-layer${subCategories.length + 2}`} className="img-fluid" onLoad={loadedImageCheck}/>
          </div>
          {/* フロントビュー→サイドビュー切替用ウィンドウ */}
          <div id='icon-on-picture-1' className='icon-on-picture js-modal-open'>
            <i className="fa fa-search-plus" aria-hidden="true"></i>
          </div>
        </div>

        {/* サイドビュー生成 */}
        <div className={view2Class} id='view-c02' onClick={() => {changeToggle(toggle, 2)}} >
          {/* 各種パースイメージ：側面 */}
          {subCategories.map(sc => {
            return (
              <div className={`layer side-view perse-image-${sc.id}`} key={sc.id}>
                {parts[Number(sc.id)] && <img src={(parts[Number(sc.id)].filter(p => p.is_checked && p.image_url_02 != null && p.image_url_02 != '').length === 1) ? parts[Number(sc.id)].filter(p => p.is_checked)[0].image_url_02 : require("./bg.png")} alt={`img2-layer${sc.layerNo}`} id={`img2-layer${sc.layerNo}`} className="img-fluid" onLoad={loadedImageCheck}/>}
              </div>
              )
            })
          }
          {/* ベースイメージ：側面 */}
          <div className='layer side-view base-image'>
            <img src={(plan.taste != null && plan.taste.base_images != null && plan.taste.base_images.image_url_02 != null && plan.taste.base_images.image_url_02 != '') ? plan.taste.base_images.image_url_02 : require("./bg.png")} alt={`img2-layer${subCategories.length}`} id={`img2-layer${subCategories.length}`} className="img-fluid" onLoad={loadedImageCheck}/>
          </div>
          {/* 天井造作：側面 */}
          <div className='layer side-view range_hood'>
            <img src={(plan.taste != null && plan.taste.base_images != null && plan.taste.base_images.range_hood_url_02 != null && plan.taste.base_images.range_hood_url_02 != '') ? plan.taste.base_images.range_hood_url_02 : require("./bg.png")} alt={`img2-layer${subCategories.length + 1}`} id={`img2-layer${subCategories.length + 1}`} className="img-fluid" onLoad={loadedImageCheck}/>
          </div>
          {/* スポットライト：側面 */}
          <div className='layer side-view spotlight'>
            <img src={(plan.taste != null && plan.taste.base_images != null && plan.taste.base_images.spotlight_url_02 != null && plan.taste.base_images.spotlight_url_02 != '') ? plan.taste.base_images.spotlight_url_02 : require("./bg.png")} alt={`img2-layer${subCategories.length + 2}`} id={`img2-layer${subCategories.length + 2}`} className="img-fluid" onLoad={loadedImageCheck}/>
          </div>
          {/* 家具：側面 */}
          <div className='layer side-view curtain'>
            <img src={(plan.taste != null && plan.taste.base_images != null && plan.taste.base_images.curtain_url != null && plan.taste.base_images.curtain_url != '') ? plan.taste.base_images.curtain_url : require("./bg.png")} alt={`img2-layer${subCategories.length + 3}`} id={`img2-layer${subCategories.length + 3}`} className="img-fluid" onLoad={loadedImageCheck}/>
          </div>
          {/* 家具：正面 */}
          <div className='layer side-view curtain'>
            <img src={(plan.taste != null && plan.taste.base_images != null && plan.taste.base_images.curtain_url_02 != null && plan.taste.base_images.curtain_url_02 != '') ? plan.taste.base_images.curtain_url_02 : require("./bg.png")} alt={`img2-layer${subCategories.length + 3}`} id={`img2-layer${subCategories.length + 3}`} className="img-fluid" onLoad={loadedImageCheck}/>
          </div>
          {/* サイドビュー→フロントビュー切替用ウィンドウ */}
          <div id='icon-on-picture-2' className='icon-on-picture js-modal-open'>
            <i className="fa fa-search-plus" aria-hidden="true"></i>
          </div>
        </div>

      </div>
    </div>
  );
};
export default BaseImage;