import $ from 'jquery';
$(function () {
  var scrollpos;
  var openModalNum = 0;

  const closeModalNum = () => {
    openModalNum = parseInt(openModalNum) - 1;
    openModalNum = openModalNum < 1 ? 0 : openModalNum;
  }

  const closeModalFunc = (elm) => {
    $(elm).attr('data-modal', '');
    closeModalNum();
    if (!openModalNum >= 1) {
      $('html, body').css({ 'position': '', 'top': '', 'left': '', 'right': '' });
    }
    window.scrollTo({ top: scrollpos });
  }

  $(document).on('click', '.c-modal--half', function (e) {
    if (!$(e.target).closest('.c-modal-inwrapper-content').length) {
      closeModalFunc(this);
    }
    return false;
  });

  $(document).on("click", ".c-modal_toggle", function (e) {
    if (e.target.classList.contains('modal-ignore')) return true
    var target = $(this).data('modal-target');
    var modal = document.getElementById(target);
    if (!modal) return true;
    if (openModalNum < 1) {
      scrollpos = $(window).scrollTop();
    }
    $(modal).attr('data-modal', 'active');
    $(modal).show();
    $('html, body').css({ 'position': 'fixed', 'top': '-' + scrollpos + 'px', 'left': '0', 'right': '0' });
    if ($(document).find('[data-modal="active"]')) {
      openModalNum = parseInt(openModalNum) + 1;
    }

    $(document).on("click", ".c-modal_close", function (e) {
      closeModalFunc($(this).closest('.c-modal, .c-modal--half'));
      return true;
    });
  });
});
window.onpopstate = function (event) {
  $('html, body').css({ 'position': '', 'top': '', 'left': '', 'right': '' });
};