import { Action, Reducer } from "redux";
import { ISetting } from "../models/setting";

export interface ISettingState {
  fetchSetting: ISetting;
}

const initialState: ISettingState = {
  fetchSetting: { fixed_cost: 0, base_price_area: 0 },
};

export enum ActionName {
  setSetting = "setting/setSetting",
}

interface ISetSeting extends Action<ActionName.setSetting> {
  fetchSetting: ISetting;
}

export type ISettingAction = ISetSeting;

export const settingReducer: Reducer<ISettingState | undefined, ISettingAction> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case ActionName.setSetting: {
      return { ...state, fetchSetting: action.fetchSetting };
    }
  }
  return state;
};
