import * as React from "react";
import ColorThumbnail from "./color_thumbnails";
import {IPart, ISubCategories} from "./index";
import "./style.css";

interface Iprops{
  parts: IPart[][];
  subCategories: ISubCategories[];
  changeCheck: (id: number, subCategoryId: number) => void
}

const VisualizationContent = (props: Iprops) => {
  const {parts, subCategories, changeCheck} = props;

  /* アコーディオン（サムネイル選択エリア）生成 */
  return (
    <div id="visualization-content-parts-area" className="col-md-4 parts-area now-loading">
      {subCategories.map(sc => {
        return (
          <div className="c-accordion" key={sc.id}>
            <input
              className="c-accordion-toggler"
              type="checkbox"
              defaultChecked={true}
              id={`c-accordion-${sc.id}`}
            />
            {/* アコーディオンタイトル（中カテゴリ名） */}
            <label className="c-accordion-label" htmlFor={`c-accordion-${sc.id}`}>
              <div className="c-accordion-label-strings">{sc.name}</div>
            </label>
            {/* アコーディオン内：サムネイル選択エリア */}
            <div className="c-group--visualization-color-list c-accordion-content" style={{padding: "8px 16px"}}>
              <div className="c-list--equal" >
                {parts[sc.id] && parts[sc.id].map((p, index) => {
                  // サムネイル
                  return(
                    <ColorThumbnail
                      key={`${p.sub_category_id}${p.part_variation_id}${index}`}
                      id={p.part_variation_id}
                      subCategoryId={p.sub_category_id}
                      panel_url={p.panel_url}
                      checked={p.is_checked}
                      changeCheck={changeCheck}
                    />
                  );
                })}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};
export default VisualizationContent;