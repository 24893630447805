import * as React from "react";
import LazyLoad from "react-lazyload";

const CaseDetailNav = (props: { onClose: (e: any) => void }) => {
  const { onClose } = props;
  return (
    <React.Fragment>
      <div className="c-nav_bar">
        <div className="c-nav_bar-left">
          <div
            className="c-button--circle u-fc--on_dark-quatiary c-modal_close"
            onClick={onClose}
          >
            <i className="u-tc--on_light-primary material-icons">close</i>
          </div>
        </div>
        <div className="c-nav_bar-center" />
        <div className="c-nav_bar-right" />
      </div>
    </React.Fragment>
  );
};

const CaseDetailsFooter = (props: {
  isSelected: boolean;
  onClick: (e: any) => void;
}) => {
  const { isSelected, onClick } = props;
  return (
    <div className="c-footer">
      <div className="c-footer-buttons">
        <div
          className={
            isSelected
              ? "c-footer-buttons-main c-button u-fc--danger u-tc--on_danger c_modal_close"
              : "c-footer-buttons-main c-button c-modal_close"
          }
          onClick={onClick}
        >
          <span className="c-button-strings c-modal_close">
            {isSelected ? "選択を解除する" : "このイメージを選択"}
          </span>
          <i className="material-icons c-modal_close">
            {isSelected ? "close" : "check"}
          </i>
        </div>
      </div>
    </div>
  );
};

export interface IOuterProps {
  imageUrl: string;
  isSelected: boolean;
  onSelect: (e: any) => void;
  onClose: (e: any) => void;
  loaded?: boolean;
}

const CaseDetailUI = (props: IOuterProps) => {
  const { isSelected, imageUrl, onSelect, loaded, onClose } = props;
  return (
    <div className="c-modal-inwrapper">
      <div className="c-modal-inwrapper-content">
        <CaseDetailNav onClose={onClose} />
        <div className="c-layout">
          <div className="c-layout-child">
            <div className="c-thumbnail">
              {loaded ? (
                <img src={imageUrl} alt="" />
              ) : (
                <LazyLoad height={200} once={true}>
                  <img src={imageUrl} alt="" />
                </LazyLoad>
              )}
            </div>
          </div>
          <div className="c-layout-child">
            <CaseDetailsFooter isSelected={isSelected} onClick={onSelect} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CaseDetailUI;
