import * as React from "react";
import { useLayoutEffect, useState } from "react";
import { Dispatch } from "redux";
import { CaseImageModal } from ".";
import ICase from "../../../models/case";
import { ActionName, ICaseAction } from "../../../redux/case";
import CaseCell from "./case_cell";

interface IOuterProps {
  ids: number[];
  cases: ICase[];
  isReadable: boolean;
  // onSelect: (c: ICaseImageSelect) => void;
  onSelect?: (e: any, c: ICase) => void;
  onModalClose: (e: any) => void;
  onModalSelect: (e: any) => void;
  isModalConfirm: boolean;
  forceUpdate: any;
  dispatch: Dispatch<ICaseAction>;
}

type AllProps = IOuterProps;

const CaseImageList = (props: AllProps) => {
  const [listHeight, setListHeight] = useState("auto");
  const [visibleList, setVisibleList] = useState(false);
  const [loadedImages, setLoadedImages] = useState<string[]>([]);

  // Masonry画像描画(Z順)
  const handleMasonryImage = () => {
    setTimeout(() => {
      const list =
        window.document.getElementById("case-list-pinterest") || undefined;
      if (list) {
        setListHeight(`${list.clientHeight}px`);
        // lazyload再描画
        setTimeout(() => {
          window.scrollTo(0, 10);
          window.scrollTo(0, 0);
        }, 100);
        // 事例一覧描画
        setVisibleList(true);
      }
    }, 50);
  };

  useLayoutEffect(() => {
    handleMasonryImage();
  }, []);

  const buildMasonryImage = () => {
    const list =
      window.document.getElementById("case-list-pinterest") || undefined;
    if (list) {
      // 左列の高さ合計
      const oddsHeight = Array.prototype.slice
        .call(
          document.querySelectorAll(
            "#case-list-pinterest .c-list-item:nth-child(odd)"
          )
        )
        .reduce(
          (prev, current) => {
            return { clientHeight: prev.clientHeight + current.clientHeight };
          },
          { clientHeight: 0 }
        );

      // 右列の高さ合計
      const evensHeight = Array.prototype.slice
        .call(
          document.querySelectorAll(
            "#case-list-pinterest .c-list-item:nth-child(even)"
          )
        )
        .reduce(
          (prev, current, i, arr) => {
            return { clientHeight: prev.clientHeight + current.clientHeight };
          },
          { clientHeight: 0 }
        );

      // 高い方
      const higher =
        oddsHeight.clientHeight > evensHeight.clientHeight
          ? oddsHeight.clientHeight
          : evensHeight.clientHeight;

      const bottom = 100;
      if (higher + bottom !== list.clientHeight) {
        setListHeight(`${higher + bottom}px`);
      }
    }
  };

  return (
    <div className="c-group--case_list">
      <div
        className="c-list--pinterest"
        id="case-list-pinterest"
        data-list_column="2"
        style={{
          height: listHeight,
          visibility: visibleList ? "visible" : "hidden"
        }}
      >
        {props.cases ? (
          props.cases.map(c => {
            return (
              <CaseCell
                key={c.id}
                id={c.id}
                isReadable={props.isReadable}
                imageUrl={c.image_url}
                selected={props.ids.includes(c.id)}
                onSelect={(e: any) => props.onSelect && props.onSelect(e, c)}
                onLoaded={async (imageUrl: string | undefined) => {
                  const images = loadedImages;
                  if (imageUrl) {
                    buildMasonryImage();
                    if (!images.includes(imageUrl)) {
                      images.push(imageUrl);
                      props.forceUpdate(0);
                    }
                    setLoadedImages(images);
                  }
                }}
              />
            );
          })
        ) : (
          <></>
        )}
      </div>
      {props.cases &&
        props.cases.map(c => {
          return (
            <CaseImageModal
              key={c.id}
              currentCaseId={c.id}
              imageUrl={c.image_url}
              isConfirm={props.isModalConfirm}
              onSelect={(e: any) => {
                if (props.ids.includes(c.id)) {
                  const index = props.ids.indexOf(c.id, 0);
                  if (index > -1) {
                    props.ids.splice(index, 1);
                  }
                } else {
                  props.ids.push(c.id);
                }
                props.dispatch({
                  type: ActionName.addSelectedCaseId,
                  selectedCaseIds: props.ids
                });
                e.preventDefault();
                props.forceUpdate(e);
                props.onModalSelect(e);
                // sendPageViewCaseInsert();
              }}
              onClose={(e: any) => {
                props.onModalClose(e);
                // sendPageViewCaseInsert();
              }}
              loaded={loadedImages.includes(c.image_url)}
            />
          );
        })}
    </div>
  );
};

export default CaseImageList;
