import * as AWS from 'aws-sdk';
import { S3 } from 'aws-sdk';
import { S3Config, S3_FILENAME_HASH_LENGTH } from '../config/aws_config';

// 以下でないとimportができない
const Hashids = require('hashids').default;

export interface IUploadObject {
  key: string;
  body: string | object;
}

export class ImageUploader {

  private client: S3;
  private hashids: any;

  constructor() {
    const s3Config = S3Config();
    AWS.config.region = s3Config.region;
    AWS.config.credentials = new AWS.CognitoIdentityCredentials({
      IdentityPoolId: s3Config.identityPoolId
    });

    this.client = new AWS.S3({ params: { Bucket: s3Config.bucket } });
    this.hashids = new Hashids(`sugata-v2-${process.env.RAILS_ENV}`, S3_FILENAME_HASH_LENGTH);
  }

  async upload(object: IUploadObject): Promise<AWS.S3.ManagedUpload.SendData> {

    const body: Blob | any = (() => {
      if (typeof object.body === 'string') {
        return this.toBlob(object.body);
      } else if (typeof object.body === 'object') {
        return object.body as Blob;
      }
      throw new Error('想定外のファイル形式です');
    })();

    const params: AWS.S3.Types.PutObjectRequest = {
      Bucket: S3Config().bucket,
      Key: object.key,
      Body: body
    };

    const options: AWS.S3.ManagedUpload.ManagedUploadOptions = {
      params: params
    };

    const handler = new AWS.S3.ManagedUpload(options);
    handler.send((err: AWS.AWSError, data: AWS.S3.ManagedUpload.SendData) => {
      if (err) {
        throw new Error(err.message);
      }
    });
    return handler.promise();
  }

  generateHash(): string {
    const t: number = new Date().getTime();
    return this.hashids.encode(t);
  }

  // utility
  private toBlob(base64: string): Blob | null {
    const bin: string = atob(base64.replace(/^.*,/, ''));
    const buffer = new Uint8Array(bin.length);
    for (let i = 0; i < bin.length; i++) {
      buffer[i] = bin.charCodeAt(i);
    }
    try {
      let blob = new Blob([buffer.buffer], {
        type: 'image/jpeg'
      });
      return blob;
    } catch (error) {
      return null;
    }
  }
}
