import React from "react";
import { Redirect, Route } from "react-router-dom";
import { authRedirectUrl } from "../../util";
import { getSession } from "../../session";

type AllProps = React.Props<{}>;
class Auth extends React.Component<{}, AllProps> {
  public render() {
    return this.authenticate() ? (
      <Route children={this.props.children} />
    ) : (
      <Redirect to={authRedirectUrl()} />
    );
  }
  public authenticate = () => {
    const session = getSession();
    if (session && session.login_key.length > 0) {
      return true;
    }
    alert("ログインして下さい。");
    return false;
  };
}
export default Auth;
