import request from "request-promise-native";
import { Env, getCurrentEnv } from "../../env";
import { getSession } from "../../session";

const DEFAULT_TIMEOUT = 10000;
const getHost = (): string => {

  const env = getCurrentEnv();
  switch (env) {
    case Env.production: {
      return "https://sugata-v2-admin.renoveru-tech.jp";
    }
    case Env.staging: {
      return "https://sugata-v2-admin-staging.renoveru-tech.jp";
    }
    case Env.devtest: {
      return "https://sugata-v2-admin-test.renoveru-tech.jp";
    }
    default: {
      if (process.env.REACT_APP_LOCAL_ENDPOINT === "on") {
        return "http://localhost:3000";
      } else {
        return "https://sugata-v2-admin-develop.renoveru-tech.jp";
      }
    }
  }
};

export enum HttpStatusCode {
  REQUEST_INVALID = 400,
  USER_INVALID = 401,
  USER_NOT_FOUND = 404,
  DUPLICATE_TASTE_FIXED = 452,
}

export const host = getHost();

export interface IRequest<IResponse> {
  url: string;
  options: request.RequestPromiseOptions;
}

export const execRequest = async <IResponse>(
  req: IRequest<IResponse>,
  headers?: {}
): Promise<IResponse> => {
  const session = getSession();
  req.options.headers = headers || {
    "Login-Key": session ? session.login_key : ""
  };

  const options: request.RequestPromiseOptions = {
    ...req.options,
    timeout: DEFAULT_TIMEOUT,
    transform2xxOnly: true,
  };
  const res: request.FullResponse = await request(
    req.url,
    options,
    (error, response, body) => {
      // 通信エラーの場合そのまま戻す
      if (response == null) {
        return;
      }
      if (
        response.statusCode >= 300 &&
        response.statusCode !== HttpStatusCode.USER_NOT_FOUND &&
        response.statusCode !== HttpStatusCode.USER_INVALID &&
        response.statusCode !== HttpStatusCode.DUPLICATE_TASTE_FIXED &&
        response.statusCode !== HttpStatusCode.REQUEST_INVALID
      ) {
        console.error(response);
        throw new Error(response.body.error);
      }
    }
  );

  return res.body as IResponse;
};
