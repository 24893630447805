import * as React from "react";
import { useEffect, useState } from "react";
import Done from "../../../component/alert/done";
import Confirm from "../../../component/confirm";

interface IProps {
  onCopy: () => void;
  onCancel: () => void;
  onComplete: () => void;
  locked: boolean;
}
const ShareDialog = (props: IProps) => {
  const [showConfirmMsg, setConfirmMsg] = useState(true);
  const [showCompleteMsg, setCompleteMsg] = useState(false);

  useEffect(() => {
    // ロックプランならコピー完了メッセージのみ
    if (props.locked) {
      setConfirmMsg(false);
      setCompleteMsg(true);
      return;
    }
    setConfirmMsg(true);
    setCompleteMsg(false);
  }, [props.locked]);

  return (
    <React.Fragment>
      {showConfirmMsg && (
        <div>
          <Confirm
            title="プランURLを発行してもよろしいですか？"
            text="URLを発行するとそのプランを変更できなくなりますが、メニューからプランを複製すれば別のプランとしてカスタマイズすることができます。"
            okText="プランURLを発行する"
            cancelText="キャンセル"
            onClickOk={() => {
              setTimeout(() => {
                props.onCopy();
                setConfirmMsg(false);
                setCompleteMsg(true);
              }, 1000);
            }}
            onClickCancel={() => {
              setConfirmMsg(false);
              props.onCancel();
            }}
          />
        </div>
      )}
      {showCompleteMsg && (
        <Done
          title=""
          text={"URLをクリップボードにコピーしました。"}
          okText="OK"
          onClickOk={(e: any) => {
            setCompleteMsg(false);
            props.onComplete();
          }}
        />
      )}
    </React.Fragment>
  );
};

export default ShareDialog;
