import { ITaste } from "../../models/taste";
import { host, IRequest } from "./core";

export const buildTasteRecommendsRequest = (
  cases: number[],
  publishKey: string
): IRequest<ITaste[]> => {
  return {
    url: `${host}/api/tastes/recommend?${buildArgs(
      cases
    )}&publish_key=${publishKey}`,
    options: {
      method: "get",
      resolveWithFullResponse: true,
      json: true
    }
  };
};

const buildArgs = (cases: number[]): string => {
  return cases.map(n => `cases[]=${n}`).join("&");
};
