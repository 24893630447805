import * as React from "react";
import { useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import Confirm from "../../../component/confirm";
import {
  disablePartResetConfirm,
  isShowPartResetConfirm
} from "../../../session";
import PriceDiff from "../price_diff";

export enum PartState {
  default = "DEFAULT",
  changed = "CHANGED"
}

interface IOuterProps {
  id: number;
  coverImage: string;
  images: string[];
  name: string;
  materialName: string;
  partNumber: string;
  description: string;
  priceDifference: number;
  state: PartState;
  isDefault?: boolean;
  isSelectFromAll?: boolean;
  isChange?: boolean;
  hideForUser?: boolean;
  selected: boolean;
  beforeIsDefault: boolean;
  onSelect: (e: any) => void;
  onClick?: (e: any) => void;
}

type AllProps = RouteComponentProps & IOuterProps;
const PartCell = (props: AllProps) => {
  const [ showResetPartConfirm, setShowResetPartConfirm] = useState(false);
  const [ showResetPartConfirmHide, setShowResetPartConfirmHide] = useState(false);
  return (
    <div className="c-list-item">
      <div
        data-state={props.selected ? "selected" : "unselect"}
        className="c-card c-modal_toggle"
        data-modal-target={`details_modal_${props.id}`}
        style={{ width: "100%" }}
        onClick={props.onClick}
      >
        <div className="c-card-thumbnail">
          <div
            className="c-thumbnail--round_top "
            style={{ width: "100%", height: "110px" }}
            data-object_fit="contain"
          >
            {props.coverImage && (
              <img src={props.coverImage} alt="画像が未設定です" />
            )}
            <div
              className="c-button modal-ignore"
              onClick={e => {
                if (isShowPartResetConfirm() && !props.selected ) {
                  if(props.hideForUser && !props.beforeIsDefault){
                    setShowResetPartConfirmHide(true);
                  }else{
                    setShowResetPartConfirm(true);
                  }
                  return;
                }
                props.onSelect(e);
              }}
            >
              <i className="material-icons modal-ignore">check_circle</i>
            </div>

            {props.isDefault ? (
              <div
                className="c-card-ribbon"
                style={{
                  position: "absolute",
                  bottom: "-1px",
                  left: 0,
                  right: 0
                }}
              >
                <div className="c-ribbon u-fc--tint u-tc--on_tint">
                  標準パーツ
                </div>
              </div>
            ) : null}
            {props.isSelectFromAll ? (
              <div
                className="c-card-ribbon"
                style={{
                  position: "absolute",
                  bottom: "-1px",
                  left: 0,
                  right: 0
                }}
              >
                <div className="c-ribbon u-fc--success u-tc--on_success">
                  全てのパーツから選択
                </div>
              </div>
            ) : null}
          </div>
        </div>
        <div className="c-card-body">
          <div className="c-card-body-title--long">
            {props.materialName}　|　{props.description}　
          </div>
          <PriceDiff
            price={props.priceDifference}
            isDefault={props.isDefault}
          />
        </div>
      </div>
      {showResetPartConfirm && (
          <Confirm
              title="部材を変更すると、以前の選択が上書きされます。変更しますか？"
              text="※他に編集する方や別の端末がある場合は、そちらへの上書きにご注意ください。"
              okText="はい"
              cancelText="キャンセル"
              onClickOk={(e: any) => {
                // state制御:確認ダイアログを非表示
                setShowResetPartConfirm(false);
                // session制御:確認ダイアログを無効
                disablePartResetConfirm();
                // 部材の選択処理
                props.onSelect(e);
              }}
              onClickCancel={() => setShowResetPartConfirm(false)}
          />
      )}
      {showResetPartConfirmHide && (
          <Confirm
              title="部材を変更すると、以前の選択が上書きされます。変更しますか？"
              text="※他に編集する方や別の端末がある場合は、そちらへの上書きにご注意ください。\n※本部材は廃版になりましたので、変更後は表示されません。"
              okText="はい"
              cancelText="キャンセル"
              onClickOk={(e: any) => {
                // state制御:確認ダイアログを非表示
                setShowResetPartConfirmHide(false);
                // session制御:確認ダイアログを無効
                disablePartResetConfirm();
                // 部材の選択処理
                props.onSelect(e);
              }}
              onClickCancel={() => setShowResetPartConfirmHide(false)}
          />
      )}
    </div>
  );
};

export default PartCell;
