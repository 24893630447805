import * as React from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import { Dispatch } from "redux";
import { ApiClient } from "../../api";

import Logo from "../../component/logo";
import renoveruLogo from "../../images/renoveru.svg";
import { IUser } from "../../models/user";
import { ActionName as ActionNamePlan, IPlanAction } from "../../redux/plan";
import { ActionName as ActionNameUser, IUserAction } from "../../redux/user";
import { getSession, setSession } from "../../session";
import { isHouseholdItemsUrl } from "../../util";
import { clearDisplayed } from "../information";

const login = async (
  dispatchUser: Dispatch<IUserAction>,
  dispatchPlan: Dispatch<IPlanAction>,
  email: string
): Promise<IUser> => {
  // try {
  const session = getSession() || {
    select_case_ids: "",
    plan_id: 0,
    publish_key: "",
    login_key: "",
    base_taste_id: 0,
    show_reset_part_confirm: false,
    select_square_meter: 40
  };
  const result = await new ApiClient().login(email, session.publish_key);

  dispatchUser({
    type: ActionNameUser.setUser,
    userState: result
  });

  // plan
  dispatchPlan({
    type: ActionNamePlan.setPlan,
    fetchedPlan: result.plan
  });

  // Sessionに保存
  setSession({
    plan_id: result.plan.id || 0,
    publish_key: result.plan.publish_key || "",
    select_case_ids: session.select_case_ids,
    login_key: result.login_key,
    base_taste_id: session.base_taste_id,
    visualization_func_status: result.visualization_func_status,
    accepted: result.accepted,
    matter_type: result.matter_type_id,
    email,
    show_reset_part_confirm: session.show_reset_part_confirm,
    select_square_meter: session.select_square_meter,
    from_where: ""
  });
  return result;
};

const Top = (props: RouteComponentProps) => {
  const [email, setEmail] = useState("");
  const [invalid, setInvalid] = useState(true);
  const [errorMsg, setErrorMsg] = useState("");
  const [publishKey, setPublishKey] = useState("");
  const dispatchUser = useDispatch<Dispatch<IUserAction>>();
  const dispatchPlan = useDispatch<Dispatch<IPlanAction>>();
  const submitClass = invalid
    ? "t-submit c-button u-fc--tint u-tc--on_tint disabled"
    : "t-submit c-button u-fc--tint u-tc--on_tint";
  const emailClass = errorMsg ? "on_light u-bc--danger" : "on_light";

  return (
    <div className="t-insert">
      <div className="c-layout">
        <div className="c-layout-child">
          <div className="c-group--read">
            <div className="c-group--read-image">
              <Logo />
            </div>
            <div className="c-group--read-small_read">
              リノベるの豊富な事例から、
              <br />
              あなたにオススメのスタイルを提案します。
              <br />
              好みの空間や部材をピックアップし、
              <br />
              理想のお部屋をデザイナーに共有しましょう！
            </div>

            <div className="c-group--read-large_read">
              <div
                className="u-fs--small u-tc--on_light-tertiary"
                style={{ textAlign: "left" }}
              >
                メールアドレス
              </div>
              <div style={{ width: "90vw", maxWidth: "345px" }}>
                <div className="">
                  <div className="c-form-with_overlay">
                    <input
                      type="text"
                      className={emailClass}
                      required={true}
                      value={email}
                      onChange={e => {
                        e.target.validationMessage.length > 0
                          ? setInvalid(true)
                          : setInvalid(false);
                        setEmail(e.target.value);
                      }}
                    />
                  </div>
                </div>
              </div>
              {errorMsg && (
                <div
                  className="u-fs--small u-tc--danger u-bc--danger"
                  style={{ textAlign: "left" }}
                >
                  {errorMsg}
                </div>
              )}
            </div>

            <div className="c-group--read-button">
              <button
                disabled={invalid ? true : false}
                onClick={async () => {
                  const user = await login(
                    dispatchUser,
                    dispatchPlan,
                    email
                  ).catch(e => {
                    setErrorMsg(e.error.error);
                  });
                  if (user) {
                    setErrorMsg("");
                    clearDisplayed();
                    // ログイン後のリダイレクト先
                    if (isHouseholdItemsUrl()) {
                      props.history.push(
                        `/share/detail?publish_key=${user.plan.publish_key}&si=rhi`
                      );
                    } else {
                      switch (user.plan.status) {
                        case "pending":
                          props.history.push(
                            `/case/insert?publish_key=${user.plan.publish_key}&first_login=${user.first_login}`
                          );
                          break;
                        case "edit":
                          setPublishKey(user.plan.publish_key);
                          props.history.push(
                            user.visualization_func_status ? `/visualization?publish_key=${user.plan.publish_key}` : `/part/home?publish_key=${user.plan.publish_key}`
                          );
                          break;
                        case "locked":
                          setPublishKey(user.plan.publish_key);
                          props.history.push(
                            `/share/detail?publish_key=${user.plan.publish_key}`
                          );
                          break;
                        default:
                          props.history.push(
                            `/case/insert?publish_key=${user.plan.publish_key}&first_login=${user.first_login}`
                          );
                          break;
                      }
                    }
                  }
                }}
                className={submitClass}
              >
                はじめる
              </button>
            </div>
            <div className="c-group--read-small_read">
              ※本サービスはWiFi環境下での使用を推奨しています。
            </div>
          </div>
        </div>
        <div className="c-layout-child">
          <div className="c-group--copy">
            <img src={renoveruLogo} alt="renoveru" />
            <div>Copyright © Renoveru,Inc.</div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Top;