import * as React from "react";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import { Dispatch } from "redux";
import sendPageView from "../../../analytics";
import { ApiClient } from "../../../api";
import { IUpdatePlanParam } from "../../../api/request/plans";
import Loading from "../../../component/loading";
import Navigation from "../../../component/navigation";
import IPartVariation from "../../../models/part_variation";
import { IPlan, IPlanInfo } from "../../../models/plan";
import { ISubCategoryParts } from "../../../models/plan_part";
import { ActionName as ActionNamePlan, IPlanAction } from "../../../redux/plan";
import {
  ActionName as ActionNameAllPart,
  IAllPartAction
} from "../../../redux/all_part";
import {
  buildPlanQueryParam,
  handleRequestError,
  loadPlanPublishKey
} from "../../../util";
import { PartDetailImageModal, PartDetailModal } from "../detail/index";
import PartCell, { PartState } from "./part_cell";
import "./index.css"

interface IPartListProps {
  planId: number;
  subCategoryId: number;
  planPartVariation: ISubCategoryParts;
  allPartVariation: IPartVariation[];
}
type AllProps = RouteComponentProps & IPartListProps;

const PartListHeader = (props: AllProps) => {
  return (
    <Navigation
      left={"戻る"}
      onLeftClick={() => backPage(props)}
    />
  );
};

const backPage = (props: AllProps) => {
  const searchParams = new URLSearchParams(window.location.search.substring(1));
  const scrollId = searchParams.get("scroll_id") || "";
  const subCategoryId = Number(searchParams.get("sub_category_id")) || 0;
  props.history.push(
    `/part/list${buildPlanQueryParam()}&sub_category_id=${subCategoryId}&scroll_id=${scrollId}`
  );
};

const PartListContent = (props: AllProps & IPlanUpdateProps) => {
  // 最新パーツ
  const planSelector = (state: any) => state.planReducer.fetchedPlan;
  const resultPlan: IPlan = useSelector(planSelector);
  // 選択部材
  const [selectedPartVariationId, setSelectedPartVariationId] = useState(0);

  return (
    <div className="c-layout">
      <div className="c-layout-child">
        {/* list */}
        <div className="c-group--part_list">
          <div className="c-list--equal" data-list_column="2">
            {props.allPartVariation.map(pv => {

              // 選択状態
              const selected =
                selectedPartVariationId > 0
                  ? selectedPartVariationId === pv.part_variation_id
                  : props.planPartVariation.selected_part
                    .part_variation_id === pv.part_variation_id || false;

              return (
                <PartCell
                  {...props}
                  key={pv.id}
                  id={pv.id}
                  name={pv.name}
                  coverImage={pv.images.length > 0 ? pv.images[0] : ""}
                  images={pv.images}
                  partNumber={pv.part_number}
                  materialName={pv.material_name}
                  description={pv.note}
                  priceDifference={pv.price_difference}
                  state={PartState.default}
                  isDefault={pv.is_default}
                  selected={selected}
                  onClick={(e: any) => {
                    setSelectedPartVariationId(0);
                    sendPageView(
                      "部材詳細画面",
                      `/part/detail/${pv.part_id}`
                    );
                  }}
                  onSelect={async (e: any) => {
                    if (resultPlan.status !== `locked`) {
                      // 部材詳細-トラッキング停止
                      e.stopPropagation();
                      const updateParam: IUpdatePlanParam = {
                        name: resultPlan.name,
                        memo: resultPlan.memo,
                        plan_part_variations: [
                          {
                            id: props.planPartVariation.selected_part.id,
                            part_variation_id: pv.part_variation_id,
                            sub_category_id: props.subCategoryId,
                            is_change: !selected,
                            is_default: pv.is_default,
                            memo:
                              props.planPartVariation.selected_part.memo || ""
                          }
                        ]
                      };
                      setSelectedPartVariationId(pv.part_variation_id);
                      await updatePlan(
                        props.dispatcherPlan,
                        updateParam,
                        props.planId,
                        (plan: IPlanInfo) => {
                          // console.log('onUpdated');
                        }
                      );
                    } else {
                      props.dispatcherPlan({
                        type: ActionNamePlan.showDuplicateConfirm,
                        showDuplicateConfirm: true
                      });
                    }
                  }}
                />
              );
            })}
            {
              props.allPartVariation.length === 0 &&
              (<span className="no-parts"> 選択可能なパーツが登録されていません。</span>)
            }
          </div>
        </div>
      </div>
    </div>
  );
};

const PartListModalMemo = (props: AllProps & IPlanUpdateProps) => {
  const [isReset, setReset] = useState(false);
  const formattedMemo = `${props.planPartVariation.selected_part.memo}`.substr(
    0,
    50
  );
  const [memo, setMemo] = useState(formattedMemo);

  // 最新パーツ
  const planSelector = (state: any) => state.planReducer.fetchedPlan;
  const resultPlan: IPlan = useSelector(planSelector);
  // reset
  useEffect(() => {
    if (isReset) {
      setMemo(formattedMemo);
      setReset(false);
    }
  }, [isReset]);

return (
    <div
      id="comment_modal"
      className="c-modal--half"
      data-modal-color="light_trans_nav"
    >
      <div className="c-modal-inwrapper">
        <div className="c-modal-inwrapper-content">
          <div className="c-nav_bar">
            <div className="c-nav_bar-left">
              <div
                className="c-button c-modal_close"
                onClick={() => {
                  // キャンセル
                  setReset(true);
                }}
              >
                <div className="c-button-strings">キャンセル</div>
              </div>
            </div>
            <div className="c-nav_bar-center" />
            <div className="c-nav_bar-right">
              <div
                className="c-button c-modal_close"
                onClick={async () => {
                  if (resultPlan.status !== "locked") {
                    const updateParam: IUpdatePlanParam = {
                      name: resultPlan.name,
                      memo: resultPlan.memo,
                      plan_part_variations: [
                        {
                          id: props.planPartVariation.selected_part.id,
                          part_variation_id:
                            props.planPartVariation.selected_part
                              .part_variation_id,
                          sub_category_id: props.subCategoryId,
                          is_change:
                            props.planPartVariation.selected_part.is_change,
                          is_default:
                            props.planPartVariation.selected_part.is_default,
                          memo: memo ? memo : ""
                        }
                      ]
                    };
                    await updatePlan(
                      props.dispatcherPlan,
                      updateParam,
                      props.planId,
                      (plan: IPlanInfo) => {
                        // console.log('onUpdated');
                      }
                    );
                  } else {
                    props.dispatcherPlan({
                      type: ActionNamePlan.showDuplicateConfirm,
                      showDuplicateConfirm: true
                    });
                  }
                }}
              >
                <div className="c-button-strings u-tc--success">保存</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

interface IPlanUpdateProps {
  dispatcherPlan: Dispatch<IPlanAction>;
  setLoading: (loading: boolean) => void;
}
const PartListModal = (props: AllProps & IPlanUpdateProps) => {
  // 最新パーツ
  const planSelector = (state: any) => state.planReducer.fetchedPlan;
  const resultPlan: IPlan = useSelector(planSelector);
  const [index, setIndex] = useState(0);

  return (
    <React.Fragment>
      {/* メモのModal */}
      <PartListModalMemo
        {...props}
        planPartVariation={props.planPartVariation}
      />

      {/* テイスト側のModal */}
      {props.allPartVariation.map(pv => {
        // 選択状態
        const selected =
          props.planPartVariation.selected_part.part_variation_id ===
            pv.part_variation_id || false;

        return (
          <React.Fragment key={pv.id}>
            <PartDetailModal
              id={pv.id}
              name={pv.name}
              partNumber={pv.part_number}
              description={pv.note}
              images={pv.images}
              material_name={pv.material_name}
              isShared={false}
              isSelected={selected}
              isDefault={pv.is_default}
              memo={""}
              price_difference={pv.price_difference}
              onBack={() => {
                sendPageView(
                  "部材選択画面",
                  `/part/list?sub_category_id=${props.subCategoryId}`
                );
              }}
              onChange={async (e: any) => {
                if (resultPlan.status !== "locked") {
                  const updateParam: IUpdatePlanParam = {
                    name: resultPlan.name,
                    memo: resultPlan.memo,
                    plan_part_variations: [
                      {
                        id: props.planPartVariation.selected_part.id,
                        part_variation_id: pv.id,
                        sub_category_id: props.subCategoryId,
                        is_change: !selected,
                        is_default: false,
                        memo: props.planPartVariation.selected_part.memo || ""
                      }
                    ]
                  };
                  window.scrollTo({ top: 0 });
                  props.setLoading(true);
                  await updatePlan(
                    props.dispatcherPlan,
                    updateParam,
                    props.planId,
                    (plan: IPlanInfo) => {}
                  );
                  backPage(props);
                } else {
                  props.dispatcherPlan({
                    type: ActionNamePlan.showDuplicateConfirm,
                    showDuplicateConfirm: true
                  });
                }
              }}
              onChangeIndex={cahngedIndex => {
                setIndex(cahngedIndex);
              }}
            />
            <PartDetailImageModal
              id={pv.id}
              images={pv.images}
              startIndex={index}
            />
          </React.Fragment>
        );
      })}
    </React.Fragment>
  );
};

const updatePlan = async (
  dispatcherPlan: Dispatch<IPlanAction>,
  planParts: IUpdatePlanParam,
  planId: number,
  onUpdated: (plan: IPlanInfo) => void
) => {
  // プラン更新
  const result = await new ApiClient().updatePlan(planId, planParts);
  // 再取得
  const responsePlan = await new ApiClient().getPlan(planId);
  dispatcherPlan({
    type: ActionNamePlan.setPlan,
    fetchedPlan: responsePlan
  });
  onUpdated(result);
};

const PartList = (props: AllProps) => {
  const [loading, setLoading] = useState(false);

  // planId,publishKey
  const searchParams = new URLSearchParams(window.location.search.substring(1));
  const subCategoryId = Number(searchParams.get("sub_category_id")) || 0;

  // plan
  const planSelector = (state: any) => state.planReducer.fetchedPlan;
  const resultPlan: IPlan = useSelector(planSelector);
  const dispatcherPlan = useDispatch<Dispatch<IPlanAction>>();
  // 現在のサブカテゴリに紐づくプラン側のパーツ
  const resultPlanPartVariation:
    | ISubCategoryParts
    | undefined = resultPlan.categories
    .map(c => c.sub_categories)
    .flat()
    .find(sc => sc.id === subCategoryId);
  const planId = resultPlan.id;

  // all parts
  const allPartSelector = (state: any) =>
    state.allPartReducer.fetchedAllParts;
  const resultAllPart: IPartVariation[] = useSelector(allPartSelector);
  const dispatcherAllPart = useDispatch<Dispatch<IAllPartAction>>();

  const fetchContents = useCallback(async () => {
    setLoading(true);
    try {
      // publish_key -> plan
      const responsePlan = await loadPlanPublishKey();

      // plan
      dispatcherPlan({
        type: ActionNamePlan.setPlan,
        fetchedPlan: responsePlan
      });

      // all parts
      const responseAllPart = await new ApiClient().getAllPartVariations(
        responsePlan.taste.id,
        Number(subCategoryId),
        responsePlan.publish_key
      );

      dispatcherAllPart({
        type: ActionNameAllPart.setAllParts,
        fetchedAllParts: responseAllPart
      });
      setLoading(false);
    } catch (e) {
      setLoading(false);
      handleRequestError(e, props.history);
    }
  }, [dispatcherPlan, dispatcherAllPart]);

  useEffect(() => {
    fetchContents();
  }, [fetchContents]);

  if (
    resultPlan === undefined ||
    loading ||
    resultPlanPartVariation === undefined ||
    resultAllPart === undefined
  ) {
    return <Loading />;
  }
  if (resultPlan.taste.id === 0) {
    return <p className="u-centered">存在しないプランです</p>;
  }

  return (
    <div className="t-part-select t-all-list">
      <PartListHeader {...props} planPartVariation={resultPlanPartVariation} />
      <PartListContent
        {...props}
        dispatcherPlan={dispatcherPlan}
        planId={planId}
        subCategoryId={Number(subCategoryId)}
        planPartVariation={resultPlanPartVariation}
        allPartVariation={resultAllPart}
        setLoading={setLoading}
      />
      <PartListModal
        {...props}
        dispatcherPlan={dispatcherPlan}
        planId={planId}
        subCategoryId={Number(subCategoryId)}
        planPartVariation={resultPlanPartVariation}
        allPartVariation={resultAllPart}
        setLoading={setLoading}
      />
    </div>
  );
};

export default PartList;
