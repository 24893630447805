import ReactGA from "react-ga";
import ReactGA4 from "react-ga4";
import { Env, getCurrentEnv } from "../env";
import { getSession } from "../session";

const getTrackingId = (): string => {
  const env = getCurrentEnv();
  switch (env) {
    case Env.production: {
      return "UA-102499618-10";
    }
    case Env.staging: {
      return "UA-102499618-11";
    }
    case Env.devtest: {
      return "UA-102499618-12";
    }
    default: {
      return "UA-102499618-13";
    }
  }
};

const getTrackingId4 = (): string => {
  const env = getCurrentEnv();
  switch (env) {
    case Env.production: {
      return "G-08GEQM1VC4";
    }
    case Env.staging: {
      return "G-ZJKYSKX6CC";
    }
    case Env.devtest: {
      return "G-J73ZQQEYFM";
    }
    default: {
      return "G-1EHBREZ1H5";
    }
  }
};

const getUrlParam = (key: string): string => {
  return new URLSearchParams(window.location.search).get(key) || "";
};

const sendPageView = (title: string, url?: string, param?: string) => {
  ReactGA.initialize(getTrackingId());
  ReactGA4.initialize(getTrackingId4());
  const path = url || window.location.pathname.replace(/\/$/, "");
  const session = getSession();
  if (session) {
    if (session.email) {
      ReactGA.set({ dimension1: session.email });
      ReactGA4.set({ dimension1: session.email });
    }

    if (session.base_taste_id) {
      ReactGA.set({ dimension2: session.base_taste_id });
      ReactGA4.set({ dimension2: session.base_taste_id });
    }
  }
  param
    ? ReactGA.pageview(`${path}?${param}=${getUrlParam(param)}`, [], title)
    : ReactGA.pageview(path, [], title);

  param
    ? ReactGA4.send({
      hitType: "pageview",
      page: `${path}?${param}=${getUrlParam(param)}`,
      title: title
    })
    : ReactGA4.send({
      hitType: "pageview",
      page: path,
      title: title
    });
};

export const sendException = (err: Error) => {
  try {
    ReactGA.initialize(getTrackingId());
    ReactGA4.initialize(getTrackingId4());
    const session = getSession();
    if (session) {
      if (session.email) {
        ReactGA.set({ dimension1: session.email });
        ReactGA4.set({ dimension1: session.email });
      }

      if (session.base_taste_id) {
        ReactGA.set({ dimension2: session.base_taste_id });
        ReactGA4.set({ dimension2: session.base_taste_id });
      }
      if (session.plan_id) {
        ReactGA.set({ dimension3: session.plan_id });
        ReactGA4.set({ dimension3: session.plan_id });
      }
    }
    ReactGA.exception({
      description: err.stack,
      fatal: true
    });
    ReactGA4.send({
      hitType: "exception",
      exDescription: err.stack,
      exFatal: true
    });
  } catch (err) {
    console.error(err);
  }
};

export default sendPageView;
