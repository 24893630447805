import * as React from "react";
import arrowImg from "../../images/walk_through/arrow.png";
import thumbnailImg01 from "../../images/walk_through/page_v2_1.jpg";
import thumbnailImg02 from "../../images/walk_through/page_v2_2.jpg";
import thumbnailImg03 from "../../images/walk_through/page_v2_3.jpg";
import thumbnailImg04 from "../../images/walk_through/page_v2_4.jpg";
import paga5ThumbnailImg01 from "../../images/walk_through/page_5_1.png";
import paga5ThumbnailImg02 from "../../images/walk_through/page_5_2.png";
import paga5ThumbnailImg03 from "../../images/walk_through/page_5_3.png";
import paga5ThumbnailImg04 from "../../images/walk_through/page_5_4.png";
import titleImg from "../../images/walk_through/title.svg";
import Carousel from "../carousel";

interface IOuterProps {
  onClickClose?: (e: any) => void;
  disableModal?: boolean;
}

type AllProps = IOuterProps;

const pages = [
  {
    modalTitle: "のメリット",
    title: "のメリット",
    hideModal: false,
    number: 0,
    subTitle: `<span class="sub-title-text">想いをカタチに、</span><span class="sub-title-text">らしいを暮らしに</span>`,
    mainDescription:`<span class="description-text">sugataは物件購入や</span><span class="description-text">設計前の準備として</span>
<span class="description-text">デザインイメージと</span><span class="description-text">工事の金額を</span>
<span class="description-text">シミュレーションし、</span>
<span class="description-text">自分らしい暮らしへの</span><span class="description-text">お部屋づくりの</span>
<span class="description-text">方向性を決めるための</span><span class="description-text">ツールです。</span>`
  },
  {
    modalTitle: "",
    title: "",
    hideModal: false,
    number: 1,
    subTitle: `<span class="sub-title-text">自分の</span><span class="sub-title-text">好みの</span><span class="sub-title-text">お部屋を</span>
<span class="sub-title-text">簡単に</span><span class="sub-title-text">見つけることが</span><span class="sub-title-text">できます。</span>`,
    thumbnailImg: thumbnailImg01,
    mainDescription:`<span class="description-text">sugataがあなたに</span><span class="description-text">おすすめの住まいを</span><span class="description-text">提案します。</span>
    <span class="description-text">数十種類の</span><span class="description-text">テイストから</span><span class="description-text">あなたの好みを</span><span class="description-text">見つけましょう。</span>`
  },
  {
    modalTitle: "",
    title: "",
    hideModal: false,
    number: 2,
    subTitle: `<span class="sub-title-text">好きなときに、</span>
    <span class="sub-title-text">好きなだけ</span><span class="sub-title-text">お部屋を</span><span class="sub-title-text">アレンジ</span>`,
    thumbnailImg: thumbnailImg02,
    mainDescription:`<span class="description-text">選んだテイストを</span><span class="description-text">自分好みに</span><span class="description-text">カスタマイズしてみましょう。</span>
<span class="description-text">天井や壁を</span><span class="description-text">変えてみると、</span>
<span class="description-text">お部屋の印象が</span><span class="description-text">がらりと変化します。</span>`
  },
  {
    modalTitle: "",
    title: "",
    hideModal: false,
    number: 3,
    subTitle: `<span class="sub-title-text">コストもわかり</span><span class="sub-title-text">安心</span><br /><br />`,
    thumbnailImg: thumbnailImg03,
    mainDescription:`<span class="description-text">お部屋の面積と</span><span class="description-text">選んだテイストと</span><span class="description-text">部材などの選択肢によって</span>
<span class="description-text">リノベーション費用が</span><span class="description-text">変化します。</span>
<span class="description-text">理想の住まいに対して、</span><span class="description-text">適切な資金計画が出来ます。</span>`
  },
  {
    modalTitle: "",
    title: "",
    hideModal: false,
    number: 4,
    subTitle: `<span class="sub-title-text">間取や仕上げは</span>
<span class="sub-title-text">デザイナーと</span><span class="sub-title-text">シッカリと</span><span class="sub-title-text">打ち合わせ</span>`,
    thumbnailImg: thumbnailImg04,
    mainDescription:`<span class="description-text">理想に近づけたプランを</span><span class="description-text">デザイナーに共有しましょう。</span>
<span class="description-text">悩みが残っていれば、</span><span class="description-text">メモ欄で伝えましょう。</span>
<span class="description-text">選んだ素材も</span><span class="description-text">サンプルを見ながら</span><span class="description-text">最終決定します。</span>
<span class="description-text">0から考えるよりも、</span><span class="description-text">スムーズに!</span>
<span class="description-text">（あくまで打合せの参考にするもので、</span><span class="description-text">変更は自由にできます。)</span>`
  },
  {
    modalTitle: "の利用フロー",
    title: "の利用フロー",
    hideModal: false,
    number: 5
  },
  {
    modalTitle: "のヒント",
    title: "のヒント",
    hideModal: true,
    number: 6,
    arrowImg,
    thumbnailImg01: paga5ThumbnailImg01,
    thumbnailImg02: paga5ThumbnailImg02,
    thumbnailImg03: paga5ThumbnailImg03,
    thumbnailImg04: paga5ThumbnailImg04,
    firstDescription: "画面左上の３本線から<br />メニューが開けます。",
    secondDescription: "「プラン管理」から<br />プランを新たに追加できます。"
  }
];

const PageBody = (props: AllProps & { page: any; index: number }) => {
  const { onClickClose, page, index } = props;
  if (props.disableModal && page.hideModal) {
    return <></>;
  }
  return (
    <React.Fragment>
      <div key={index} className={"page page-" + page.number}>
        <div className="header">
          <div className="close">
            {[6].includes(page.number) && (
              <div onClick={onClickClose} className="button">
                ×
              </div>
            )}
          </div>
          {page.number === 0 ? (
              <div style={{marginBottom: 50}}></div>
            ) : (
              <></>
            )
          }
          {props.disableModal && page.title === "" ? (
            <></>
          ) : ([0, 5, 6].includes(page.number) ? (
            (page.number === 5 && window.location.href.includes("introduction") ?
              (
                <>
                  <div className="under">
                    <div className="line" />
                  </div>
                  <div className="title">
                    <img src={titleImg} className="image" />
                    {props.disableModal ? page.title : page.modalTitle}
                  </div>
                </>
              ) :
              (
                <div className="title">
                  <img src={titleImg} className="image" />
                  {props.disableModal ? page.title : page.modalTitle}
                </div>
              )
            )
          ) : (<></>)
          )}
        </div>
        <div className="body">
          {[0, 1, 2, 3, 4].includes(page.number) && (
            <div>
              {page.number !== 0 ? (<div className="number">{page.number}</div>) : (<></>)}
              {page.subTitle && (page.number === 0 ?
                (
                  <>
                    <div className="under">
                      <div className="line" />
                    </div>
                    <div
                      className="sub-title first-page-title"
                      dangerouslySetInnerHTML={{
                        __html: page.subTitle.replace(/\n/g, "<br />")
                      }}
                    />
                  </>
                ) :
                (
                  <div
                    className="sub-title"
                    dangerouslySetInnerHTML={{
                      __html: page.subTitle.replace(/\n/g, "<br />")
                    }}
                  />
                )
              )}
              {page.thumbnailImg && (
                <div className="thumbnail">
                  <img src={page.thumbnailImg} className="image" />
                </div>
              )}
              <div className="description">
                {page.mainDescription && (page.number === 0 ?
                  (
                    <>
                      <div
                        className="main first-page-description"
                        dangerouslySetInnerHTML={{
                          __html: page.mainDescription.replace(/\n/g, "<br />")
                        }}
                      />
                      <div className="under">
                        <div className="line" />
                      </div>
                    </>
                  ) :
                  (
                    <div
                      className="main"
                      dangerouslySetInnerHTML={{
                        __html: page.mainDescription.replace(/\n/g, "<br />")
                      }}
                    />
                  )
                )}
                {page.subDescription && (
                  <div
                    className="sub"
                    dangerouslySetInnerHTML={{
                      __html: page.subDescription.replace(/\n/g, "<br />")
                    }}
                  />
                )}
              </div>
            </div>
          )}
          {[5].includes(page.number) && (
            <div>
              <div className="step-container">
                <div className="background-line" />
                <div className="step">
                  <div className="box-container">
                    <div className="box left">STEP1</div>
                    <div className="box right">カウンセリング</div>
                  </div>
                </div>
                <div className="step last">
                  <div className="box-container">
                    <div className="box left">STEP2</div>
                    <div className="box right">業務依頼のお申し込み</div>
                  </div>
                </div>
                <div className="description">
                  <div className="box-container">
                    <div className="box">sugataを使って、理想の住まいづくりを開始</div>
                  </div>
                </div>
                <div className="description last">
                  <div className="box-container">
                    <div className="box">作成プランを、担当者に共有する</div>
                  </div>
                </div>
                <div className="step last">
                  <div className="box-container">
                    <div className="box left">STEP3</div>
                    <div className="box right">物件探し</div>
                  </div>
                </div>
                <div className="description last">
                  <div className="box-container">
                    <div className="box">作成プランを再確認 / 変更する</div>
                  </div>
                </div>
                <div className="description last">
                  <div className="box-container">
                    <div className="box">作成プランを、担当者に共有する</div>
                  </div>
                </div>
                <div className="step last">
                  <div className="box-container">
                    <div className="box left">STEP4</div>
                    <div className="box right">設計打合せ開始</div>
                  </div>
                </div>
                <div className="description last">
                  <div className="box-container">
                    <div className="box">作成したsugataを参考に、住まいを具体化！</div>
                  </div>
                </div>
                <div className="step last">
                  <div className="box-container">
                    <div className="box left">STEP5</div>
                    <div className="box right">工事着工</div>
                  </div>
                </div>
                <div className="step last">
                  <div className="box-container">
                    <div className="box left">STEP6</div>
                    <div className="box right">工事確認と引き渡し</div>
                  </div>
                </div>
                <div className="description last">
                  <div className="box-container">
                    <div className="box">理想の住まいで、新生活を開始する！</div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {[6].includes(page.number) && (
            <div>
              <div className="hint-image">
                <div className="box-container">
                  <div className="box left">
                    <div className="thumbnail">
                      <img src={page.thumbnailImg01} className="image small" />
                    </div>
                  </div>
                  <div className="box center">
                    <div className="arrow">
                      <img src={page.arrowImg} className="image" />
                    </div>
                  </div>
                  <div className="box right">
                    <div className="thumbnail">
                      <img src={page.thumbnailImg02} className="image" />
                    </div>
                  </div>
                </div>
              </div>
              {page.firstDescription && (
                <div
                  className="first-description"
                  dangerouslySetInnerHTML={{
                    __html: page.firstDescription.replace(/\n/g, "<br />")
                  }}
                />
              )}
              <div className="hint-image">
                <div className="box-container">
                  <div className="box left">
                    <div className="thumbnail">
                      <img src={page.thumbnailImg03} className="image" />
                    </div>
                  </div>
                  <div className="box center">
                    <div className="arrow">
                      <img src={page.arrowImg} className="image" />
                    </div>
                  </div>
                  <div className="box right">
                    <div className="thumbnail">
                      <img src={page.thumbnailImg04} className="image" />
                    </div>
                  </div>
                </div>
              </div>
              {page.secondDescription && (
                <div
                  className="second-description"
                  dangerouslySetInnerHTML={{
                    __html: page.secondDescription.replace(/\n/g, "<br />")
                  }}
                />
              )}
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

const WalkThroughContent = (props: AllProps) => {
  const { onClickClose, disableModal } = props;

  return (
    <React.Fragment>
      {disableModal ? (
        <div>
          {pages.map((page: any, index: number) => {
            return (
              <PageBody
                key={index}
                onClickClose={onClickClose}
                page={page}
                disableModal={disableModal}
                index={index}
              />
            );
          })}
        </div>
      ) : (
        <Carousel
          centerPadding={"0%"}
          className="c-carousel"
          dot={true}
          swipe={true}
          arrows={true}
          infinite={false}
          self={slider => {
            if (slider) {
              slider.slickGoTo(0);
            }
          }}
        >
          {pages.map((page: any, index: number) => {
            return (
              <PageBody
                key={index}
                onClickClose={onClickClose}
                page={page}
                index={index}
              />
            );
          })}
        </Carousel>
      )}
    </React.Fragment>
  );
};

export default WalkThroughContent;
