import React, { useCallback, useEffect, useState } from "react";
import { Route } from "react-router-dom";
import sendPageView from "../../analytics";
import { ApiClient } from "../../api";
import { IMaintenace } from "../../models/maintenance";
import Maintenance from "../../ui/maintenance";
import GlobalMenu, { IGlobalMenuProps } from "../global_menu";
import Information, { isNotDisplayed } from "../../ui/information";
import { getSession, setSession } from "../../session";

const TrackRoute = (props: any & IGlobalMenuProps) => {
  const [loading, setLoading] = useState(true);
  const [maintenance, setMaintenance] = useState<IMaintenace>();

  const fetchMaintenances = useCallback(async () => {
    try {
      const result = await new ApiClient().getMaintenances();
      let session = getSession();
      if (!(session == null)) {
        // 最新のユーザ情報（受付済・案件種別）を取得・反映
        const userStatus = await new ApiClient().getUserStatus(session.email);
        session.visualization_func_status = userStatus.visualization_func_status;
        session.accepted = userStatus.accepted;
        session.matter_type = userStatus.matter_type_id;
        setSession(session);
      }
      setMaintenance(result);
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }

  }, [props.path]);
  useEffect(() => {
    fetchMaintenances();
  }, [fetchMaintenances]);

  const disableInformation = (path: string) => {
    if (
      path === "/" ||
      path === "/case/insert/"
    ) {
      return true;
    }
    return false;
  };

  if (loading) {
    return <></>;
  }

  if (maintenance && maintenance.is_maintenance) {
    return (
      <Route
        path="/"
        render={_ => <Maintenance maintenance={maintenance} {...props} />}
      />
    );
  }

  sendPageView(props.title, props.url, props.param);
  return props.globalMenuOpt && props.globalMenuOpt.show ? (
    <>
      <GlobalMenu globalMenuOptions={props.globalMenuOpt} />
      {isNotDisplayed() && <Information disableModal={disableInformation(props.path)} />}
      <div className="with-global-menu">
        <Route {...props} />
      </div>
    </>
  ) : (
    <>
      {isNotDisplayed() && <Information disableModal={disableInformation(props.path)} />}
      <Route {...props} />
    </>
  );
};
export default TrackRoute;
