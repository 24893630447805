import * as React from "react";
import { useSelector } from "react-redux";

import CaseDetailUI from "./ui";

export interface ICaseImageModal {
  imageUrl: string;
  currentCaseId: number;
  isConfirm: boolean;
  onSelect: (e: any) => void;
  onClose: (e: any) => void;
  loaded?: boolean;
}
const CaseDetail = (props: ICaseImageModal) => {
  const {
    currentCaseId,
    imageUrl,
    isConfirm,
    onSelect,
    loaded,
    onClose
  } = props;

  // 現在の選択状態
  const idsSelector = (state: any) => state.caseReducer.selectedCaseIds;
  const ids: number[] = useSelector(idsSelector);

  const isSelected = isConfirm ? true : ids.includes(currentCaseId);

  return (
    <div
      id={`case_details_${currentCaseId}`}
      className="c-modal"
      style={{ display: "none" }}
      data-modal=""
      data-modal-color="light_trans_nav"
    >
      <CaseDetailUI
        imageUrl={imageUrl}
        isSelected={isSelected}
        onSelect={onSelect}
        onClose={onClose}
        loaded={loaded}
      />
    </div>
  );
};

export default CaseDetail;
