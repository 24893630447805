import React, { useState, useEffect } from "react";
import { RouteComponentProps } from "react-router";

import { ITaste } from "../../../models/taste"
import { ISetting } from "../../../models/setting"

import TasteDetailUI from "../../taste/detail/ui";

interface IReceive {
  taste?: ITaste;
  setting?: ISetting;
}

const initialInfo: IReceive = {};

const PreviewTasteDetail = (props: RouteComponentProps) => {
  const [info, setInfo] = useState(initialInfo);
  const { taste, setting } = info;

  useEffect(() => {
    window.addEventListener('message', event => {
      if (event.data.taste) {
        setInfo(event.data || {});
      }
    });
  }, []);

  return (
    <TasteDetailUI
      taste={taste}
      loading={false}
      setting={setting}
      onClickBack={() => { }}
      onClickDecide={() => { }}
      simlarLinkable={false}
    />
  );
};

export default PreviewTasteDetail;
