import * as React from "react";
import Navigation from "../../../component/navigation";
import { ISetting } from "../../../models/setting";
import { ITaste } from "../../../models/taste";
import TasteDetailContent, { TasteImageModal } from "./taste_detail";
import TasteSimilar, { TasteSimilarType } from "./taste_similar";
import Loading from "../../../component/loading";

const TasteDetailHeader = (props: {
  onLeftClick: () => void;
  onRightClick: () => void;
}) => {
  const { onLeftClick, onRightClick } = props;

  return (
    <Navigation
      left={"戻る"}
      onLeftClick={onLeftClick}
      rightNode={
        <div className="c-nav_bar-right">
          <button
            className="c-button"
            onClick={onRightClick}
          >
            <span className="c-button-strings">テイストを決定！</span>
          </button>
        </div>
      }
    />
  );
};

const TasteDetailFooter = (props: {
  onClick: () => void;
  onBack: () => void;
}) => {
  const { onClick, onBack } = props;

  return (
    <div className="c-footer">
      <div className="c-footer-buttons c-footer-navigate">
        <div className="c-footer-buttons-sub c-button c-button-back" onClick={onBack}>
          <span className="c-button-strings">戻る</span>
        </div>
        <button onClick={onClick} className="c-footer-buttons-main c-button">
          <span className="c-button-strings">テイストを決定！</span>
        </button>
      </div>
    </div>
  );
};

const TasteDetailSimilar = (props: {
  taste: ITaste;
  simlarLinkable?: boolean;
}) => {
  const { taste, simlarLinkable } = props;

  return (
    <TasteSimilar
      taste={taste}
      taste_recommends={taste.recommends}
      taste_similar_type={TasteSimilarType.detail}
      linkable={simlarLinkable}
    />
  );
};

const TasteDetailContainer = (props: { taste: ITaste; setting?: ISetting,onClickThumbnail?: (index: number) => void; }) => {
  const { taste } = props;
  return (
    <div className="c-group--taste_details">
      {/* テイストカバー、詳細 */}
      <TasteDetailContent active={true} user_made_images={"No-images"} taste={taste} index={0} setting={props.setting} onClickThumbnail={props.onClickThumbnail}/>
    </div>
  );
};

type EntryProps = {
  taste?: ITaste;
  loading: boolean;
  setting?: ISetting;
  onClickBack: () => void;
  onClickDecide: () => void;
  onClickThumbnail?: (index: number) => void;
  startIndex?: number;
  simlarLinkable?: boolean;
};

const TasteDetailUI = (props: EntryProps) => {
  const {
    taste,
    loading,
    setting,
    onClickBack,
    onClickDecide,
    onClickThumbnail,
    startIndex,
    simlarLinkable
  } = props;
  if (taste === undefined || loading) {
    return <Loading />;
  }
  window.scrollTo(0, 0);

  return (
    <div className="t-taste-details">
      <TasteDetailHeader
        onLeftClick={onClickBack}
        onRightClick={onClickDecide}
      />
      <div className="c-layout">
        <div className="c-layout-child">
          <TasteDetailContainer taste={taste} setting={setting} onClickThumbnail={onClickThumbnail} />
        </div>
        <div className="c-layout-child">
          <TasteDetailSimilar taste={taste} simlarLinkable={simlarLinkable} />
        </div>
        <div className="c-layout-child">
          <TasteDetailFooter onClick={onClickDecide} onBack={onClickBack} />
        </div>
      </div>
      <TasteImageModal taste_id={taste.id} urls={taste.images} startIndex={startIndex || 0} />
    </div>
  );
};

export default TasteDetailUI;
