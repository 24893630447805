import IPartVariation from "../../models/part_variation";
import {IParseImageForApi} from "../../models/part_variation";

import { host, IRequest } from "./core";

export const buildAllPartVariationRequest = (
  tasteId: number,
  subCategoryId: number,
  publishKey: string
): IRequest<IPartVariation[]> => {
  return {
    url: `${host}/api/all_part_variations?taste_id=${tasteId}&sub_category_id=${subCategoryId}&publish_key=${publishKey}`,
    options: {
      method: "get",
      resolveWithFullResponse: true,
      json: true
    }
  };
};

export const buildAllPartVariationForVisualizationFuncRequest = (
  tasteId: number,
  publishKey: string
): IRequest<IParseImageForApi[][]> => {
  return {
    url: `${host}/api/all_part_variations_for_visualization_funcs?taste_id=${tasteId}&publish_key=${publishKey}`,
    options: {
      method: "get",
      resolveWithFullResponse: true,
      json: true
    }
  };
};