import * as React from "react";
import { Dispatch, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { ApiClient } from "../../../api";
import SelectSquareMeter from "../../../component/select_square_meter";
import { ISetting } from "../../../models/setting";
import { ITaste, ITotalPrice } from "../../../models/taste";
import { ActionName, ITasteAction } from "../../../redux/taste";
import { getSession } from "../../../session";
import { parsePriceFormat, eventVersionPriceDisplay, showThePrice } from "../../../util";
import TasteImages, { ITasteImageModal } from "./taste_images";

interface IOuterProps {
  index: number;
  taste: ITaste;
  user_made_images: any;
  wrapper_class?: string;
  hide_estimate?: boolean;
  onClickThumbnail?: (index: number) => void;
  active: boolean;
}
type AllProps = IOuterProps & RouteComponentProps;
/**
 * カバー
 */
const TasteCover = (props: AllProps) => {
  const { onClickThumbnail } = props;
  return (
    <React.Fragment>
      {props.taste.images.length > 0 ? (
        <React.Fragment>
          <div className="c-group--taste_details-cover_image">
            <div
              className="c-thumbnail--square c-modal_toggle"
              data-button_pos="bl"
              data-modal-target={`taste_images_modal_${props.taste.id}`}
              onClick={() => {
                if (onClickThumbnail) {
                  onClickThumbnail(0);
                }
              }}
            >
              <img src={encodeURI(props.taste.images[0])} alt="" />
              <div className="c-button u-fc--light-tertiary u-tc--on_light-primary">
                <i className="material-icons">zoom_in</i>
              </div>
            </div>
          </div>
        </React.Fragment>
      ) : (
        <React.Fragment>画像が選択されていません</React.Fragment>
      )}
    </React.Fragment>
  );
};
const TasteCoverForVisualizationFunc = (props: AllProps) => {
  const { onClickThumbnail } = props;
  return (
    <React.Fragment>
      {props.taste.images.length > 0 ? (
        <React.Fragment>
          <div className="c-group--taste_details-cover_image">
            <div
              className="c-thumbnail--square c-modal_toggle"
              data-button_pos="bl"
              data-modal-target={`taste_images_modal_${props.taste.id}`}
              onClick={() => {
                if (onClickThumbnail) {
                  onClickThumbnail(0);
                }
              }}
            >
              {props.user_made_images === "No-images"
                ? (<img src={encodeURI(props.taste.images[0])} alt="" />)
                : (props.user_made_images.image_url_01 === null
                    ? (<img src={encodeURI(props.taste.images[0])} alt="" />)
                    : (<img src={encodeURI(props.user_made_images.image_url_01)} alt="" />)
                  )
              }
              <div className="c-button u-fc--light-tertiary u-tc--on_light-primary">
                <i className="material-icons">zoom_in</i>
              </div>
            </div>
          </div>
        </React.Fragment>
      ) : (
        <React.Fragment>画像が選択されていません</React.Fragment>
      )}
    </React.Fragment>
  );
};

export const TasteImageModal = (props: ITasteImageModal) => {
  return (
    <React.Fragment>
      <TasteImages
        taste_id={props.taste_id}
        urls={props.urls}
        startIndex={props.startIndex}
      />
    </React.Fragment>
  );
};

const TasteCoverThumbnails = (props: AllProps) => {
  const { onClickThumbnail } = props;
  return (
    <div className="c-group--taste_details-thumb_list">
      <div className="c-list--equal" data-list_column="4">
        {props.taste.images.length > 0 &&
          props.taste.images.slice(1).map((url, index) => {
            const u = encodeURI(url);
            return (
              <div className="c-list-item" key={index}>
                <div
                  className="c-thumbnail--square c-modal_toggle"
                  style={{ width: "100%", height: "64px" }}
                  data-button_pos="bl"
                  data-object_fit="contain"
                  data-image-index={index % 2}
                  data-modal-target={`taste_images_modal_${props.taste.id}`}
                  onClick={() => {
                    if (onClickThumbnail) {
                      onClickThumbnail(index + 1);
                    }
                  }}
                >
                  <img src={u} alt="" />
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};
const TasteCoverThumbnailsForVisualizationFunc = (props: AllProps) => {
  const { onClickThumbnail } = props;
  const testImages = props.taste.images;

  if (props.user_made_images !== "No-images") {
    if (props.user_made_images.image_url_02 !== null && !testImages.includes(props.user_made_images.image_url_02)) {
      testImages.unshift(props.user_made_images.image_url_02);
    }
    if (props.user_made_images.image_url_01 !== null && !testImages.includes(props.user_made_images.image_url_01)) {
      testImages.unshift(props.user_made_images.image_url_01);
    }
  }
  return (
    <div className="c-group--taste_details-thumb_list">
      <div className="c-list--equal" data-list_column="4">
        {testImages.length > 0 && testImages.slice(1).map((url, index) => {
            const u = encodeURI(url);
            return (
              <div className="c-list-item" key={index}>
                <div
                  className="c-thumbnail--square c-modal_toggle"
                  style={{ width: "100%", height: "64px" }}
                  data-button_pos="bl"
                  data-object_fit="contain"
                  data-image-index={index % 2}
                  data-modal-target={`taste_images_modal_${props.taste.id}`}
                  onClick={() => {
                    if (onClickThumbnail) {
                      onClickThumbnail(index + 1);
                    }
                  }}
                >
                  <img src={u} alt="" />
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

/**
 * タイトル、カラー、キーワード
 */
 const TasteAttribute = (props: AllProps) => {
  return (
    <React.Fragment>
      {/* テイストタイトル */}
      <div className="c-group--taste_details-name">{props.taste.title}</div>
      {/* テイスト説明 */}
      <div className="c-group--taste_details-description">
        {props.taste.note}
      </div>
      {/* キーワード */}
      <div className="c-group--taste_details-keywords">
        {/* キーワード：スタイル */}
        <div className="c-group--taste_details-keywords-title">スタイル</div>
        <div className="c-group--taste_details-keywords-list">
          {(props.taste.style_keywords !== "") ? props.taste.style_keywords : "－"}
        </div>
        {/* キーワード：色合い */}
        <React.Fragment>
          <div className="c-group--taste_details-keywords-title">色合い</div>
          <div className="c-group--taste_details-keywords-list">
            {(props.taste.color_keywords !== "") ? props.taste.color_keywords : "－"}
          </div>
        </React.Fragment>
      </div>
      {/* カラー */}
      <div className="c-group--taste_details-colors">
        <div className="c-group--taste_details-colors-title">カラー</div>
        <div className="c-group--taste_details-colors-list">
          <div className="c-list--equal">
            {props.taste.colors ? (
              props.taste.colors.map(color => {
                return (
                  <div
                    className="c-list-item"
                    key={color}
                    style={{ backgroundColor: `#${color}` }}
                  />
                );
              })
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
const TasteAttributeForVisualizationFunc = (props: AllProps) => {
  return (
    <React.Fragment>
      {/* テイストタイトル */}
      <div className="c-group--taste_details-name">{props.taste.title}</div>
      {/* テイスト説明 */}
      <div className="c-group--taste_details-description">
        {props.taste.note}
      </div>
      {/* キーワード */}
      <div className="c-group--taste_details-keywords">
        {/* キーワード：スタイル */}
        <div className="c-group--taste_details-keywords-title">スタイル</div>
        <div className="c-group--taste_details-keywords-list">
          {(props.taste.style_keywords !== "") ? props.taste.style_keywords : "－"}
        </div>
        {/* キーワード：色合い */}
        <React.Fragment>
          <div className="c-group--taste_details-keywords-title">色合い</div>
          <div className="c-group--taste_details-keywords-list">
            {(props.taste.color_keywords !== "") ? props.taste.color_keywords : "－"}
          </div>
        </React.Fragment>
      </div>
      {/* カラー */}
      <div className="c-group--taste_details-colors">
        <div className="c-group--taste_details-colors-title">カラー</div>
        <div className="c-group--taste_details-colors-list">
          <div className="c-list--equal">
            {props.taste.colors ? (
              props.taste.colors.map(color => {
                return (
                  <div
                    className="c-list-item"
                    key={color}
                    style={{ backgroundColor: `#${color}` }}
                  />
                );
              })
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

interface ISettingOuterProps {
  setting?: ISetting;
}

/**
 * 概算見積もり
 */
const TasteEstimate = (props: AllProps & ISettingOuterProps) => {
  const tasteTotalPriceSelector = (state: any) =>
    state.tasteReducer.setTotalPrice;
  const tasteTotalPrice: ITotalPrice = useSelector(tasteTotalPriceSelector);
  const dispatchTaste = useDispatch<Dispatch<ITasteAction>>();

  const session = getSession();
  const selectedSquareMeterArea = session
    ? session.select_square_meter || 40
    : 40;
  const [squareMeterArea, setSquareMeterArea] = useState(
    selectedSquareMeterArea
  );
  const [loading, setLoading] = useState(false);

  const getTotalPrice = useCallback(async () => {
    setLoading(true);
    try {
      const resultTotalPrice = await new ApiClient().getTotalPrice(
        props.taste.id,
        squareMeterArea
      );

      dispatchTaste({
        type: ActionName.setTotalPrice,
        setTotalPrice: resultTotalPrice
      });
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  }, [dispatchTaste, squareMeterArea]);

  useEffect(() => {
    if (props.active) {
      getTotalPrice();
    }
  }, [getTotalPrice]);

  const hiddenSetting = {
    width: 0,
    height: 0,
    visibility: 'hidden' as 'hidden'
  }

  return (
    <div className="c-group--taste_details-calc">
      {/* 概算 */}
      {!(showThePrice(getSession())) ?<React.Fragment></React.Fragment>:
      <React.Fragment>
      <div className="c-group--taste_details-calc-title">テイストの概算金額</div>
      <div className="c-group--taste_details-calc-form">
        <div className="c-group--taste_details-calc-form-area_select">
          <SelectSquareMeter
            onChange={e => {
              setSquareMeterArea(e.target.value);
            }}
          />
        </div>
        {/* 概算価格固定表示 */}
        {eventVersionPriceDisplay
          /* イベント用表示 */
          ? <React.Fragment>
              <div className="c-group--taste_details-calc-form-result">
                ¥10,000,000
              </div>
              <div className="c-group--taste_details-calc-form-result" style={hiddenSetting}>
                {props.active &&
                  !loading &&
                  parsePriceFormat(tasteTotalPrice.total_price)
                }
              </div>
            </React.Fragment>
          /* 通常表示 */
          : <div className="c-group--taste_details-calc-form-result">
              {props.active &&
                !loading &&
                parsePriceFormat(tasteTotalPrice.total_price)
              }
            </div>
        }
      </div>
      <div className="c-group--taste_details-calc-notes">
        ※お部屋の平米数に基づく参考価格(税込)です。
        <br />
        ※選択するテイストや部材により変動します。
      </div>
      </React.Fragment>
      }
    </div>
  );
};

const TasteDetailContent = (props: AllProps & ISettingOuterProps) => {
  const session = getSession();
  let visualizationStatus: boolean = false;
  if (session && session.visualization_func_status && props.taste !== null && props.taste.base_images !== null) {
    const image_url_01: boolean = props.taste.base_images.image_url_01 !== null && props.taste.base_images.image_url_01 !== '';
    const image_url_02: boolean = props.taste.base_images.image_url_02 !== null && props.taste.base_images.image_url_02 !== '';
    const spotlight_url_01: boolean = props.taste.base_images.spotlight_url_01 !== null && props.taste.base_images.spotlight_url_01 !== '';
    const spotlight_url_02: boolean = props.taste.base_images.spotlight_url_02 !== null && props.taste.base_images.spotlight_url_02 !== '';
    if (image_url_01 && image_url_02 && spotlight_url_01 && spotlight_url_02) {
      visualizationStatus = true;
    }
  }

  if (visualizationStatus) {
    var hideFlg = props.hide_estimate;
    if(!(showThePrice(getSession()))){
      hideFlg = true;
    }
    return (
      <React.Fragment>
        {/* カバー */}
        <TasteCoverForVisualizationFunc {...props} user_made_images={props.user_made_images} taste={props.taste} index={props.index} />
        {/* カバーサムネイル */}
        <TasteCoverThumbnailsForVisualizationFunc
          {...props}
          user_made_images={props.user_made_images}
          taste={props.taste}
          index={props.index}
        />
        {/* テイスト詳細情報 */}
        <TasteAttributeForVisualizationFunc {...props} user_made_images={props.user_made_images} taste={props.taste} index={props.index} />
        {/* 概算見積もり */}
        {!hideFlg && (
          <TasteEstimate
            {...props}
            taste={props.taste}
            index={props.index}
            setting={props.setting}
          />
        )}
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        {/* カバー */}
        <TasteCover {...props} taste={props.taste} index={props.index} />
        {/* カバーサムネイル */}
        <TasteCoverThumbnails
          {...props}
          taste={props.taste}
          index={props.index}
        />
        {/* テイスト詳細情報 */}
        <TasteAttribute {...props} taste={props.taste} index={props.index} />
        {/* 概算見積もり */}
        {!props.hide_estimate && (
          <TasteEstimate
            {...props}
            taste={props.taste}
            index={props.index}
            setting={props.setting}
          />
        )}
      </React.Fragment>
    );
  }
};

export default withRouter(TasteDetailContent);
