import { IHouseholdItem } from "../../models/household_item";
import { host, IRequest } from "./core";

export const buildHouseholdItemsRequest = (
  tasteId: number
): IRequest<IHouseholdItem[]> => {
  return {
    url: `${host}/api/tastes/${tasteId}/household_items`,
    options: {
      method: "get",
      resolveWithFullResponse: true,
      json: true
    }
  };
};
