import * as React from "react";
const Loading = () => {
  return (
    <React.Fragment>
      <div className="u-centered u-tc--on_light-tertiary u-fs--small loading-page">
        読込中です
      </div>
    </React.Fragment>
  );
};

export default Loading;
