import * as React from "react";
import { Link } from "react-router-dom";
import { ITaste, ITasteRecommend } from "../../../models/taste";
import { getSession, setSession } from "../../../session";

export enum TasteSimilarType {
  detail = "DETAIL",
  recommend = "RECOMMEND"
}

const TasteSimilarCard = (props: { title: string; imageUrl: string }) => {

  const whereToGo = () => {
    const currentUrl: string = window.location.pathname;
    if (currentUrl.includes("/taste/recommend")) {
    const session = getSession();
    setSession({
      plan_id: session ? session.plan_id : 0,
      publish_key: session ? session.publish_key : "",
      select_case_ids: session ? session.select_case_ids : "",
      login_key: session ? session.login_key : "",
      base_taste_id: 0,
      visualization_func_status: session ? session.visualization_func_status : false,
      accepted: session ? session.accepted : false,
      matter_type: session ? session.matter_type : 0,
      email: session ? session.email : "",
      show_reset_part_confirm: session ? session.show_reset_part_confirm : false,
      select_square_meter: session ? session.select_square_meter || 40 : 40,
      from_where: `${window.location.pathname + window.location.search}`
    });
    }
  }

  return (
    <React.Fragment>
      <div className="c-card" style={{ width: "100%" }} onClick={whereToGo}>
        <div className="c-card-thumbnail">
          <div className="c-thumbnail--round_top" data-object_fit="contain">
            <img src={encodeURI(props.imageUrl)} alt="" />
          </div>
        </div>
        <div className="c-card-body">
          <div className="c-card-body-title">{props.title}</div>
        </div>
      </div>
    </React.Fragment>
  );
};

interface IOuterProps {
  taste: ITaste;
  taste_recommends: ITasteRecommend[];
  wrapper_class?: string;
  taste_similar_type?: TasteSimilarType;
  linkable?: boolean;
}
type AllProps = IOuterProps;

const TasteSimilar = (props: AllProps) => {
  // 事例の選択状態
  const searchParams = new URLSearchParams(window.location.search.substring(1));
  const param = searchParams.get("select_case_ids") || "";
  const selectCaseIds = param.split(",").map(p => Number(p));
  const titleClass =
    props.taste_similar_type === TasteSimilarType.detail
      ? "u-fs--base-bold u-mb1"
      : "u-fs--small-bold u-mb1";
  const title =
    props.taste_similar_type === TasteSimilarType.detail
      ? "類似テイスト"
      : `${props.taste.title}の類似テイスト`;
  let linkable = true;
  if (props.linkable !== undefined) {
    linkable = props.linkable;
  }

  return (
    <div className="c-group--taste_similar">
      <div className={titleClass}>
        {title}
      </div>

      <div className="c-group--taste_similar-list">
        <div className="c-list--equal" data-list_column="2">
          {props.taste_recommends.map(taste => {
            return linkable ? (
              <Link
                to={{
                  pathname: `/taste/detail/${taste.id}`,
                  search: `?select_case_ids=${selectCaseIds}`
                }}
                key={taste.id}
                className="c-list-item"
              >
                <TasteSimilarCard
                  title={taste.title}
                  imageUrl={taste.image_url[0]}
                />
              </Link>
            ) : (
              <div key={taste.id} className="c-list-item">
                <TasteSimilarCard
                  title={taste.title}
                  imageUrl={taste.image_url[0]}
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default TasteSimilar;
