import * as React from "react";
import { Dispatch, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import { ApiClient } from "../../../api";
import { IPlanParam } from "../../../api/request/plans";
import Loading from "../../../component/loading";
import { IPlan } from "../../../models/plan";
import { ActionName, IPlanAction } from "../../../redux/plan";
import { loadPlanPublishKey } from "../../../util";
import ShareDialog from "./share_dialog";

type AllProps = {} & RouteComponentProps<{ publish_key: string }>;
const ShareUrl = (props: AllProps) => {
  const { match } = props;
  const publishKey: string = match.params.publish_key;
  const [isActionCopy, setActionCopy] = useState(false);
  const [url, setUrl] = useState("");
  const [loading, setLoading] = useState(false);
  // plan
  const planSelector = (state: any) => state.planReducer.fetchedPlan;
  const resultPlan: IPlan = useSelector(planSelector);
  const dispatcher = useDispatch<Dispatch<IPlanAction>>();
  const shareUrl = `${window.location.protocol}//${window.location.host}/share/detail?publish_key=${publishKey}`;

  // コピー
  const handleCopy = () => {
    const selection = window.getSelection();
    if (selection) {
      manuarlUrlCopy(selection.toString());
    }
  };

  // コピーイベントを監視
  useEffect(() => {
    window.addEventListener("copy", handleCopy);
    // cleanUp
    return () => {
      window.removeEventListener("copy", handleCopy);
    };
  }, []);

  // 手動URLコピー
  const manuarlUrlCopy = (selection: string) => {
    // 共有URLをコピーした場合のみ
    if (selection === shareUrl) {
      window.removeEventListener("copy", handleCopy);
      showCopyDialog(shareUrl);
      window.addEventListener("copy", handleCopy);
    }
  };

  // URLコピー処理
  const showCopyDialog = (text: string) => {
    setUrl(text);
    setActionCopy(true);
  };
  // URLコピーキャンセル
  const onCopyCancel = () => {
    setActionCopy(false);
  };
  // URLコピー完了
  const onCopyComplete = async () => {
    await copyUrl();
    setActionCopy(false);
    window.scrollTo(0, 0);
  };

  const copyUrl = async () => {
    const input = document.createElement("input");
    input.setAttribute("id", "copyinput");
    document.body.appendChild(input);
    input.value = url;
    input.select();
    document.execCommand("copy");
    document.body.removeChild(input);
  };

  const onCopyUrl = async () => {
    await copyUrl();
    // 共有状態送信
    // const responsePlan = await loadPlanPublishKey();
    // 既にロック済みの場合は、コピーのみ行いAPI送信しない
    if (resultPlan.status !== "locked") {
      const param: IPlanParam = {
        id: resultPlan.id
      };
      // 共有状態更新
      await new ApiClient().sharedUrl(param);
      // ロック処理
      await new ApiClient().lockPlan(param);
      // 再取得
      const responsePlan = await loadPlanPublishKey();
      // plan更新
      dispatcher({
        type: ActionName.setPlan,
        fetchedPlan: responsePlan
      });
    }
  };

  const fetchPlan = useCallback(async () => {
    setLoading(true);
    try {
      // plan
      const responsePlan = await loadPlanPublishKey();
      dispatcher({
        type: ActionName.setPlan,
        fetchedPlan: responsePlan
      });

      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  }, [dispatcher]);

  useEffect(() => {
    fetchPlan();
  }, [fetchPlan]);

  if (resultPlan === undefined || loading) {
    return <Loading />;
  }

  return (
    <React.Fragment>
      <div className="t-share-uri t-insert">
        <div className="c-nav_bar--light navigation-header">
          <div className="c-nav_bar-left">
            <div className="c-button" onClick={() => props.history.goBack()}>
              <span className="c-button-strings">戻る</span>
            </div>
          </div>
          <div className="c-nav_bar-center" />
          <div className="c-nav_bar-right" />
        </div>
        <div className="c-layout">
          <div className="c-layout-child">
            <div className="c-group--read">
              <div className="c-group--read-large_read">
                作成したsugataを以下のURLでシェアしよう
              </div>
              <div className="c-group--read-small_read">
                URLをコピーして、一緒に住む家族やお友達、デザイナーに共有しましょう。
              </div>
              <div className="c-form-with_overlay">
                <input
                  type="text"
                  className="on_light"
                  defaultValue={shareUrl}
                  style={{ paddingRight: "44px" }}
                />
                <div
                  className="c-button"
                  onClick={() => showCopyDialog(shareUrl)}
                >
                  <i className="material-icons u-tc--tint">content_copy</i>
                </div>
              </div>

              <div
                className=""
                style={{ marginTop: "10px", textAlign: "center" }}
              />
            </div>
          </div>
          <div className="c-layout-child">
            <div className="c-footer">
              <div className="c-footer-buttons">
                <div
                  className="c-footer-buttons-main c-button"
                  onClick={() => showCopyDialog(shareUrl)}
                >
                  <div
                    className="c-button-strings"
                    style={{ whiteSpace: "nowrap" }}
                  >
                    プランURLをコピーする
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isActionCopy && (
        <ShareDialog
          locked={resultPlan.status === "locked"}
          onCopy={onCopyUrl}
          onCancel={onCopyCancel}
          onComplete={onCopyComplete}
        />
      )}
    </React.Fragment>
  );
};

export default ShareUrl;
