import * as React from "react";
import Divider from "../../component/divider";
import WalkThroughContent from "../../component/walk_through_dialog/content";
import "./style.css";
const Intoroduction = () => {
  return (
    <>
      <div className="c-introduction">
        <div className="c-layout">
          <div className="c-layout-child">
            <div className="c-group--pageheader">
              <div className="c-pageheader">
                <div className="c-pageheader-title">sugataのサービス紹介</div>
                <Divider />
              </div>
            </div>
          </div>
          <div className="c-layout-child">
            <div className="c-detail">
              <div className="walk-through-dialog">
                <div className="c-layout">
                  <WalkThroughContent disableModal={true} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Intoroduction;
