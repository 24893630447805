import * as React from "react";
import { Dispatch, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { ApiClient } from "../../api";
import Confirm from "../../component/confirm";
import { IPlan } from "../../models/plan";
import { ActionName, IPlanAction } from "../../redux/plan";
import PlanDeleteDialog from "./delete_dialog";
import { setActivePlan } from "../../session";
import Done from "../../component/alert/done";

interface IPlanDuplicateProps {
  // 複製元のプラン
  plan: IPlan;
  // 複製後のリダイレクト先URL(publish_key除く)
  redirectUrlWithoutPublishKey: string;
  // プラン削除確認ダイアログのレンダリングON,OFF
  renderPlanDeleteDialog: boolean;
}

const PlanDuplicateDialog = (
  props: IPlanDuplicateProps & RouteComponentProps
) => {
  const [showConfirm, setShowConfirm] = useState(false);
  const [showComplete, setShowComplete] = useState(false);
  // const [duplicatePlan, setDuplicatePlan] = React.useState<IPlan>();
  const [duplicatedPublishKey, setDuplicatedPublishKey] = useState("");
  const dispatch = useDispatch<Dispatch<IPlanAction>>();

  // 複製確認プランダイアログ表示
  const planSelector = (state: any) => state.planReducer.showDuplicateConfirm;
  const doShowConfirm: boolean = useSelector(planSelector);

  // ドロワー開閉状態
  const showDrawerSelector = (state: any) => state.planReducer.showDrawer;
  const showDrawer: boolean = useSelector(showDrawerSelector);

  // 複製処理の成功
  // - 複製完了アラートを表示する
  const successDuplicate = (plan: IPlan) => {
    setActivePlan(plan);
    setDuplicatedPublishKey(plan.publish_key);
    setShowConfirm(false);
    setShowComplete(true);
  };

  // 複製処理の後処理
  // - [OK]ボタン押されて複製プランにリダイレクト
  const finishDuplicate = () => {
    // リダイレクト
    const url = `${props.redirectUrlWithoutPublishKey}publish_key=${duplicatedPublishKey}`;
    window.location.href = url;
    dismiss();
  };

  // ダイアログ終了
  const dismiss = () => {
    dispatch({
      type: ActionName.showDuplicateConfirm,
      showDuplicateConfirm: false
    });
    setShowConfirm(false);
    setShowComplete(false);
  };

  // 複製処理
  // - プラン上限数確認
  const doDuplicate = async () => {
    const result = await new ApiClient().limitPlan();
    if (result.is_over) {
      dispatch({
        type: ActionName.showDeletePlan,
        showDeletePlan: true
      });
      dismiss();
    } else {
      const plan = await new ApiClient().duplicatePlan(props.plan.id);
      successDuplicate(plan);
    }
  };

  useEffect(() => {
    setShowConfirm(doShowConfirm);
  }, [doShowConfirm]);
  const message = `
  プランを複製すれば別のプランとしてカスタマイズすることができます。
  複製してもよろしいですか？`;
  return (
    <React.Fragment>
      {showConfirm && (
        <Confirm
          title="このプランは既にプランURLを発行しているため、部材を変更できません。"
          text={message}
          okText="プランを複製する"
          cancelText="キャンセル"
          onClickOk={async () => await doDuplicate()}
          onClickCancel={() => {
            dismiss();
          }}
        />
      )}
      {showComplete && (
        <Done
          title=""
          text={`${props.plan.name}を複製しました。`}
          okText="OK"
          onClickOk={(e: any) => {
            finishDuplicate();
          }}
        />
      )}
      {!showDrawer && props.renderPlanDeleteDialog && (
        <PlanDeleteDialog
          onCancel={() => ({})}
          onCreate={() => ({})}
          onDelete={() => doDuplicate()}
        />
      )}
    </React.Fragment>
  );
};

export default withRouter(PlanDuplicateDialog);
