import * as React from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import styled from "styled-components";

const timeout = 200;

export const AnimationStyle = styled.div`
  .modal-appear,
  .modal-enter {
    opacity: 1;
    transform: translateY(200px);
  }
  .modal-appear-active,
  .modal-enter-active {
    transition: opacity ${timeout}ms ease-out, transform ${timeout}ms ease-out;
    opacity: 1;
    transform: translateY(0px);
  }
`;

type AllProps = React.Props<{}>;

const TransitionModal = (props: AllProps) => {
  return (
    <AnimationStyle>
      <TransitionGroup appear={true}>
        <CSSTransition
          classNames="modal"
          timeout={{ enter: timeout, exit: timeout }}
        >
          {props.children}
        </CSSTransition>
      </TransitionGroup>
    </AnimationStyle>
  );
};

export default TransitionModal;
