import { ITaste, ITotalPrice, ITasteVisualizationStatus } from "../../models/taste";
import { host, IRequest } from "./core";

export const buildTastesRequest = (): IRequest<ITaste[]> => {
  return {
    url: `${host}/api/tastes`,
    options: {
      method: "get",
      resolveWithFullResponse: true,
      json: true
    }
  };
};

export const buildGetVisualizaionFuncStatusRequest = (tasteId: number): IRequest<ITasteVisualizationStatus> => {
  return {
    url: `${host}/api/tastes/visualization_func_status?taste_id=${encodeURIComponent(tasteId)}`,
    options: {
      method: "get",
      resolveWithFullResponse: true,
      json: true
    }
  };
};

export const buildTotalPriceRequest = (
  tasteId: number,
  squareMeter: number
): IRequest<ITotalPrice> => ({
  url: `${host}/api/tastes/total_price?taste_id=${encodeURIComponent(tasteId)}&square_meter=${encodeURIComponent(squareMeter)}`,
  options: {
    method: "post",
    resolveWithFullResponse: true,
    json: true
  }
});
