import * as React from "react";

interface IOuterProps {
  title: string;
  text: string;
  okText: string;
  cancelText: string;
  onClickOk?: (e: any) => void;
  onClickCancel: () => void;
}

type AllProps = IOuterProps;

const Confirm = (props: AllProps) => {
  const { title, text, okText, cancelText, onClickOk, onClickCancel } = props;

  return (
    <div
      className="c-modal--alert"
      data-modal="active"
      data-modal-color="light_trans_nav"
    >
      <div className="c-modal-inwrapper">
        <div
          className="c-modal-inwrapper-content"
          style={{ overflowY: "hidden" }}
        >
          <div className="c-layout">
            <div className="c-layout-child">
              <div className="u-fs--base">{title}</div>
              <div className="u-fs--small u-tc--danger">
                {text.split("\\n").map((str, index) => (
                  <React.Fragment key={index}>
                    {str}
                    <br />
                  </React.Fragment>
                ))}
              </div>
            </div>
            <div className="c-layout-child">
              <div className="c-nav_bar">
                <div className="c-nav_bar-left" onClick={onClickCancel}>
                  <div className="c-button c-modal_close">
                    <div className="c-button-strings">
                      <div className="u-tc--success">{cancelText}</div>
                    </div>
                  </div>
                </div>
                <div className="c-nav_bar-right" onClick={onClickOk}>
                  <div className="c-button c-modal_close">
                    <div className="c-button-strings">
                      <div className="u-tc--success">{okText}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Confirm;
