import ICase from "../models/case";
import ICategory from "../models/category";
import { IHouseholdItem } from "../models/household_item";
import IInformation from "../models/information";
import { IMaintenace } from "../models/maintenance";
import IPartVariation from "../models/part_variation";
import { IParseImageForApi } from "../models/part_variation";
import { IPlan, IPlanInfo } from "../models/plan";
import { IPlanParts } from "../models/plan_part";
import { ISetting } from "../models/setting";
import { ITaste, ITotalPrice, ITasteVisualizationStatus } from "../models/taste";
import { IUser, IUserStatus } from "../models/user";
import { buildCasesRequest } from "./request/cases";
import { buildCategoriesRequest } from "./request/categories";
import { execRequest } from "./request/core";
import { buildHouseholdItemsRequest } from "./request/household_items";
import { buildInformationRequest } from "./request/informations";
import { buildMaintenancesRequest } from "./request/maintenances";
import { buildPartVariationRequest } from "./request/part_variations";
import { buildAllPartVariationRequest, buildAllPartVariationForVisualizationFuncRequest } from "./request/all_part_variations";
import { buildPlanPartsRequest } from "./request/plan_parts";
import {
  buildCreatePlanRequest,
  buildFindPlanRequest,
  buildLockRequest,
  buildPlanFixedTasteRequest,
  buildPlanRequest,
  buildPlansRequest,
  buildUpdatePlanRequest,
  buildUpdateUserMadeImageRequest,
  buildDuplicatePlanRequest,
  buildUpdatePlanSquareMeterRequest,
  buildUpdateSharedUrlRequest,
  IPlanParam,
  IUpdatePlanParam,
  IUpdateUserMadeImageParam,
  buildDestroyPlansRequest,
  buildLimitPlanRequest,
  IUpdatePlanSquareMeterParam,
  buildExistPlanRequest,
  buildCheckHideTasteRequest
} from "./request/plans";
import { buildSettingsRequest } from "./request/settings";
import { buildTasteRecommendsRequest } from "./request/taste_recommends";
import { buildTastesRequest, buildTotalPriceRequest, buildGetVisualizaionFuncStatusRequest } from "./request/tastes";
import {
  buildSelectPartRequest,
  buildSharePlanRequest,
  buildTasteResetRequest
} from "./request/user_behaviors";

import { buildUserSearchRequest, buildGetUserStatusRequest } from "./request/users";

export class ApiClient {
  /* 事例一覧: /api/cases */
  public async getCases(): Promise<ICase[]> {
    const req = buildCasesRequest();
    return await execRequest(req);
  }

  /* テイスト一覧: /api/tastes */
  public async getTastes(): Promise<ITaste[]> {
    const req = buildTastesRequest();
    return await execRequest(req);
  }

  /* 積算価格取得: /api/tastes/total_price */
  public async getTotalPrice(
    tasteId: number,
    squareMeter: number
  ): Promise<ITotalPrice> {
    const req = buildTotalPriceRequest(tasteId, squareMeter);
    return await execRequest(req);
  }

  /* オススメのテイスト一覧: /api/tastes/recommend */
  public async getTasteRecommends(
    cases: number[],
    publishKey: string
  ): Promise<ITaste[]> {
    const req = buildTasteRecommendsRequest(cases, publishKey);
    return await execRequest(req);
  }

  /* 設定取得: /api/settings/ */
  public async getSetting(): Promise<ISetting> {
    const req = buildSettingsRequest();
    return await execRequest(req);
  }

  /* パーツ取得: /api/part_variations */
  public async getPartVariations(
    tasteId: number,
    subCategoryId: number,
    publishKey: string
  ): Promise<IPartVariation[]> {
    const req = buildPartVariationRequest(tasteId, subCategoryId, publishKey);
    return await execRequest(req);
  }

  /* 全てのパーツ取得: /api/all_part_variations */
  public async getAllPartVariations(
    tasteId: number,
    subCategoryId: number,
    publishKey: string
  ): Promise<IPartVariation[]> {
    const req = buildAllPartVariationRequest(tasteId, subCategoryId, publishKey);
    return await execRequest(req);
  }

  /* カテゴリー取得: /api/categories */
  public async getCategories(): Promise<ICategory[]> {
    const req = buildCategoriesRequest();
    return await execRequest(req);
  }

  /* カテゴリ毎のプランに紐づくパーツ取得: /api/plans/:id/parts */
  public async getPlanParts(planId: number): Promise<IPlanParts[]> {
    const req = buildPlanPartsRequest(planId);
    return await execRequest(req);
  }

  /* プラン取得: /api/plans */
  public async getPlan(planId: number): Promise<IPlan> {
    const req = buildPlanRequest(planId);
    return await execRequest(req);
  }

  /* カテゴリ毎のプランに紐づくパーツ取得: /api/plans/:id/parts */
  public async getVisualizationFuncStatus(tasteId: number): Promise<ITasteVisualizationStatus> {
    const req = buildGetVisualizaionFuncStatusRequest(tasteId);
    return await execRequest(req);
  }

  /* プラン取得: /api/plans */
  public async findPlan(publishKey: string): Promise<IPlan> {
    const req = buildFindPlanRequest(publishKey);
    return await execRequest(req);
  }

  /* プラン一覧: /api/plans */
  public async getPlans(): Promise<IPlan[]> {
    const req = buildPlansRequest();
    return await execRequest(req);
  }

  /* テイスト提案: /api/plans/fixed_taste */
  public async planFixedTaste(
    tasteId: number,
    publishKey: string,
    squareMeter: number
  ): Promise<IPlanInfo> {
    const req = buildPlanFixedTasteRequest(tasteId, publishKey, squareMeter);
    return await execRequest(req);
  }

  /* プラン作成: /api/plans */
  public async createPlan(): Promise<IPlan> {
    const req = buildCreatePlanRequest();
    return await execRequest(req);
  }

  /* プラン更新: /api/plans/:id */
  public async updatePlan(
    planId: number,
    param: IUpdatePlanParam
  ): Promise<IPlanInfo> {
    const req = buildUpdatePlanRequest(planId, param);
    return await execRequest(req);
  }

  /* プラン複製: /api/plans/:plan_id/duplicate */
  public async duplicatePlan(planId: number): Promise<IPlan> {
    const req = buildDuplicatePlanRequest(planId);
    return await execRequest(req);
  }

  /* プラン削除: /api/plans/destroies */
  public async destroyPlans(planIds: number[]): Promise<IPlan> {
    const req = buildDestroyPlansRequest(planIds);
    return await execRequest(req);
  }

  /* プラン数確認: /api/plans/limit_plan */
  public async limitPlan(): Promise<IPlan> {
    const req = buildLimitPlanRequest();
    return await execRequest(req);
  }

  /* プラン存在確認: /api/plans */
  public async existPlan(publishKey: string): Promise<IPlan> {
    const req = buildExistPlanRequest(publishKey);
    return await execRequest(req);
  }

  /* プランに紐づくテイスト表示可否確認: /api/plans */
  public async checkHideTaste(publishKey: string): Promise<IPlan> {
    const req = buildCheckHideTasteRequest(publishKey);
    return await execRequest(req);
  }

  /* ログイン: /api/plans */
  public async login(email: string, publishKey: string): Promise<IUser> {
    const req = buildUserSearchRequest(email, publishKey);
    return await execRequest(req, {});
  }

  /* 部材選択完了: /api/user_behaviors/select_part */
  public async selectPart(publishKey: string): Promise<void> {
    const req = buildSelectPartRequest(publishKey);
    return await execRequest(req);
  }

  /* 共有完了: /api/user_behaviors/share_plan */
  public async sharePlan(publishKey: string): Promise<void> {
    const req = buildSharePlanRequest(publishKey);
    return await execRequest(req);
  }

  /* テイストリセット: /api/user_behaviors/taste_reset */
  public async tasteReset(publishKey: string): Promise<void> {
    const req = buildTasteResetRequest(publishKey);
    return await execRequest(req);
  }

  /* お知らせ取得: /api/users/{loginKey}/information */
  public async getInformation(loginKey: string): Promise<IInformation> {
    const req = buildInformationRequest(loginKey);
    return await execRequest(req);
  }

  /* プラン平米更新: /api/plans/update_square_meter */
  public async updatePlanSquareMeter(
    param: IUpdatePlanSquareMeterParam
  ): Promise<IPlanInfo> {
    const req = buildUpdatePlanSquareMeterRequest(param);
    return await execRequest(req);
  }

  /* 家具取得: /api/tastes/{id}/household_items */
  public async getHouseholdItems(tasteId: number): Promise<IHouseholdItem[]> {
    const req = buildHouseholdItemsRequest(tasteId);
    return await execRequest(req);
  }

  /* プラン共有更新: /api/plans/update_is_share */
  public async sharedUrl(param: IPlanParam): Promise<void> {
    const req = buildUpdateSharedUrlRequest(param);
    return await execRequest(req);
  }

  /* プランロック処理: /api/plans/lock */
  public async lockPlan(param: IPlanParam): Promise<IPlan> {
    const req = buildLockRequest(param);
    return await execRequest(req);
  }

  /* メインテナンス状態取得: /api/maintenances */
  public async getMaintenances(): Promise<IMaintenace> {
    const req = buildMaintenancesRequest();
    return await execRequest(req);
  }

  /* 見える化イメージ(合成画像)更新: /api/user_made_images/:id */
  public async updateUserMadeImages(
    planId: number,
    param: IUpdateUserMadeImageParam
  ): Promise<IPlan> {
    const req = buildUpdateUserMadeImageRequest(planId, param);
    return await execRequest(req);
  }

  /* 見える化用パースデータ取得： /api/all_part_variations_for_visualization_funcs */
  public async getAllPartVariationsForVisualizationFunc(
    tasteId: number,
    publishKey: string
  ): Promise<IParseImageForApi[][]> {
    const req = buildAllPartVariationForVisualizationFuncRequest(tasteId, publishKey);
    return await execRequest(req);
  }

  /* ユーザステータス取得: /api/users/get_user_status */
  public async getUserStatus(email: string): Promise<IUserStatus> {
    const req = buildGetUserStatusRequest(email);
    return await execRequest(req);
  }

}
