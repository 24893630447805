import * as React from "react";
import { createRef, useEffect } from "react";
import Slider from "react-slick";

interface IOuterProps {
  initialSlide?: number;
  centerPadding?: string;
  className?: string;
  dot: boolean;
  swipe: boolean;
  arrows: boolean;
  infinite: boolean;
  afterChange?(currentSlide: number): void;
  beforeChange?(currentSlide: number, nextSlide: number): void;
  self?(slider: any): void;
}

type AllProps = React.Props<{}> & IOuterProps;

let firstClientX: number;
let clientX: number;

const preventTouch = (e: TouchEvent) => {
  const minValue = 5; // threshold

  clientX = e.touches[0].clientX - firstClientX;

  // 横スワイプが開始されていたら縦スワイプを止める
  if (Math.abs(clientX) > minValue) {
    e.preventDefault();
    e.returnValue = false;

    return false;
  }
};

const touchStart = (e: TouchEvent) => {
  firstClientX = e.touches[0].clientX;
};

const Carousel = (props: AllProps) => {
  const {
    initialSlide,
    centerPadding,
    children,
    afterChange,
    beforeChange,
    className,
    self,
    dot,
    swipe,
    arrows,
    infinite
  } = props;

  const containerRef = createRef<HTMLDivElement>();
  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.addEventListener("touchstart", touchStart);
      containerRef.current.addEventListener("touchmove", preventTouch, {
        passive: false
      });
    }

    return () => {
      if (containerRef.current) {
        containerRef.current.removeEventListener("touchstart", touchStart);
        containerRef.current.removeEventListener("touchmove", preventTouch);
      }
    };
  });

  return (
    <div ref={containerRef}>
      <Slider
        ref={self}
        afterChange={afterChange}
        beforeChange={beforeChange}
        className={className || "c-carousel--selectable"}
        dots={dot}
        swipe={swipe}
        infinite={infinite}
        arrows={arrows}
        autoplay={false}
        slidesToShow={1}
        slidesToScroll={1}
        initialSlide={initialSlide || 0}
        centerMode={true}
        centerPadding={centerPadding || "10%"}
      >
        {children}
      </Slider>
    </div>
  );
};

export default Carousel;
