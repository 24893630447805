import { Action, Reducer } from "redux";
import { ITaste, ITotalPrice } from "../models/taste";

export interface ITasteState {
  fetchTastes: ITaste[];
  fetchTasteList: ITaste[];
  commitTasteId: number;
  setTotalPrice: ITotalPrice;
}

export interface ITasteTotalPriceState {
  setTotalPrice: number;
}

const initialState: ITasteState = {
  fetchTastes: [],
  fetchTasteList: [],
  commitTasteId: 0,
  setTotalPrice: { total_price: 0 }
};

export enum ActionName {
  setTaste = "taste/setTaste",
  setTasteList = "taste/setTasteList",
  commitTasteId = "taste/commitTasteId",
  setTotalPrice = "taste/setTotalPrice"
}

interface ISetTaste extends Action<ActionName.setTaste> {
  fetchTaste: ITaste[];
}

interface ISetTasteList extends Action<ActionName.setTasteList> {
  fetchTasteList: ITaste[];
}

interface ICommitTaste extends Action<ActionName.commitTasteId> {
  commitTasteId: number;
}

interface ISetTotalPrice extends Action<ActionName.setTotalPrice> {
  setTotalPrice: ITotalPrice;
}

export type ITasteAction =
  | ICommitTaste
  | ISetTaste
  | ISetTasteList
  | ISetTotalPrice;

export const tasteReducer: Reducer<ITasteState | undefined, ITasteAction> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case ActionName.commitTasteId: {
      return { ...state, commitTasteId: action.commitTasteId };
    }
    case ActionName.setTaste: {
      return { ...state, fetchTastes: action.fetchTaste };
    }
    case ActionName.setTasteList: {
      return { ...state, fetchTasteList: action.fetchTasteList };
    }
    case ActionName.setTotalPrice: {
      return { ...state, setTotalPrice: action.setTotalPrice };
    }
  }
  return state;
};
