import * as React from "react";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import { Dispatch } from "redux";
import { ApiClient } from "../../../api";
import { IPlanInfo } from "../../../models/plan";
import { ISetting } from "../../../models/setting";
import { ITaste } from "../../../models/taste";
import { ActionName as ActionNamePlan, IPlanAction } from "../../../redux/plan";
import {
  ActionName as ActionNameSetting,
  ISettingAction
} from "../../../redux/setting";
import { ActionName, ITasteAction } from "../../../redux/taste";
import { getSession, setSession } from "../../../session";
import TasteDetailUI from "./ui";
import { handleRequestError, parsePublishKey } from "../../../util";
import Done from "../../../component/alert/done";
import { HttpStatusCode } from "../../../api/request/core";

const createPlan = async (
  dispatchPlan: Dispatch<IPlanAction>,
  tasteId: number,
  setDuplicateFixedTaste: (isDuplicate: boolean) => void,
  onCreated: (plan: IPlanInfo) => void
) => {
  try {
    // プラン作成
    const session = getSession();
    await new ApiClient().tasteReset(parsePublishKey());
    const result = await new ApiClient().planFixedTaste(
      tasteId,
      parsePublishKey(),
      session ? session.select_square_meter : 40
    );
    dispatchPlan({
      type: ActionNamePlan.setPlanState,
      planState: result
    });

    // Sessionに保存
    setSession({
      plan_id: result.id,
      publish_key: result.publish_key,
      select_case_ids: getSelectedCaseIds(),
      login_key: session ? session.login_key : "",
      base_taste_id: tasteId,
      visualization_func_status: session ? session.visualization_func_status : false,
      accepted: session ? session.accepted : false,
      matter_type: session ? session.matter_type : 0,
      email: session ? session.email : "",
      show_reset_part_confirm: false,
      select_square_meter: session ? session.select_square_meter : 40,
      from_where: `${window.location.pathname + window.location.search}`
    });
    onCreated(result);
  } catch (error) {
    if (error.statusCode === HttpStatusCode.DUPLICATE_TASTE_FIXED) {
      setDuplicateFixedTaste(true);
    }
  }
};

// url を優先する
const getSelectedCaseIds = (): string => {
  const session = getSession();
  const loadSelectCaseIds = session ? session.select_case_ids : "";
  const searchParams = new URLSearchParams(window.location.search.substring(1));
  const selectCaseIdsParam =
    searchParams.get("select_case_ids") || loadSelectCaseIds;
  return selectCaseIdsParam;
};

type EntryProps = {} & RouteComponentProps<{ taste_id: string }>;
const TasteDetail = (props: EntryProps) => {
  const { match } = props;
  const tasteId: number = Number(match.params.taste_id);

  const [loading, setLoading] = useState(false);
  const [thumbnailIndex, setThumbnailIndex] = useState(0);
  const [isDuplicateFixedTaste, setDuplicateFixedTaste] = useState(false);

  // plan
  const dispatchPlan = useDispatch<Dispatch<IPlanAction>>();

  // taste
  const dispatcher = useDispatch<Dispatch<ITasteAction>>();
  const tasteSelector = (state: any) => state.tasteReducer.fetchTasteList;
  const tastes: ITaste[] = useSelector(tasteSelector);
  const taste: ITaste | undefined = tastes.find(t => t.id === tasteId);

  // setting
  const settingSelector = (state: any) => state.settingReducer.fetchSetting;
  const setting: ISetting = useSelector(settingSelector);
  const dispatchSetting = useDispatch<Dispatch<ISettingAction>>();

  const fetchTasteList = useCallback(async () => {
    setLoading(true);
    try {
      // tastes
      const resultTastes = await new ApiClient().getTastes();
      dispatcher({
        type: ActionName.setTasteList,
        fetchTasteList: resultTastes
      });

      // setting
      const resultSetting = await new ApiClient().getSetting();
      dispatchSetting({
        type: ActionNameSetting.setSetting,
        fetchSetting: resultSetting
      });

      setLoading(false);
    } catch (e) {
      setLoading(false);
      handleRequestError(e, props.history);
    }
  }, [dispatcher, dispatchSetting]);

  useEffect(() => {
    fetchTasteList();
  }, [fetchTasteList]);

  const onClickBack = () => {
    const session = getSession();
    const caseIds: string = session ? session.select_case_ids : "";
    const publishKey: string = parsePublishKey();
    let url: string = session ? session.from_where : "";
    if (!(url.includes("/taste/list"))) {
      url = `/taste/recommend?select_case_ids=${caseIds}&publish_key=${publishKey}`;
    }
    props.history.push(url);
  };

  const onClickDecide = async () => {
    if (taste === undefined) {
      return;
    }

    await createPlan(
      dispatchPlan,
      taste.id,
      setDuplicateFixedTaste,
      (plan: IPlanInfo) => {
        props.history.push(`/part/insert?publish_key=${plan.publish_key}`);
      }
    );
  };
  const duplicateMessage = `
  テイストを選び直す場合は画面左上のメニューから「初めからプランを作成」を選択して新しくプランを作成してください。
  OKをタップすると、先ほど選択したテイストの部材選択画面へ戻ります`;

  return (
    <React.Fragment>
      <TasteDetailUI
        taste={taste}
        setting={setting}
        loading={loading}
        onClickBack={onClickBack}
        onClickDecide={onClickDecide}
        onClickThumbnail={(index: number) => setThumbnailIndex(index)}
        startIndex={thumbnailIndex}
      />
      {isDuplicateFixedTaste && (
        <Done
          title="テイストは既に選択済みです"
          text={duplicateMessage}
          okText="OK"
          onClickOk={(e: any) => {
            // 部材HOME画面へ
            const publishKey = parsePublishKey();
            props.history.push(`/part/home?publish_key=${publishKey}`);
          }}
        />
      )}
    </React.Fragment>
  );
};

export default TasteDetail;