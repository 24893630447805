import * as React from "react";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, RouteComponentProps } from "react-router-dom";
import Slider from "react-slick";
import { Dispatch } from "redux";
import { ApiClient } from "../../../api";
import { HttpStatusCode } from "../../../api/request/core";
import Done from "../../../component/alert/done";
import Carousel from "../../../component/carousel";
import Divider from "../../../component/divider";
import Loading from "../../../component/loading";
import Navigation from "../../../component/navigation";
import collectionsImg from "../../../images/ic-collections.svg";
import { IPlanInfo } from "../../../models/plan";
import { ISetting } from "../../../models/setting";
import { ITaste } from "../../../models/taste";
import { ActionName as ActionNamePlan, IPlanAction } from "../../../redux/plan";
import {
  ActionName as ActionNameSetting,
  ISettingAction
} from "../../../redux/setting";
import { ActionName, ITasteAction } from "../../../redux/taste";
import { getSession, setSession } from "../../../session";
import { handleRequestError, parsePublishKey } from "../../../util";
import TasteDetailContent, { TasteImageModal } from "../detail/taste_detail";
import TasteSimilar from "../detail/taste_similar";

interface ITasteHeaderProps {
  recommend_count: number;
  recommends: number[];
  select_case_ids: number[];
}
const ToTasteList = (props: ITasteHeaderProps & { label: string }) => {
  return (
    <React.Fragment>
      <Link
        className="u-tc--success u-tc--on_tint u-fc"
        style={{ textDecoration: "underline" }}
        to={{
          pathname: "/taste/list/",
          search: `?recommends=${props.recommends.join(
            ","
          )}&select_case_ids=${props.select_case_ids.join(",")}`
        }}
      >
        {props.label}
      </Link>
    </React.Fragment>
  );
};
/**
 * ヘッダーコンテンツ
 */
const RecommentedTasteHeader = (props: ITasteHeaderProps) => {
  return (
    <React.Fragment>
      <div className="c-group--pageheader">
        <div className="c-pageheader">
          <div className="c-pageheader-title">オススメはこちら！</div>
          <div className="c-pageheader-descriptions">
            {props.recommend_count > 1 ? (
              <span>
                {props.recommend_count}
                つのテイストから、1つお好みを選んでください。 <br />
                選択したテイストをベースに、お部屋をアレンジすることが出来ます！
              </span>
            ) : (
              <span>こちらのテイストをベースに、お部屋作りを行います。</span>
            )}
            <br />
            画面下部の「類似テイスト」や「
            <ToTasteList label="全てのテイスト" {...props} />
            」から選ぶことも出来ます。
          </div>
          <Divider />
        </div>
      </div>
    </React.Fragment>
  );
};

const createPlan = async (
  dispatchPlan: Dispatch<IPlanAction>,
  tasteId: number,
  setDuplicateFixedTaste: (isDuplicate: boolean) => void,
  onCreated: (plan: IPlanInfo) => void
) => {
  try {
    // プラン作成
    const session = getSession();
    await new ApiClient().tasteReset(parsePublishKey());
    const result = await new ApiClient().planFixedTaste(
      tasteId,
      parsePublishKey(),
      session ? session.select_square_meter : 40
    );

    dispatchPlan({
      type: ActionNamePlan.setPlanState,
      planState: result
    });

    // Sessionに保存
    setSession({
      plan_id: result.id,
      publish_key: result.publish_key,
      select_case_ids: getSelectedCaseIds(),
      login_key: session ? session.login_key : "",
      base_taste_id: tasteId,
      visualization_func_status: session ? session.visualization_func_status : false,
      accepted: session ? session.accepted : false,
      matter_type: session ? session.matter_type : 0,
      email: session ? session.email : "",
      show_reset_part_confirm: false,
      select_square_meter: session ? session.select_square_meter : 40,
      from_where: `${window.location.pathname + window.location.search}`
    });
    onCreated(result);
  } catch (error) {
    if (error.statusCode === HttpStatusCode.DUPLICATE_TASTE_FIXED) {
      setDuplicateFixedTaste(true);
    }
  }
};

const getSelectedCaseIds = (): string => {
  const searchParams = new URLSearchParams(window.location.search.substring(1));
  const selectCaseIdsParam = searchParams.get("select_case_ids") || "";
  return selectCaseIdsParam;
};

const RecommentedTasteFooter = (props: {
  recommends: number[];
  select_case_ids: number[];
  onClick: () => void;
  onBack: () => void;
}) => {
  const { recommends, select_case_ids, onClick, onBack } = props;
  return (
    <React.Fragment>
      <div className="c-footer">
        {/* 全てのテイストから選ぶ */}
        <div className="c-footer-buttons taste-footer-navigate">
          <Link
            style={{
              borderWidth: "2px",
              marginRight: "7%",
              marginLeft: "7%",
              marginBottom: "14px"
            }}
            className="c-footer-buttons-main c-button u-bc--tint u-tc--on_tint u-fc"
            to={{
              pathname: "/taste/list/",
              search: `?recommends=${recommends.join(
                ","
              )}&select_case_ids=${select_case_ids.join(",")}`
            }}
          >
            <span
              className="c-button-strings"
              style={{ color: "#000000", fontWeight: "bold" }}
            >
              全てのテイストから選ぶ
            </span>
            <img
              src={collectionsImg}
              className="icon-collections"
              alt="bar"
              style={{ position: "absolute", right: "15%" }}
            />
          </Link>
        </div>

        <div className="c-footer-buttons c-footer-navigate">
          <div
            className="c-footer-buttons-sub c-button c-button-back"
            onClick={onBack}
          >
            <span className="c-button-strings">戻る</span>
          </div>
          <div
            className="c-footer-buttons-main c-button u-fc--tint u-tc--on_tint"
            onClick={onClick}
          >
            <span className="c-button-strings">テイストを決定！</span>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

const backPage = (props: AllProps, ids: number[]) => {
  const url = `/case/confirm?ids=${ids.join(",")}`;
  props.history.push(url);
};

type AllProps = RouteComponentProps;
const TasteRecommend = (props: AllProps) => {
  const [loading, setLoading] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isDuplicateFixedTaste, setDuplicateFixedTaste] = useState(false);

  // setting
  const settingSelector = (state: any) => state.settingReducer.fetchSetting;
  const setting: ISetting = useSelector(settingSelector);
  const dispatchSetting = useDispatch<Dispatch<ISettingAction>>();

  // taste
  const tastesSelector = (state: any) => state.tasteReducer.fetchTastes;
  const tastes: ITaste[] = useSelector(tastesSelector);
  const dispatchTaste = useDispatch<Dispatch<ITasteAction>>();

  // plan
  const dispatchPlan = useDispatch<Dispatch<IPlanAction>>();

  // URLから事例ID取得
  const selectCaseIdsParam = getSelectedCaseIds();
  const selectCaseIds: number[] = selectCaseIdsParam
    .split(",")
    .map(id => Number(id));

  // テイスト提案取得
  const fetchTastes = useCallback(async () => {
    setLoading(true);
    try {
      const resultTastes = await new ApiClient().getTasteRecommends(
        selectCaseIds,
        parsePublishKey()
      );
      dispatchTaste({
        type: ActionName.setTaste,
        fetchTaste: resultTastes
      });

      const resultSetting = await new ApiClient().getSetting();
      dispatchSetting({
        type: ActionNameSetting.setSetting,
        fetchSetting: resultSetting
      });

      setLoading(false);
    } catch (e) {
      setLoading(false);
      handleRequestError(e, props.history);
      dispatchTaste({
        type: ActionName.setTaste,
        fetchTaste: []
      });
    }
  }, [dispatchTaste, dispatchSetting]);

  useEffect(() => {
    fetchTastes();
  }, [fetchTastes]);

  if (loading) {
    return <Loading />;
  }

  let carouselDetail: Slider;
  let carouselSimilar: Slider;
  const RecommentedTasteCarousel = () => {
    const [thumbnailIndex, setThumbnailIndex] = useState(0);
    return (
      <React.Fragment>
        <RecommentedTasteHeader
          recommend_count={tastes.length}
          recommends={tastes.map(t => t.id)}
          select_case_ids={selectCaseIds}
        />
        {/* テイスト詳細 */}
        <div className="c-group--taste_selector">
          <Carousel
            centerPadding={"8%"}
            className={"c-carousel--selectable carousel-taste-detail"}
            beforeChange={(currentIndex: number, nextIndex: number) => {
              carouselDetail.slickGoTo(nextIndex, false);
              setCurrentIndex(nextIndex);
            }}
            self={slider => (carouselDetail = slider)}
            dot={true}
            swipe={true}
            arrows={false}
            infinite={true}
            initialSlide={currentIndex}
          >
            {tastes.map((taste, index) => {
              return (
                <div key={index}>
                  <div className="c-group--taste_details">
                    <TasteDetailContent
                      active={currentIndex === index}
                      taste={taste}
                      user_made_images={"No-images"}
                      index={index}
                      setting={setting}
                      key={index}
                      onClickThumbnail={(thumbIndex: number) =>
                        setThumbnailIndex(thumbIndex)
                      }
                    />
                  </div>
                </div>
              );
            })}
          </Carousel>
        </div>
        {/* 類似テイスト */}
        <div className="c-group--taste_similar">
          <Carousel
            centerPadding={"8%"}
            className={"c-carousel--selectable noborder"}
            self={slider => (carouselSimilar = slider)}
            beforeChange={(currentIndex: number, nextIndex: number) => {
              carouselDetail.slickGoTo(nextIndex, false);
              setCurrentIndex(nextIndex);
            }}
            dot={true}
            swipe={true}
            arrows={false}
            infinite={true}
            initialSlide={currentIndex}
          >
            {tastes.map((taste, index) => {
              return (
                <TasteSimilar
                  taste={taste}
                  taste_recommends={taste.recommends}
                  key={index}
                />
              );
            })}
          </Carousel>

          {/* テイスト画像Modal */}
          {tastes.map((taste, index) => {
            return (
              <TasteImageModal
                taste_id={taste.id}
                urls={taste.images}
                key={index}
                startIndex={thumbnailIndex}
              />
            );
          })}
        </div>
      </React.Fragment>
    );
  };
  const duplicateMessage = `
  テイストを選び直す場合は画面左上のメニューから「初めからプランを作成」を選択して新しくプランを作成してください。
  OKをタップすると、先ほど選択したテイストの部材選択画面へ戻ります`;

  return (
    <div className="t-taste">
      {isDuplicateFixedTaste && (
        <Done
          title="テイストは既に選択済みです"
          text={duplicateMessage}
          okText="OK"
          onClickOk={(e: any) => {
            // 部材HOME画面へ
            const publishKey = parsePublishKey();
            props.history.push(`/part/home?publish_key=${publishKey}`);
          }}
        />
      )}
      <Navigation
        left={"戻る"}
        onLeftClick={() => backPage(props, selectCaseIds)}
        rightNode={
          <div className="c-nav_bar-right">
            <button
              className="c-button"
              onClick={async () => {
                const taste = tastes[currentIndex];
                await createPlan(
                  dispatchPlan,
                  taste.id,
                  setDuplicateFixedTaste,
                  (plan: IPlanInfo) => {
                    props.history.push(
                      `/part/insert?publish_key=${plan.publish_key}`
                    );
                  }
                );
              }}
            >
              <span className="c-button-strings">テイストを決定！</span>
            </button>
          </div>
        }
      />
      <div className="c-layout">
        {tastes.length > 0 ? (
          <React.Fragment>
            <div className="c-layout-child">
              {/* カルーセル */}
              <RecommentedTasteCarousel />
            </div>
            <div className="c-layout-child">
              {/* フッター */}
              <RecommentedTasteFooter
                recommends={tastes.map(t => t.id)}
                select_case_ids={selectCaseIds}
                onClick={async () => {
                  const taste = tastes[currentIndex];
                  await createPlan(
                    dispatchPlan,
                    taste.id,
                    setDuplicateFixedTaste,
                    (plan: IPlanInfo) => {
                      props.history.push(
                        `/part/insert?publish_key=${plan.publish_key}`
                      );
                    }
                  );
                }}
                onBack={() => backPage(props, selectCaseIds)}
              />
            </div>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <div className="c-pageheader">
              <div className="u-centered u-tc--on_light-quatiary">
                事例が選択されていないか、該当するテイストが存在しませんでした
              </div>
              <div className="c-footer">
                <div className="c-footer-buttons">
                  <div className="u-centered">
                    <div
                      className="c-button  c-footer-buttons-main c-button"
                      onClick={() => {
                        props.history.push(`/case/list`);
                      }}
                    >
                      <span className="c-button-strings">
                        違う事例を選択する
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </React.Fragment>
        )}
      </div>
    </div>
  );
};

export default TasteRecommend;
