import { Action, Reducer } from "redux";
import IPartVariation from "../models/part_variation";

export interface ITastePartListState {
  fetchedTastePartsList: IPartVariation[][];
}

const initialState: ITastePartListState = {
  fetchedTastePartsList: []
};

export enum ActionName {
  setTastePartsList = "tastePartList/setTastePartsList"
}

interface ISetTastePartsList extends Action<ActionName.setTastePartsList> {
  fetchedTastePartsList: IPartVariation[][];
}

export type ITastePartListAction = ISetTastePartsList;

export const tastePartListReducer: Reducer<
  ITastePartListState | undefined,
  ITastePartListAction
> = (state = initialState, action) => {
  switch (action.type) {
    case ActionName.setTastePartsList: {
      return { ...state, fetchedTastePartsList: action.fetchedTastePartsList };
    }
  }
  return state;
};
