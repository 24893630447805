import * as React from "react";

interface IOuterProps {
  left?: string;
  title?: string;
  right?: string;
  onLeftClick?: () => void;
  onRightClick?: () => void;
  rightNode?: React.ReactNode;
  leftIcon?: string;
}

type AllProps = IOuterProps;

const Navigation = (props: AllProps) => {
  const { title, left, right, onLeftClick, onRightClick, rightNode, leftIcon } = props;
  return (
    <React.Fragment>
      <div className="c-nav_bar--light navigation-header">
        {/* left */}
        <div className="c-nav_bar-left">
          {left || leftIcon ? (
            <div className="c-button" onClick={onLeftClick}>
              {left ? (
                <span className="c-button-strings">{left}</span>
              ) :
                <i className="material-icons">{leftIcon}</i>
              }
            </div>
          ) : (
            <></>
          )}
        </div>

        {/* title */}
        <div className="c-nav_bar-center">
          {title ? (
            <span className="c-button-strings">{title}</span>
          ) : (
            <></>
          )}
        </div>

        {/* right */}
        <div className="c-nav_bar-right">
          {right ? (
            <div className="c-button" onClick={onRightClick}>
              <span className="c-button-strings">{right}</span>
            </div>
          ) : (
            <></>
          )}
        </div>
        {rightNode}
      </div>
    </React.Fragment>
  );
};

export default Navigation;
