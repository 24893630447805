import { Action, Reducer } from "redux";
import IPartVariation from "../models/part_variation";

export interface IAllPartState {
  fetchedAllParts: IPartVariation[];
}

const initialState: IAllPartState = {
  fetchedAllParts: []
};

export enum ActionName {
  setAllParts = "allPart/setAllParts"
}

interface ISetAllParts extends Action<ActionName.setAllParts> {
  fetchedAllParts: IPartVariation[];
}

export type IAllPartAction = ISetAllParts;

export const allPartReducer: Reducer<
  IAllPartState | undefined,
  IAllPartAction
> = (state = initialState, action) => {
  switch (action.type) {
    case ActionName.setAllParts: {
      return { ...state, fetchedAllParts: action.fetchedAllParts };
    }
  }
  return state;
};
