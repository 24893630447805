import * as React from "react";
import { sendException } from "../../analytics";
import { rollbar } from "../../rollbar";

interface IInnerProps {
  hasError: boolean;
  error: Error | null;
}

type AllProps = IInnerProps;

class ErrorBoundary extends React.Component<{}, AllProps> {
  constructor(props: AllProps) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  public componentDidCatch(error: any, info: any) {
    rollbar.error(error, info);
    console.error(error, info);
    this.setState({ hasError: true, error });
  }

  public render() {
    const { hasError, error } = this.state;

    if (hasError) {
      if (error) {
        sendException(error);
      }
      return (
        <>
          <div className="u-centered u-tc--on_light-primary u-fs----base">
            内部エラーが発生しました
          </div>
          <div className="u-centered u-tc--on_light-quatiary u-fs--small">
            {error && error.message}
          </div>
        </>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
