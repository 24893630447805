import * as React from "react";
import { Dispatch, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RouteComponentProps, useLocation, withRouter } from "react-router-dom";
import { ApiClient } from "../../api";
import barsImg from "../../images/bars.svg";
import { IPlan } from "../../models/plan";
import { ActionName, IPlanAction } from "../../redux/plan";
import { parsePublishKey } from "../../util";
import Done from "../alert/done";
import DrawerMenu from "../drawer";

export interface IGlobalMenuProps {
  hideTitle: boolean;
  showCloseButton: boolean;
  show: boolean;
}
interface IOuterProps {
  globalMenuOptions: IGlobalMenuProps;
}

type AllProps = RouteComponentProps & IOuterProps;
const GlobalMenu = (props: AllProps) => {
  // 上スクロール:ヘッダー表示
  const [scrollDicrectionTop, setScrollDicrectionTop] = useState(false);
  const [scrollPositionTopFirst, setScrollPositionTopFirst] = useState(false);
  // // ドロワー開閉
  const showDrawerSelector = (state: any) => state.planReducer.showDrawer;
  const showDrawerMenu: boolean = useSelector(showDrawerSelector);

  // スクロール位置
  let scrollPosition = 0;
  const { pathname } = useLocation();

  const dispatch = useDispatch<Dispatch<IPlanAction>>();

  const planSelector = (state: any) => state.planReducer.fetchedPlan;
  const resultPlan: IPlan = useSelector(planSelector);

  // プラン削除済みダイアログ表示
  const deletedPlanSelector = (state: any) => state.planReducer.showDeletedPlan;
  const doShowDeletedPlan: boolean = useSelector(deletedPlanSelector);

  const dispatchPlan = useDispatch<Dispatch<IPlanAction>>();

  const existPlan = useCallback(async () => {
    const publishKey = parsePublishKey();
    const result: IPlan = await new ApiClient().existPlan(publishKey);
    if (!result.exist) {
      dispatchPlan({
        type: ActionName.showDeletedPlan,
        showDeletedPlan: true
      });
    }
  }, []);

  useEffect(() => {
    // 初回のみ
    existPlan();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  // 上スクロール時、ヘッダーを表示する
  const handleScroll = () => {
    const currentPosition = window.pageYOffset;
    const throttle = 60;
    if (Math.abs(currentPosition - scrollPosition) > throttle) {
      const isUpperScroll = scrollPosition - throttle > currentPosition;
      scrollPosition = currentPosition;
      setScrollDicrectionTop(isUpperScroll);
    }
    // 最上部までスクロールしたか
    const positionTop = currentPosition < 1 ? true : false;
    setScrollPositionTopFirst(positionTop);
  };

  // スクロール位置判定
  useEffect(() => {
    window.removeEventListener("scroll", handleScroll);
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [scrollPosition]);

  return (
    <React.Fragment>
      <div
        id="global-menu"
        className={
          scrollDicrectionTop && !props.globalMenuOptions.showCloseButton
            ? scrollPositionTopFirst
              ? "c-nav_bar--light scroll-position-top"
              : "c-nav_bar--light scroll-direction-top"
            : "c-nav_bar--light"
        }
      >
        <div className="c-nav_bar-left">
          {props.globalMenuOptions.showCloseButton ? (
            <div
              className="c-button"
              onClick={() => props.history.goBack()}
              style={{
                fontSize: "13px",
                paddingLeft: "16px"
              }}
            >
              戻る
            </div>
          ) : (
            <div
              className="c-button "
              onClick={() => {
                // body(親画面) がスクロールする為の制御
                document.body.setAttribute("class", "scroll-hidden");
                dispatchPlan({
                  type: ActionName.showDrawer,
                  showDrawer: true
                });
              }}
            >
              <img src={barsImg} alt="bar" />
            </div>
          )}
        </div>

        {/* plan */}
        <div className="c-nav_bar-center">
          {props.globalMenuOptions.hideTitle ? (
            <></>
          ) : (
            <span className="c-button-strings">{resultPlan.name}</span>
          )}
        </div>
        <div className="c-nav_bar-right" />
      </div>
      {doShowDeletedPlan && (
        <Done
          title=""
          text={"このプランは既に削除されています。"}
          okText="OK"
          onClickOk={(e: any) => {
            dispatch({
              type: ActionName.showDeletedPlan,
              showDeletedPlan: false
            });
            props.history.push("/plan/menu");
          }}
        />
      )}
      {showDrawerMenu && <DrawerMenu />}
    </React.Fragment>
  );
};

export default withRouter(GlobalMenu);
