import * as React from "react";
import { Dispatch } from "react";
import { useDispatch } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import closeImg from "../../images/close.svg";
import { IPlan } from "../../models/plan";
import { ActionName as ActionNamePlan, IPlanAction } from "../../redux/plan";
import { setActivePlan } from "../../session";
import PlanDeleteDialog from "../../ui/plan/delete_dialog";
import { parsePublishKey } from "../../util";
import "./style.css";

type AllProps = RouteComponentProps;
const DrawerMenu = (props: AllProps) => {
  const dispatchPlan = useDispatch<Dispatch<IPlanAction>>();
  // メニュー終了
  const dismiss = () => {
    // body のスクロール無効化を戻す
    document.body.removeAttribute("class");
    dispatchPlan({
      type: ActionNamePlan.showDrawer,
      showDrawer: false
    });
  };
  const showPlanMenu = () => {
    dismiss();
    props.history.push(`/plan/menu?publish_key=${parsePublishKey()}`);
  };
  const showLP = () => {
    dismiss();
    props.history.push(`/introduction?publish_key=${parsePublishKey()}`);
  };
  const showRule = () => {
    dismiss();
    props.history.push(`/rule?publish_key=${parsePublishKey()}`);
  };
  const createPlan = () => {
    dispatchPlan({
      type: ActionNamePlan.createPlan,
      createPlan: true
    });
  };

  return (
    <React.Fragment>
      <div className="drawer-menu">
        <div className="navigation-header c-nav_bar--light">
          <div className="c-nav_bar-left">
            <div
              className="c-button"
              onClick={() => {
                dismiss();
              }}
            >
              <img src={closeImg} alt="close" />
            </div>
          </div>
          <div className="c-nav_bar-center" />
          <div className="c-nav_bar-right" />
        </div>

        <div className="c-drawer-menu-container">
          <div className="c-drawer-menu-item c-drawer-menu-title">Menu</div>
          <div className="c-drawer-menu-item" onClick={showPlanMenu}>
            プラン管理
          </div>
          <div className="c-drawer-menu-item" onClick={createPlan}>
            初めからプランを作成
          </div>
          <div className="c-drawer-menu-item" onClick={showLP}>
            sugataのサービス紹介
          </div>
          <div className="c-drawer-menu-item" onClick={showRule}>
            注意事項
          </div>
        </div>
      </div>
      <PlanDeleteDialog
        onCancel={() => { }}
        onCreate={(plan: IPlan, router: RouteComponentProps) => {
          dismiss();
          setActivePlan(plan);
          router.history.push(`/case/insert?publish_key=${plan.publish_key}`);
        }}
        onDelete={actionType => {
          if (actionType === "create") {
            dispatchPlan({
              type: ActionNamePlan.createPlan,
              createPlan: true
            });
          }
        }}
      />
    </React.Fragment>
  );
};

export default withRouter(DrawerMenu);
