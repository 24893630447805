import * as React from "react";
import { Dispatch } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IPlan } from "../../models/plan";
import { ActionName, IPlanAction } from "../../redux/plan";
import { getSession, permanentSelectSquareMeter } from "../../session";
import PlanDuplicateDialog from "../../ui/plan/duplicate_dialog";

interface IOuterProps {
  onChange: (e: any) => void;
  // プラン削除確認ダイアログの表示
  renderPlanDeleteDialog?: boolean;
}

type AllProps = IOuterProps;

const SelectSquareMeter = (props: AllProps) => {
  const { onChange } = props;
  const session = getSession();
  const squareMeterList = [40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95, 100];

  const planSelector = (state: any) => state.planReducer.fetchedPlan;
  const resultPlan: IPlan = useSelector(planSelector);
  const dispatch = useDispatch<Dispatch<IPlanAction>>();

  return (
    <React.Fragment>
      <select
        value={session ? session.select_square_meter : 40}
        className="on_light"
        onChange={e => {
          if (resultPlan.status !== "locked") {
            onChange(e);
            permanentSelectSquareMeter(Number(e.target.value));
            return;
          }
          // ロック時
          dispatch({
            type: ActionName.showDuplicateConfirm,
            showDuplicateConfirm: true
          });
        }}
      >
        {squareMeterList.map((squareMeter, index) => (
          <option key={index} value={squareMeter}>
            {squareMeter}m&sup2;
          </option>
        ))}
      </select>
      <PlanDuplicateDialog
        renderPlanDeleteDialog={
          props.renderPlanDeleteDialog === undefined
            ? false
            : props.renderPlanDeleteDialog
        }
        plan={resultPlan}
        redirectUrlWithoutPublishKey={`${window.location.pathname}?`}
      />
    </React.Fragment>
  );
};

export default SelectSquareMeter;
