import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import insertImg from "../../../images/walk_through/page_v2_2.jpg";
import { buildPlanQueryParam } from "../../../util";
import { getSession } from "../../../session";
import { ApiClient } from "../../../api";

type AllProps = RouteComponentProps;
const PartInsert = (props: AllProps) => {
  let route: string = `/part/home${buildPlanQueryParam()}`;
  const session = getSession();
  if (session != null) {
    const tasteId: number = session.base_taste_id
    new ApiClient().getVisualizationFuncStatus(tasteId).then(function (visualizationFlg) {
      if (visualizationFlg.status && session.visualization_func_status) {
        route = `/visualization${buildPlanQueryParam()}`;
      }
    })
  }

  return (
    <div className="t-insert">
      <div className="c-layout">
        <div className="c-layout-child">
          <div className="c-group--read">
            <div className="c-group--read-large_read">お部屋をアレンジしてみよう</div>
            <div className="c-group--read-image">
              <img src={insertImg} style={{height: 150, width: 190, objectFit: "cover"}} alt="ピックアップする" />
            </div>
            <div className="c-group--read-small_read">
              選んだテイストの天井や壁を
              <br />
              変更することが出来ます。
            </div>
            <div className="c-group--read-button">
              <div
                className="c-button"
                onClick={() =>
                  props.history.push(route)
                }
              >
                進む
              </div>
            </div>
          </div>
        </div>
        <div className="c-layout-child" />
      </div>
    </div>
  );
};

export default withRouter(PartInsert);
