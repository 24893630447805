import { host } from "./core";

export const buildSelectPartRequest = (publishKey: string) => {
  return {
    url: `${host}/api/user_behaviors/select_part?publish_key=${publishKey}`,
    options: {
      method: "post",
      resolveWithFullResponse: true,
      json: true
    }
  };
};

export const buildSharePlanRequest = (publishKey: string) => {
  return {
    url: `${host}/api/user_behaviors/share_plan?publish_key=${publishKey}`,
    options: {
      method: "post",
      resolveWithFullResponse: true,
      json: true
    }
  };
};

export const buildTasteResetRequest = (publishKey: string) => {
  return {
    url: `${host}/api/user_behaviors/taste_reset?publish_key=${publishKey}`,
    options: {
      method: "post",
      resolveWithFullResponse: true,
      json: true
    }
  };
};
