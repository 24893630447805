import { IPlanParts } from "../../models/plan_part";
import { host, IRequest } from "./core";

export const buildPlanPartsRequest = (
  planId: number
): IRequest<IPlanParts[]> => {
  return {
    url: `${host}/api/plans/${planId}/parts`,
    options: {
      method: "get",
      resolveWithFullResponse: true,
      json: true
    }
  };
};
