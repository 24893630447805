import * as React from "react";
import { useState } from "react";

interface IOuterProps {
  title: string;
  editItems: Array<{ title: string; type: string; value: string }>;
  okText: string;
  cancelText: string;
  onClickOk: (planName: string, planMemo: string) => void;
  onClickCancel: () => void;
}

type AllProps = IOuterProps;

const Edit = (props: AllProps) => {
  const {
    title,
    editItems,
    okText,
    cancelText,
    onClickOk,
    onClickCancel
  } = props;

  const [updatePlanName, setUpdatePlanName] = useState(editItems[0].value);
  const [updatePlanMemo, setUpdatePlanMemo] = useState(editItems[1].value);

  return (
    <div
      className="c-modal--alert information"
      data-modal="active"
      data-modal-color="light_trans_nav"
    >
      <div className="c-modal-inwrapper">
        <div
          className="c-modal-inwrapper-content"
          style={{ overflowY: "hidden" }}
        >
          <div className="c-layout">
            <div className="c-layout-child">
              <div className="u-fs--base">{title}</div>
              {editItems.map((item, index) => {
                return (
                  <div key={index} style={{ marginBottom: "1rem" }}>
                    <div style={{ textAlign: "left" }}>{item.title}</div>
                    <div>
                      {item.type === "textarea" ? (
                        <textarea
                          id={`item-${index}`}
                          className={"on_light"}
                          style={{
                            width: "100%",
                            height: "8rem",
                            resize: "none",
                            margin: "0"
                          }}
                          defaultValue={item.value}
                          maxLength={50}
                          onChange={e => setUpdatePlanMemo(e.target.value)}
                        />
                      ) : (
                        <input
                          type={item.type}
                          id={`item-${index}`}
                          className={"on_light"}
                          style={{
                            width: "100%",
                            height: "2.5rem",
                            margin: "0"
                          }}
                          defaultValue={item.value}
                          maxLength={15}
                          onChange={e => setUpdatePlanName(e.target.value)}
                        />
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="c-layout-child">
              <div className="c-nav_bar">
                <div className="c-nav_bar-left" onClick={onClickCancel}>
                  <div className="c-button c-modal_close">
                    <div className="c-button-strings">
                      <div className="u-tc--success">{cancelText}</div>
                    </div>
                  </div>
                </div>
                <div
                  className="c-nav_bar-right"
                  onClick={() => {
                    onClickOk(updatePlanName, updatePlanMemo);
                  }}
                >
                  <div className="c-button c-modal_close">
                    <div className="c-button-strings">
                      <div className="u-tc--success">{okText}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Edit;
