import * as React from "react";
import { RouteComponentProps } from "react-router-dom";
import shareImg from "../../../../images/redirect.svg";
import { IHouseholdItem } from "../../../../models/household_item";

interface IHouseholdItemProps {
  householdItem: IHouseholdItem;
}
type AllProps = RouteComponentProps & IHouseholdItemProps;

const HouseholdItemCell = (props: AllProps) => {
  const { householdItem } = props;

  return (
    <div className="c-list-item">
      <a
        href={householdItem.site_url}
        target="_blank"
        rel="noreferrer noopener"
      >
        <div className="c-card">
          <div className="c-card-body">
            <div className="c-card-body-thumbnail">
              <div
                className="c-thumbnail--round_all c-drop_shadow--custom"
                data-object_fit="contain"
              >
                <img src={householdItem.image_url} alt="画像が未設定です" />
              </div>
            </div>
            <div className="c-card-body-title" style={{ height: "4rem" }}>
              {householdItem.title}
            </div>
            <div className="c-card-body-share" style={{ textAlign: "right" }}>
              <img src={shareImg} alt="サイトにアクセスする" />
            </div>
          </div>
        </div>
      </a>
    </div>
  );
};

export default HouseholdItemCell;
