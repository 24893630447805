import * as React from "react";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import { Dispatch } from "redux";
import { ApiClient } from "../../../api";
import { IUpdatePlanSquareMeterParam } from "../../../api/request/plans";
import Divider from "../../../component/divider";
import Loading from "../../../component/loading";
import Navigation from "../../../component/navigation";
import SelectSquareMeter from "../../../component/select_square_meter";
import MultipleableConfirm, { IMultipleItem } from "../../../component/confirm/multipleable";
import { IPlan } from "../../../models/plan";
import { ActionName, IPlanAction } from "../../../redux/plan";
import { ActionName as ActionNamePlan } from "../../../redux/plan";
import { ActionName as ActionNameHousehold, IHouseholdItemAction  } from "../../../redux/household_item";
import { enablePartResetConfirm, getSession, setActivePlan } from "../../../session";
import {
  buildPlanQueryParam,
  handleRequestError,
  loadPlanPublishKey,
  parsePriceFormat,
  scrollFromQueryParam,
  authRedirectUrl,
  eventVersionPriceDisplay,
  showThePrice,
  getSavingUserMadeImageStatus,
  sleep
} from "../../../util";
import { PartDetailImageModal, PartDetailModal } from "../../part/detail";
import PartCell, { PartState } from "./part_cell";
import TasteDetailContent, {
  TasteImageModal
} from "../../taste/detail/taste_detail";
import sendPageView from "../../../analytics";
import HouseholdItemRecommendList from "./household";
import { IHouseholdItem } from "../../../models/household_item";

interface IPlanProps {
  plan: IPlan;
  dispatcherPlan: Dispatch<IPlanAction>;
}
type AllProps = RouteComponentProps & IPlanProps;

const sharePlan = async (props: AllProps) => {
  // ロック以外なら
  if (props.plan.status !== "locked") {
    await new ApiClient().sharePlan(props.plan.publish_key);
  }
  props.history.push(`/share/url/${props.plan.publish_key}`);
};

const ConfirmLockedDialog = (props: {
  onDuplicatePlan: () => void;
  onNewPlan: () => void;
  onCancel: () => void;
}) => {
  const items: IMultipleItem[] = [
    {
      index: 0,
      title: "このプランを複製して編集する",
      onClick: () => props.onDuplicatePlan()
    },
    {
      index: 1,
      title: "新しいプランを作成する",
      onClick: () => props.onNewPlan()
    },
    {
      index: 2,
      title: "キャンセル",
      onClick: () => props.onCancel()
    }
  ];
  const message = `このプランは既にプランURLを発行しているため、戻って編集はできません。
    編集するにはこのプランを複製するか、新しくプランを作成してください。`;
    return (
    <React.Fragment>
      <MultipleableConfirm
        title="プランURLを発行済みです"
        text={message}
        items={items}
      />
    </React.Fragment>
  );
};

const newPlan = async (
  props: RouteComponentProps,
  dispatchPlan: Dispatch<IPlanAction>
) => {
  const result = await new ApiClient().limitPlan();
  if (result.is_over) {
    // 削除確認ダイアログを表示する
    dispatchPlan({
      type: ActionNamePlan.showDeletePlan,
      showDeletePlan: true
    });
  } else {
    // プランを新規作成する
    const plan = await new ApiClient().createPlan();
    dispatchPlan({
      type: ActionName.setPlan,
      fetchedPlan: plan
    });
    setActivePlan(plan);
    props.history.push(`/case/insert?publish_key=${plan.publish_key}`);
  }
};

const duplicatePlan = async (
  props: AllProps,
  planId: number,
  dispatchPlan: Dispatch<IPlanAction>,
) => {
  const result = await new ApiClient().limitPlan();
  if (result.is_over) {
    // 削除確認ダイアログを表示する
    dispatchPlan({
      type: ActionNamePlan.showDeletePlan,
      showDeletePlan: true
    });
  } else {
    // プラン複製
    const duplicatedPlan = await new ApiClient().duplicatePlan(planId);
    // 再取得
    const responsePlan = await new ApiClient().getPlan(duplicatedPlan.id);
    dispatchPlan({
      type: ActionNamePlan.setPlan,
      fetchedPlan: responsePlan
    });
    dispatchPlan({
      type: ActionNamePlan.setDuplicatedPlan,
      duplicatedPlan: duplicatedPlan
    });
    setActivePlan(responsePlan);
    props.history.push(`/share/detail?publish_key=${responsePlan.publish_key}`);
  }
};

const ShareDetailHeader = (props: AllProps) => {
  const [showBackConfirm, setShowBackConfirm] = useState(false);
  const dispatchPlan = useDispatch<Dispatch<IPlanAction>>();

  return (
    <React.Fragment>
      <Navigation
        left={"戻る"}
        onLeftClick={() =>
          setShowBackConfirm(true)
        }
        rightNode={
          <div className="c-nav_bar-right">
            <div onClick={() => sharePlan(props)} className="c-button">
              <i className="material-icons">share</i>
            </div>
          </div>
        }
      />
      {showBackConfirm &&
        (props.plan.status === "locked" ? (
          <ConfirmLockedDialog
            onDuplicatePlan={async () => {
              await duplicatePlan(props, props.plan.id, dispatchPlan);
              setShowBackConfirm(false);
              }
            }
            onNewPlan={async () => {
              await newPlan(props, dispatchPlan);
              setShowBackConfirm(false);
            }}
            onCancel={() => setShowBackConfirm(false)}
          />
          ) : (props.history.push(`/part/home${buildPlanQueryParam()}`))
        )
      }
    </React.Fragment>
  );
};

const PlanParts = (props: AllProps) => {
  const { plan } = props;
  const [initialized, setInitialized] = useState(false);
  // 初回表示時のみスクロール
  if (!initialized) {
    scrollFromQueryParam("scroll_id");
    setInitialized(true);
  }

  return (
    <React.Fragment>
      <div className="c-group--part_category">
        {plan.categories.map(c => {
          return (
            <div className="c-accordion" key={c.id}>
              <input
                className="c-accordion-toggler"
                type="checkbox"
                defaultChecked={
                  new URLSearchParams(window.location.search.substring(1)).get(
                    "scroll_id"
                  )
                    ? true
                    : false
                }
                id={`c-accordion-${c.id}`}
              />
              <label
                className="c-accordion-label"
                htmlFor={`c-accordion-${c.id}`}
              >
                <div className="c-accordion-label-strings">{c.name}</div>
              </label>
              <div className="c-group--part_category-list c-accordion-content">
                <div className="c-list--equal" data-list_column="2">
                  {c.sub_categories.map(s => {
                    if (!s.id) {
                      return <></>;
                    }
                    const part = s.selected_part || [];

                    return (
                      <PartCell
                        {...props}
                        showModal={true}
                        key={s.id}
                        id={part.id}
                        subCategory={s.name}
                        imageUrl={
                          part.images && part.images.length > 0
                            ? part.images[0]
                            : ""
                        }
                        materialName={part.material_name}
                        description={part.note}
                        state={
                          part.is_change && !part.is_default
                            ? PartState.changed
                            : PartState.default
                        }
                        hasComment={part.memo ? true : false}
                        onClick={() => {
                          sendPageView(
                            "部材詳細画面",
                            `/part/detail/${part.part_id}`
                          );
                        }}
                      />
                      // </div>
                    );
                  })}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </React.Fragment>
  );
};

const ShareDetailModal = (props: AllProps) => {
  return (
    <div className="t-part-select" style={{ height: "0%", padding: "0px" }}>
      {props.plan.categories.map(c => {
        return c.sub_categories.map(s => {
          if (!s.id) {
            return <></>;
          }
          const part = s.selected_part || [];
          return (
            <React.Fragment key={part.id}>
              <PartDetailModal
                id={part.id}
                name={part.name}
                partNumber={part.partNumber}
                description={part.note}
                images={part.images}
                material_name={part.material_name}
                isShared={true}
                isSelected={false}
                memo={part.memo}
                price_difference={part.price_difference}
                onBack={() => {
                  sendPageView("共有内容確認画面", "/share/detail");
                }}
                onRedirect={() => {
                  // 部材選択画面へ
                  props.history.push(
                    `/part/list${buildPlanQueryParam()}&sub_category_id=${
                      s.id
                    }&is_share=true&scroll_id=${part.id}`
                  );
                }}
              />
              <PartDetailImageModal id={part.id} images={part.images} />
            </React.Fragment>
          );
        });
      })}
    </div>
  );
};

/* 概算価格算出・表示（通常用） */
const ShareEstimate = (props: AllProps) => {
  const updateSquareMeter = async (value: number) => {
    // プラン平米更新
    const param: IUpdatePlanSquareMeterParam = {
      id: props.plan.id,
      square_meter: value
    };
    // 平米、部材差分表示
    const result = await new ApiClient().updatePlanSquareMeter(param);
    // 再取得
    const responsePlan = await new ApiClient().getPlan(param.id);
    props.dispatcherPlan({
      type: ActionName.setPlan,
      fetchedPlan: responsePlan
    });
  };

  return (
    <div className="c-group--taste_details-calc">
      <div className="c-group--taste_details-calc-title">概算</div>
      <div className="c-group--taste_details-calc-form">
        <div className="c-group--taste_details-calc-form-area_select">
          <SelectSquareMeter
            renderPlanDeleteDialog={true}
            onChange={e => {
              updateSquareMeter(Number(e.target.value));
            }}
          />
        </div>
        <div className="c-group--taste_details-calc-form-result">
          {parsePriceFormat(props.plan.total_price)}
        </div>
      </div>
      <div
        className="c-group--taste_details-calc-notes"
        style={{ fontSize: "11px", lineHeight: "1.2rem" }}
      >
        ※お部屋の平米数に基づく参考価格(税込)です。実際の価格は間取り等で変化しますが、部材の選択結果は25万円単位で概算されています
      </div>
    </div>
  );
};

/* 概算価格算出・表示（イベント用） */
const ShareEstimateForEvent = (props: AllProps) => {
  const updateSquareMeter = async (value: number) => {
    // プラン平米更新
    const param: IUpdatePlanSquareMeterParam = {
      id: props.plan.id,
      square_meter: value
    };
    // 平米、部材差分表示
    const result = await new ApiClient().updatePlanSquareMeter(param);
    // 再取得
    const responsePlan = await new ApiClient().getPlan(param.id);
    props.dispatcherPlan({
      type: ActionName.setPlan,
      fetchedPlan: responsePlan
    });
  };

  return (
    <div className="c-group--taste_details-calc">
      <div className="c-group--taste_details-calc-title">概算</div>
      <div className="c-group--taste_details-calc-form">
        <div className="c-group--taste_details-calc-form-area_select">
          <SelectSquareMeter
            renderPlanDeleteDialog={true}
            onChange={e => {
              updateSquareMeter(Number(e.target.value));
            }}
          />
        </div>
        {/* 概算価格固定表示 */}
        <div className="c-group--taste_details-calc-form-result">
          ¥10,000,000
        </div>
      </div>
      <div
        className="c-group--taste_details-calc-notes"
        style={{ fontSize: "11px", lineHeight: "1.2rem" }}
      >
        ※お部屋の平米数に基づく参考価格(税込)です。実際の価格は間取り等で変化しますが、部材の選択結果は25万円単位で概算されています
      </div>
    </div>
  );
};
interface IHouseholdItemProps {
  householdItems: IHouseholdItem[];
}

const ShareDetailContainer = (props: AllProps & IHouseholdItemProps) => {
  const { plan, dispatcherPlan } = props;
  const [thumbnailIndex, setThumbnailIndex] = useState(0);
  const [showBackConfirm, setShowBackConfirm] = useState(false);
  const dispatchPlan = useDispatch<Dispatch<IPlanAction>>();

  return (
    <div className="c-layout">
      <div className="c-layout-child">
        {/* ヘッダー */}
        <div className="c-pageheader">
          <div className="c-pageheader-title">これがあなたのsugata！</div>
          <div className="c-pageheader-descriptions">
            完成したsugataの内容を確認しましょう。こちらを参考に、お部屋づくりを進めていきます。
          </div>
          <Divider />
        </div>
        <div className="u-gutter--both4  u-fs--large-bold">{/*"ベーステイスト"*/}</div>

        {/* カバー、詳細 */}
        <div className="c-group--taste_details">
          <TasteDetailContent
            active={true}
            taste={plan.taste}
            user_made_images={plan.user_made_images}
            index={0}
            wrapper_class={"t-share-inwrapper"}
            hide_estimate={true}
            onClickThumbnail={(index: number) => setThumbnailIndex(index)}
          />

          {/* 見積もり結果 */}
          {!(showThePrice(getSession())) ?<React.Fragment></React.Fragment>:

          <div>
          {eventVersionPriceDisplay
            /* イベント用表示 */
            ? <ShareEstimateForEvent
                {...props}
                plan={plan}
                dispatcherPlan={dispatcherPlan}
              />
            /* 通常表示 */
            : <ShareEstimate
                {...props}
                plan={plan}
                dispatcherPlan={dispatcherPlan}
              />
          }
          </div>
          }
        </div>
        <TasteImageModal
          taste_id={props.plan.taste.id}
          urls={props.plan.taste.images}
          startIndex={thumbnailIndex}
        />
        {/* お部屋 */}
        <div className="u-gutter--both4  u-fs--large-bold">お部屋</div>
        <PlanParts {...props} plan={plan} />
      </div>

      {/* フッター */}
      <div className="c-layout-child">
        <div className="c-footer">
          <div className="c-footer-buttons">
            <div
              className="c-footer-buttons-sub c-button"
              onClick={() =>
                setShowBackConfirm(true)
                // props.history.push(`/part/home${buildPlanQueryParam()}`)
              }
            >
              <span className="c-button-strings">戻る</span>
            </div>
            <div
              onClick={() => sharePlan(props)}
              className="c-footer-buttons-main c-button"
            >
              <span className="c-button-strings">この内容で共有する</span>
              <div className="material-icons">share</div>
            </div>
          </div>
        </div>

        <HouseholdItemRecommendList
          {...props}
          plan={plan}
          householdItems={props.householdItems}
        />

        {showBackConfirm &&
          (props.plan.status === "locked" ? (
            <ConfirmLockedDialog
              onDuplicatePlan={async () => {
                await duplicatePlan(props, props.plan.id, dispatchPlan);
                setShowBackConfirm(false);
                }
              }
              onNewPlan={async () => {
                await newPlan(props, dispatchPlan);
                setShowBackConfirm(false);
              }}
              onCancel={() => setShowBackConfirm(false)}
            />
            ) : (props.history.push(`/part/home${buildPlanQueryParam()}`))
          )
        }
      </div>
    </div>
  );
};

type EntryProps = {} & RouteComponentProps;

const validSharePlan = async (props: EntryProps) => {
  const session = getSession();
  if (session) {
    const searchParams = new URLSearchParams(
      window.location.search.substring(1)
    );
    const publishKey = searchParams.get("publish_key") || "";

    if (publishKey){
      const plan: IPlan = await new ApiClient().findPlan(publishKey);
      if (session.login_key !== plan.login_key) {
        alert(
          "共有されたプランを閲覧する為には、同じアカウントでログインする必要があります。"
        );
        // ログイン後のリダイレクト先:家具EC連携であるか
        props.history.push(authRedirectUrl());
      }
    }
  }
};

const ShareDetail = (props: EntryProps) => {
  const [loading, setLoading] = useState(false);

  // 部材確認ダイアログ表示状態をリセット
  enablePartResetConfirm();

  // plan
  const planSelector = (state: any) => state.planReducer.fetchedPlan;
  const resultPlan: IPlan = useSelector(planSelector);
  const dispatcher = useDispatch<Dispatch<IPlanAction>>();

  // household
  const householdItemSelector = (state: any) =>
    state.householdItemReducer.fetchedHouseholdItem;
  const resultHouseholdItems: IHouseholdItem[] = useSelector(
    householdItemSelector
  );
  const dispatcherHousehold = useDispatch<Dispatch<IHouseholdItemAction>>();

  useCallback(async () => {
    // 共有プランが自分のプランであるか
    await validSharePlan(props);
  }, []);

  const fetchContents = useCallback(async () => {
    setLoading(true);
    try {
      // 合成イメージのアップロード+URL保存が完了するまで待つ(最大2分)
      let savingStatus: boolean = await getSavingUserMadeImageStatus();
      let waitCount: number = 0;
      while (savingStatus && waitCount < 240) {
        await sleep(500);
        waitCount += 1;
        savingStatus = await getSavingUserMadeImageStatus();
      }
      // plan
      const responsePlan = await loadPlanPublishKey();
      dispatcher({
        type: ActionName.setPlan,
        fetchedPlan: responsePlan
      });

      // 家具
      if (responsePlan.is_share) {
        const responseHouseholdItems = await new ApiClient().getHouseholdItems(
          responsePlan.taste.id
        );
        dispatcherHousehold({
          type: ActionNameHousehold.setHouseholdItem,
          fetchedHouseholdItem: responseHouseholdItems
        });
      }

      setLoading(false);
    } catch (e) {
      setLoading(false);
      handleRequestError(e, props.history);
    }
  }, [dispatcher]);

  useEffect(() => {
    fetchContents();
  }, [fetchContents]);

  if (resultPlan === undefined || loading) {
    return <Loading />;
  }
  if (resultPlan.taste === undefined || resultPlan.taste.id === 0) {
    return <p className="u-centered">存在しないプランです</p>;
  }

  return (
    <React.Fragment>
      <div className="t-share">
        <ShareDetailHeader
          {...props}
          plan={resultPlan}
          dispatcherPlan={dispatcher}
        />
        <ShareDetailModal
          {...props}
          plan={resultPlan}
          dispatcherPlan={dispatcher}
        />
        <ShareDetailContainer
          {...props}
          plan={resultPlan}
          householdItems={resultHouseholdItems}
          dispatcherPlan={dispatcher}
        />
      </div>
    </React.Fragment>
  );
};

export default ShareDetail;