import { IUser, IUserStatus } from "../../models/user";
import { host, IRequest } from "./core";

export const buildUserSearchRequest = (email: string, publishKey: string): IRequest<IUser> => {
  return {
    url: `${host}/api/users/search?email=${encodeURIComponent(email)}&publish_key=${publishKey}`,
    options: {
      method: "get",
      resolveWithFullResponse: true,
      json: true
    }
  };
};
export const buildGetUserStatusRequest = (email: string): IRequest<IUserStatus> => {
  return {
    url: `${host}/api/users/get_user_status?email=${encodeURIComponent(email)}`,
    options: {
      method: "get",
      resolveWithFullResponse: true,
      json: true
    }
  };
};
