import Rollbar from "rollbar";
import { Env, getCurrentEnv } from "../env";

const env = getCurrentEnv();

const config: Rollbar.Configuration = {
  accessToken: "a6861016b63b4615b8d2a51d7b03837c",
  captureUncaught: true,
  captureUnhandledRejections: true,
  enabled: env === Env.production || env === Env.staging,
  payload: {
    environment: env,
    client: {
      javascript: {
        source_map_enabled: true,
        code_version: process.env.VERSION || "",
        guess_uncaught_frames: true
      }
    }
  }
};

export const rollbar = new Rollbar(config);
