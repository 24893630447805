import React, { useState, useEffect } from "react";
import { RouteComponentProps } from "react-router";

import Loading from "../../../component/loading";
import { PartDetailModalUI } from "../../part/detail";

import "./style.css";

interface IReceive {
  name: string;
  partNumber: string;
  images: string[];
  material_name: string;
  description: string;
  price_difference: number;
}

const PreviewPartDetail = (props: RouteComponentProps) => {
  const [info, setInfo] = useState<IReceive | null>(null);

  useEffect(() => {
    window.addEventListener('message', event => {
      console.log({ front: event.data })
      setInfo(event.data || {});
    });
  }, []);

  if (info === null) {
    return <Loading />;
  }

  const {
    name,
    partNumber,
    images,
    material_name,
    description,
    price_difference
  } = info;

  return (
    <div style={{ height: "100%" }}>
      <PartDetailModalUI
        id={0}
        name={name}
        partNumber={partNumber}
        images={images}
        material_name={material_name}
        description={description}
        price_difference={price_difference}
        isSelected={false}
        isShared={false}
        guardConfirm={true}
      />
    </div>
  );
};

export default PreviewPartDetail;
