import { Action, Reducer } from "redux";
import { IPlanParts } from "../models/plan_part";

export interface IPlanPartState {
  fetchedPlanParts: IPlanParts[];
}

const initialState: IPlanPartState = {
  fetchedPlanParts: []
};

export enum ActionName {
  setPlanParts = "planPart/setPlanParts",
  createPlan = "createPlan"
}

interface ISetPlanParts extends Action<ActionName.setPlanParts> {
  fetchedPlanParts: IPlanParts[];
}

export type IPlanPartAction = ISetPlanParts;

export const planPartReducer: Reducer<
  IPlanPartState | undefined,
  IPlanPartAction
> = (state = initialState, action) => {
  switch (action.type) {
    case ActionName.setPlanParts: {
      return { ...state, fetchedPlanParts: action.fetchedPlanParts };
    }
  }
  return state;
};
