import { IUploadObject, ImageUploader } from './uploader';
import { S3Config } from '../config/aws_config';
import { getCurrentEnv } from '../env'

export interface IUserMadeImageRequest {
  id: number;
  filetype: string;
  extension : string;
  body: string | object;
  filename: string;
}

export const uploadUserMadeImage = async (request: IUserMadeImageRequest): Promise<string> => {
  const client: ImageUploader = new ImageUploader();
  const object: IUploadObject = {
    key: buildPath(request, client.generateHash()),
    body: request.body
  };
  await client.upload(object);
  return S3Config().cloudfront + object.key;
}

const formatDate = (current_date: Date)=>{
  const formatted_date: string = `${current_date.getFullYear()}${("0" + (current_date.getMonth() + 1)).slice(-2)}${("0" + current_date.getDate()).slice(-2)}${current_date.getHours()}${current_date.getMinutes()}`;
  return formatted_date;
}

const buildPath = (request: IUserMadeImageRequest, hashKey: string): string => {
  return `${getCurrentEnv()}/images/userMadeImage/${request.id}/${formatDate(new Date())}_${request.filename}_${hashKey}.${request.extension}`;
};
